import React, { createContext, useState } from 'react';

export const RefreshContext = createContext({});

export function RefreshProvider({ children }) {
  const [refreshFolders, setRefreshFolders] = useState(false);
  const [refreshChilds, setRefreshChilds] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(false);

  return (
    <RefreshContext.Provider
      value={{
        refreshFolders,
        setRefreshFolders,
        refreshChilds,
        setRefreshChilds,
        refreshDetails,
        setRefreshDetails,
      }}
    >
      {children}
    </RefreshContext.Provider>
  );
}
