import React, { useContext } from 'react';
import styled from '@emotion/styled';
import useAuth from '../../hooks/useAuth';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { GreetingContext } from '../../contexts/GreetingContext';

import NavbarNav from './NavbarNav';
import UserDropdown from '../MenuDropdown/UserDropdown';
import LanguagesDropdown from '../MenuDropdown/LanguagesDropdown';

import BackgroundImg from '../../assets/image2.png';
import LogoMaxion from '../../assets/logoopenmax.png';

import { CircularProgress } from '@mui/material';

const NavBar = styled.header`
  background-image: url(${BackgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* padding: 10px 30px 25px 50px; */
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Logo = styled(Link)`
  text-decoration: none;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 130px;
  bottom: 75px;
`;

const TopMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 0;
  bottom: 50px;

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

function Navbar({ items, t }) {
  const { greeting } = useContext(GreetingContext);
  const { user } = useAuth();

  return (
    <NavBar>
      <Flex>
        <Logo to="/">
          <img
            width={250}
            src={LogoMaxion}
            alt={t('maxion-logo', { ns: 'maxion' })}
          />
        </Logo>

        {user && greeting ? (
          <TopMenu>
            <p>
              {t('Olá', { ns: 'maxion' })} {user.name}.{' '}
              {t(`${greeting}`, { ns: 'maxion' })}!
            </p>
            <Actions>
              <LanguagesDropdown />
              <UserDropdown />
            </Actions>
          </TopMenu>
        ) : (
          <Loading>
            <CircularProgress size={24} />
          </Loading>
        )}
      </Flex>

      <NavbarNav items={items} />
    </NavBar>
  );
}

export default withTranslation('maxion')(Navbar);
