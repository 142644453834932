import React, { useEffect, useState, useContext } from 'react';
import baseItems from './baseItems';
import { POContext } from '../../../../../contexts/POContext';
import axios from '../../../../../utils/axios';
import { withTranslation } from 'react-i18next';

function Content() {
  const [content, setContent] = useState(null);
  const [old, setOld] = useState(null);

  // const { invisibleNumber, setInvisibleNumber } = useContext(POContext);
  const { invisibleNumber } = useContext(POContext);

  // useEffect(() => {
  //   return () => {
  //     setInvisibleNumber(0);
  //   };
  // }, []);

  useEffect(() => {
    if (invisibleNumber !== undefined) {
      if (
        (old === null || old !== baseItems[invisibleNumber].title) &&
        baseItems[invisibleNumber].title !== 'Listagem de versões'
      ) {
        setUsersNavigationLog(baseItems[invisibleNumber].title);
        setOld(baseItems[invisibleNumber].title);
      }

      setContent(baseItems[invisibleNumber].component);
    }
  }, [content, invisibleNumber, old]);

  async function setUsersNavigationLog(textRoute) {
    try {
      const route = `OpenMax / Números Maxion / Plano Operacional / ${textRoute}`;

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}users_navigation_log`,
        {
          route: route,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return <div style={{ width: '100%' }}>{content && content}</div>;
}

export default withTranslation('maxion')(Content);
