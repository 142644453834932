import { useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function useNavBlock(block) {
  const { navigator } = useContext(NavigationContext);
  const { t } = useTranslation();

  useEffect(
    (event) => {
      if (block) {
        const unblock = navigator.block((tx) => {
          const text = t(
            'Há alterações não salvas. Deseja descartar as alterações e sair?',
            { ns: 'maxion' }
          );

          const confirm = window.confirm(text);

          if (confirm) {
            unblock();
            tx.retry();
          } else {
            return;
          }
        });

        return () => {
          unblock();
        };
      }
    },
    [block, navigator, t]
  );
}
