import React, { useState, useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import Loading from '../../../../../../components/Loading';
import axios from '../../../../../../utils/axios';
import styled from '@emotion/styled';

function ModalLogs({ open, setOpen, t, logId }) {
  const [loading, setLoading] = useState(true);
  const [logInfo, setLogInfo] = useState(null);

  useEffect(() => {
    async function loadLogs() {
      console.log('loading log', logId);
      setLoading(true);
      try {
        axios
          .get(
            `${process.env.REACT_APP_PUBLIC_API}upload_file/importer/log/${logId}`
          )
          .then((values) => {
            setLogInfo(values.data);
          });
      } catch (error) {
        console.error('Error loading files:', error);
      } finally {
        setLoading(false);
      }
    }

    if (logId) {
      loadLogs();
    }
  }, [logId]);

  function handleClose() {
    setOpen(false);
  }

  const Content = styled.div`
    margin: 10px 0;
  `;

  const ContentList = styled.div`
    height: 300px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ccc;
  `;

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md">
        <DialogTitle>Arquivo: {logInfo?.file_name}</DialogTitle>
        <DialogContent>
          {loading && <Loading />}
          {!loading && logInfo && (
            <>
              <Content>
                <p>
                  <b>Descrição do arquivo</b>
                </p>
                <p>{logInfo?.file_description}</p>
              </Content>
              <Content>
                <p>
                  <b>Status do arquivo</b>
                </p>
                <p>{logInfo?.status}</p>
              </Content>

              <h3>Logs</h3>
              {logInfo?.logs && logInfo?.logs.nocodb && (
                <>
                  <Content>
                    <p>
                      <b>Porcentagem de sucesso</b>
                    </p>
                    <p>{logInfo?.logs?.nocodb?.success_percent}%</p>
                  </Content>
                  <Content>
                    <p>
                      <b>Total de linhas</b>
                    </p>
                    <p>{logInfo?.logs.total_rows}</p>
                  </Content>
                  <Content>
                    <p>
                      <b>Total de linhas com erro</b>
                    </p>
                    <p>{logInfo?.logs.error_count}</p>
                  </Content>
                  <Content>
                    <p>
                      <b>Total de linhas com sucesso</b>
                    </p>
                    <p>{logInfo?.logs.success_count}</p>
                  </Content>
                </>
              )}
              {logInfo?.errors && logInfo?.errors.length > 0 && (
                <ContentList>
                  <p>
                    <b>Erros</b>
                  </p>
                  <div>
                    <ul>
                      {logInfo?.errors.map((error, index) => {
                        return (
                          <li key={index}>
                            <div>
                              <li>Tipo de erro: {error.error_type}</li>
                              <li>Coluna: {error.problem_column}</li>
                              <li>Valor: {error.problem_value}</li>
                              <li>{error.error_message}</li>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </ContentList>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button disabled={loading} onClick={() => handleClose()}>
            {t('Voltar', { ns: 'maxion' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withTranslation('maxion')(ModalLogs);
