import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { POContext } from '../../../../../../contexts/POContext';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';
import { checkPermission } from '../../../../../../utils/permissions';
import useAuth from '../../../../../../hooks/useAuth';
import Loading from '../../../../../../components/Loading';
import axios from '../../../../../../utils/axios';
import TableProductList from './components/TableProductList';
import InputsNewProduct from './components/InputsNewProduct';

const Form = styled.div`
  display: flex;
  flex-direction: column;

  .info-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    margin-left: 8px;

    span {
      display: flex;
      align-items: center;
      gap: 8px;

      P {
        color: ${({ theme }) => theme.colors.blue_300};
      }

      b {
        color: ${({ theme }) => theme.colors.gray_500};
        font-weight: 400;
      }
    }
  }

  .form-items {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    h5 {
      margin-bottom: 10px;
      margin-left: 5px;
      color: ${({ theme }) => theme.colors.blue_300};
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

const InputTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue_300};
  margin-top: 10px;
`;

export default function ModalNewProduct({
  item,
  open,
  setOpen,
  fetchSemaphore,
  t,
}) {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [newProducts, setNewProducts] = useState('');

  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingNewProducts, setLoadingNewProducts] = useState(true);

  const { version } = useContext(POContext);

  const { user } = useAuth();
  const { isPlanActive, customer } = useContext(POContext);

  const userHasPermissionToInteract =
    user && checkPermission(user, [], [9, 10]);
  const isProductEditable = customer.aop !== 'finished' ? true : false;
  const isVersionEditable = isPlanActive;

  const canUserManage =
    userHasPermissionToInteract && isProductEditable && isVersionEditable;

  const initialValues = {
    name: '',
    cliente: '',
    organizacao: '',
    aplicacao: '',
    hierarquia: '',
    segmento: '',
    codigo_produto_cliente: '',
    sap_id: '',
    custo: '',
  };

  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [rowId, setRowId] = useState('');

  async function fetchCustomers() {
    try {
      setLoadingCustomers(true);

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}criticas/grupo_cliente`,
        {
          search: '',
        }
      );

      setCustomers(data);
      setLoadingCustomers(false);
    } catch (error) {
      console.log(`Error fetching customers:  ${error}`);
      setLoadingCustomers(false);
    }
  }

  async function fetchTableItems() {
    try {
      setLoadingNewProducts(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_new_products?order_by=created_at&order_type=desc&filters=sk_grupo_cliente=${item.sk_grupo_cliente}`
      );

      setNewProducts(data);
      setLoadingNewProducts(false);
    } catch (error) {
      toast.error(t('Erro ao buscar produtos'), {
        ns: 'maxion',
      });
      setLoadingNewProducts(false);
      console.log(`Error fetching data:  ${error}`);
    }
  }

  useEffect(() => {
    fetchCustomers();
    fetchTableItems();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>{t('Novos produtos', { ns: 'maxion' })}</DialogTitle>
      <DialogContent>
        {!version.deleted_at && canUserManage && (
          <InputsNewProduct
            fetchTableItems={fetchTableItems}
            fetchSemaphore={fetchSemaphore}
            row={item}
            t={t}
            version={version}
            formikInitialValues={formikInitialValues}
            setFormikInitialValues={setFormikInitialValues}
            isEditingProduct={isEditingProduct}
            setIsEditingProduct={setIsEditingProduct}
            rowId={rowId}
          />
        )}
        <TableProductList
          fetchTableItems={fetchTableItems}
          fetchSemaphore={fetchSemaphore}
          t={t}
          items={newProducts}
          setItems={setNewProducts}
          loadingItems={loadingNewProducts}
          version={version}
          isPlanActive={isPlanActive}
          setFormikInitialValues={setFormikInitialValues}
          isEditingProduct={isEditingProduct}
          setIsEditingProduct={setIsEditingProduct}
          setRowId={setRowId}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            fetchSemaphore();
            setOpen(false);
          }}
        >
          {t('Fechar', { ns: 'maxion' })}
        </Button>
      </DialogActions>
      {loading && <Loading />}
    </Dialog>
  );
}
