import React, { useEffect, useState } from 'react';
import { useMaskito } from '@maskito/react';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { TextField } from '@mui/material';
import { maskitoTransform } from '@maskito/core';
import { maskitoParseNumber } from '@maskito/kit';

export default function TextFieldMaskNumberV2(props) {
  const [value, setValue] = useState('');

  const numberOptions = {
    precision: props.mask.precision,
    decimalSeparator: props.mask.decimalSeparator,
    thousandSeparator: props.mask.thousandSeparator,
  };

  const maskitoNumberOptions = maskitoNumberOptionsGenerator(numberOptions);

  const inputRef = useMaskito({ options: maskitoNumberOptions });

  useEffect(() => {
    if (props.value !== undefined) {
      const formattedValue = formatValue(props.value);
      setValue(formattedValue);
    }
  }, [props.value, numberOptions.precision, numberOptions.decimalSeparator]);

  const formatValue = (val) => {
    if (typeof val === 'number') {
      return maskitoTransform(
        val
          .toFixed(numberOptions.precision)
          .replace('.', numberOptions.decimalSeparator),
        maskitoNumberOptions
      );
    }
    return val;
  };

  const handleInput = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (props.onInput) {
      const numericValue = maskitoParseNumber(
        newValue,
        numberOptions.decimalSeparator
      );
      e.target.numericValue = numericValue;
      props.onInput(e);
    }
  };

  const handleBlur = (e) => {
    if (props.onBlur) {
      const numericValue = maskitoParseNumber(
        e.target.value,
        numberOptions.decimalSeparator
      );
      e.target.numericValue = numericValue;
      props.onBlur(e);
    }
  };

  return (
    <TextField
      value={value}
      onChange={handleInput}
      fullWidth
      inputRef={inputRef}
      size={props.size}
      name={props.name}
      label={props.label}
      onBlur={handleBlur}
      style={props.style}
      disabled={props.disabled}
    />
  );
}
