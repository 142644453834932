import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Breadcrumbs, Button, CircularProgress, Divider } from '@mui/material';
import ModalUpload from './components/ModalUpload';
import { ReportProvider } from '../ReportGeneral/components/ReportProvider';
import axios from '../../../../../utils/axios';
import ModalLogs from './components/ModalLogs';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.gray_500};
      cursor: pointer;
      font-size: 13.5px;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

const BoxContent = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;

  h2 {
    font-size: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }

  .info-plano {
    margin: 40px 0;
    padding: 0 20px;

    .flex {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      width: 80%;
      justify-content: space-between;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
    }

    b {
      color: ${({ theme }) => theme.colors.gray_300};
    }
  }

  .observations {
    flex: 1 1 auto;
    white-space: normal;
    word-wrap: break-word;
    overflow: visible;
    min-width: 0;
  }

  .workDays-and-vehicles {
    position: relative;
    margin: 40px 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .label {
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
      margin-bottom: 10px;
    }
  }
`;

function ArquivosAdmin({ t, invisibleNumber }) {
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalLogs, setOpenModalLogs] = useState(false);
  const [dataControl, setDataControl] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [logId, setLogId] = useState(null);

  const columnsBase = useMemo(() => {
    return ['Arquivo', 'Status', 'Log', 'Data', 'Usuário'];
  }, []);

  const getFileList = async () => {
    setIsLoadingData(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API}upload_file/importer/list`
    );

    console.log('DATA', data);

    setIsLoadingData(false);

    setDataControl(data.uploads);
  };

  useEffect(() => {
    document.title = t('Importação de arquivos', { ns: 'maxion' });
    getFileList();
  }, []);

  const CellData = styled(TableCell)`
    width: 150px;
    text-align: center;
  `;

  const BoxContentMain = styled(BoxContent)`
    min-height: calc(100vh - 650px);
    padding: 10px;
    width: 100%;
  `;

  const TableDiv = styled.div`
    width: 95%;

    .table-container {
      margin: 20px;
      height: 100vh !important;
      overflow-y: auto;
      width: 100%;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
    }

    table tr:last-child .border-bottom {
      border-bottom: 2px solid #0783c2;
    }

    th {
      color: #0783c2;
      background-color: #c2ddf0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    th,
    td {
      padding: 5px;
      text-align: center;
      font-size: 14px;
    }

    thead th {
      background-color: #c2ddf0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .row-title th {
      background-color: #f4f7fc;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 20px !important;
    }

    .row-collapsible {
      background-color: #0783c2;
    }

    tbody tr:nth-child(even) {
      background-color: #f4f8fc;
    }

    .border-left {
      border-left: 2px solid #0783c2;
    }

    .border-right {
      border-right: 2px solid #0783c2;
    }

    .border-top {
      border-top: 2px solid #0783c2;
    }

    tr {
      line-height: 15px;
    }
  `;

  return (
    <ReportProvider t={t}>
      <div style={{ width: '100%' }}>
        <Helmet title={t('Importação de Arquivos', { ns: 'maxion' })} />

        <Infobar t={t}>
          <div>
            <div>
              <h2> {t('Importação de Arquivos', { ns: 'maxion' })}</h2>

              <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
                <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

                <p>{t('Admin', { ns: 'maxion' })} </p>

                <span>{t('Importação de arquivos', { ns: 'maxion' })}</span>
              </Breadcrumbs>
            </div>

            <Divider className="divider" />
          </div>
        </Infobar>

        <BoxContentMain>
          {isLoadingData && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 60,
              }}
            >
              <CircularProgress size={50} />
            </div>
          )}
          {!isLoadingData && (
            <TableDiv>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  padding: 20,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenModalUpload(true)}
                >
                  {t('Enviar arquivo', { ns: 'maxion' })}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => getFileList()}
                  style={{ marginLeft: '10px' }}
                >
                  {t('Atualizar lista', { ns: 'maxion' })}
                </Button>
              </div>
              <TableContainer component={Paper} className={'table-container'}>
                <Table aria-label="collapsible table" stickyHeader>
                  <TableHead>
                    <TableRow className={'row-title'}>
                      {columnsBase &&
                        columnsBase.length > 0 &&
                        columnsBase.map((column, idxCol) => (
                          <CellData component="th" key={`${column}-${idxCol}`}>
                            {t(column, { ns: 'maxion' })}
                          </CellData>
                        ))}
                    </TableRow>
                  </TableHead>
                  <tbody>
                    {dataControl.length > 0 &&
                      dataControl.map((row, idxRow) => (
                        <TableRow
                          key={`row-tb-${idxRow}`}
                          style={{ height: '50px' }}
                        >
                          <TableCell>{row.file_name}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                console.log('ROW', row);
                                if (row.log_id) {
                                  setLogId(row.log_id);
                                  setOpenModalLogs(true);
                                }
                              }}
                              style={{ marginLeft: '10px' }}
                            >
                              {t('Visualizar logs', { ns: 'maxion' })}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {new Date(row.created_at).toLocaleDateString(
                              'pt-br'
                            )}{' '}
                            -{' '}
                            {new Date(row.created_at).toLocaleTimeString(
                              'pt-br'
                            )}
                          </TableCell>
                          <TableCell>{row.user_upload}</TableCell>
                        </TableRow>
                      ))}
                  </tbody>
                </Table>
                {/*</div>*/}
              </TableContainer>
            </TableDiv>
          )}
        </BoxContentMain>
        <ModalUpload
          t={t}
          open={openModalUpload}
          setOpen={setOpenModalUpload}
          getFileList={getFileList}
        />
        <ModalLogs
          t={t}
          open={openModalLogs}
          setOpen={setOpenModalLogs}
          logId={logId}
        />
      </div>
    </ReportProvider>
  );
}

export default withTranslation('maxion')(ArquivosAdmin);
