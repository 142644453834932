import React from 'react';

import async from './components/Async';

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import BaseLayout from './layouts/BaseLayout';

// Guards
import AuthGuard from './components/guards/AuthGuard';

// Auth components
import SignIn from './pages/SignIn/SignIn';
import Page404 from './components/Page404';
import Page500 from './components/Page500';

// Somativa
import Home from './pages/Home/Home';
import User from './pages/pages/Administrativo/User';
import DepartmentPage from './pages/pages/Administrativo/Department';
import RolePage from './pages/pages/Administrativo/RolePage';
import MonitoramentoDeUso from './pages/pages/Administrativo/Monitoramento';
import DataFechamento from './pages/pages/Administrativo/DataFechamento';
import TableauPage from './pages/pages/TableauPage/Tableau';
import HistoricCriticas from './pages/pages/NumerosMaxion/PrevisaoDeFaturamento/components/HistoricCriticas';

//Números Maxion
import PlanoOperacional from './pages/pages/NumerosMaxion/PlanoOperacional/';
import NovosNegocios from './pages/pages/NumerosMaxion/NovosNegocios/';

//Mercado
import Emplacamentos from './pages/pages/Mercado/Emplacamentos';
import ApresentacaoOperacional from './pages/pages/Mercado/ApresentacaoOperacional';
import DadosAutomotivosBR from './pages/pages/Mercado/DadosAutomotivosBR';
import DadosHistoricosBR from './pages/pages/Mercado/DadosHistoricosBR';
import Forecasts from './pages/pages/Mercado/Forecasts';
import MercadoEVs from './pages/pages/Mercado/MercadoEVs';
import CyclePlanVeic from './pages/pages/Mercado/CyclePlanVeiculos';
import PrecoVeiculosFipeBR from './pages/pages/Mercado/PrecoVeiculosFipeBR';
import DadosMacro from './pages/pages/Mercado/DadosMacro/';
import Concorrentes from './pages/pages/Mercado/Concorrentes';
import Clientes from './pages/pages/Mercado/Clientes';
import InfoFinanceira from './pages/pages/Mercado/InfoFinanceira';

//Maxion X Mercado
import AlinhamentoReceitaMaxion from './pages/pages/MaxionXMercado/AlinhamentoReceitaMaxion/';
import MapeamentoMercado from './pages/pages/MaxionXMercado/MapeamentoMercado/';
import MatrizClienteProduto from './pages/pages/MaxionXMercado/MatrizClienteProduto';
import ImporterPage from './pages/pages/Administrativo/Importer';
import ArquivosAdmin from './pages/pages/NumerosMaxion/PlanoOperacional/ArquivosAdmin';

//Componentes  mais pesados
const PrevisaoDeFaturamento = async(() =>
  import('./pages/pages/NumerosMaxion/PrevisaoDeFaturamento/')
);
const Explorar = async(() =>
  import('./pages/pages/EstrategiaisMateriais/Explorar/Explorar')
);
const Details = async(() =>
  import('./pages/pages/EstrategiaisMateriais/Details/Details')
);
const OldExplorar = async(() => import('./components/OldExplorar'));

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <Home />
      </AuthGuard>
    ),
  },
  {
    path: '/numbers-maxion',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'sales-forecast',
        element: <PrevisaoDeFaturamento />,
      },
      {
        path: 'operational-plan',
        element: <PlanoOperacional />,
      },
      {
        path: 'new-business',
        element: <NovosNegocios />,
      },
      {
        path: 'versions',
        element: <NovosNegocios />,
      },
    ],
  },
  {
    path: '/marketplace',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'registration',
        element: <Emplacamentos />,
      },
      {
        path: 'operational-presentation',
        element: <ApresentacaoOperacional />,
      },
      {
        path: 'automotive-data-br',
        element: <DadosAutomotivosBR />,
      },
      {
        path: 'historic-data-br',
        element: <DadosHistoricosBR />,
      },
      {
        path: 'forecasts',
        element: <Forecasts />,
      },
      {
        path: 'market-evs',
        element: <MercadoEVs />,
      },
      {
        path: 'cycle-plan-vehicles',
        element: <CyclePlanVeic />,
      },
      {
        path: 'vehicle-price-br-fipe',
        element: <PrecoVeiculosFipeBR />,
      },
      {
        path: 'macroeconomic-data',
        element: <DadosMacro />,
      },
      {
        path: 'competitors',
        element: <Concorrentes />,
      },
      {
        path: 'customers',
        element: <Clientes />,
      },
      {
        path: 'infos',
        element: <InfoFinanceira />,
      },
    ],
  },
  {
    path: '/maxion-marketplace',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'maxion-recipe-alignment',
        element: <AlinhamentoReceitaMaxion />,
      },
      {
        path: 'market-mapping',
        element: <MapeamentoMercado />,
      },
      {
        path: 'customer-product-matrix',
        element: <MatrizClienteProduto />,
      },
    ],
  },
  {
    path: '/marketing-content',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'explore',
        element: <Explorar />,
      },
      {
        path: 'details/:foldId/:childId',
        element: <Details />,
      },
      {
        path: 'old-explore',
        element: <OldExplorar />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'tableau',
        element: <TableauPage />,
      },
    ],
  },
  {
    path: '/admin',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'user',
        element: <User />,
      },
      {
        path: 'department',
        element: <DepartmentPage />,
      },
      {
        path: 'role',
        element: <RolePage />,
      },
      {
        path: 'closing-date',
        element: <DataFechamento />,
      },
      {
        path: 'monitoring',
        element: <MonitoramentoDeUso />,
      },
      {
        path: 'historic-criticas',
        element: <HistoricCriticas />,
      },
      {
        path: 'importing',
        element: <ArquivosAdmin />,
      },
    ],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '500',
        element: <Page500 />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
