//Importações
import React from 'react';
import styled from '@emotion/styled';
//Components
import FooterLinks from './components/FooterLinks';
//Material UI
import { Box as BoxMUI } from '@mui/material';

const Box = styled(BoxMUI)`
  display: flex;
  margin-top: 200px;
`;

const linksOne = [
  {
    title: 'Emplacamento diário',
    route: '/marketplace/registration',
    permissions: [],
    roles: [2],
  },
  {
    title: 'Cycle Plan',
    route: '/marketplace/cycle-plan-vehicles',
    permissions: [],
    roles: [3],
  },
  {
    title: 'Forecasts ICE',
    route: '/marketplace/forecasts',
    permissions: [],
    roles: [3],
  },
];

const liknsTwo = [
  {
    title: 'Forecasts EV',
    route: '/marketplace/market-evs',
    permissions: [],
    roles: [3],
  },
  {
    title: 'Clientes',
    route: '/marketplace/customers',
    permissions: [],
    roles: [4, 7],
  },
  {
    title: 'Concorrentes',
    route: '/marketplace/competitors',
    permissions: [],
    roles: [4],
  },
];

export default function Footer({ t }) {
  return (
    <Box>
      <FooterLinks title={t('Atalhos', { ns: 'maxion' })} links={linksOne} />
      <FooterLinks title={t('Destaques', { ns: 'maxion' })} links={liknsTwo} />
    </Box>
  );
}
