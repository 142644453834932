import GraficoPO from './GraficoPO';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CircularProgress, Switch } from '@mui/material';
import styled from '@emotion/styled';
import TabelaPO from './TabelaPO';
import axios from '../../../../../../../utils/axios';
import { POContext } from '../../../../../../../contexts/POContext';
import OverviewReceita from './OverviewReceita';
import ModalPoReportProductsDetails from '../../POReports/components/ModalPoReportProductsDetails';
import { Button } from '@mui/material';

const Div = styled.div`
  .content-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .content {
    margin-top: 30px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .spinner-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

const InfoTitle = styled.h2`
  color: ${({ theme }) => theme.colors.blue_300};
  font-size: 24.5px;
  margin-top: 12px;
`;

export default function MainContent({ t }) {
  const [infoDataType, switchInfoDataType] = useState('value');
  const [dataInfo, setDataInfo] = useState(null);
  const [overviewData, setOverviewData] = useState([]);
  const [isLoadingDataInfo, setIsLoadingDataInfo] = useState(true);
  const [isLoadingOverview, setIsLoadingOverview] = useState(true);
  const [openModalReportProductDetails, setOpenModalReportProductDetails] =
    useState(false);

  const { version, customer, isRefreshCritic, setIsRefreshCritic } =
    useContext(POContext);

  async function fetchDataValues() {
    try {
      setIsLoadingDataInfo(true);
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/get_op_totals?version_id=${version.id}&sk_grupo_cliente=${customer.sk_grupo_cliente}`
      );

      setDataInfo(response.data);
    } catch (error) {
      console.log(`Error fetching data: ${error}`);
    } finally {
      setIsLoadingDataInfo(false);
    }
  }

  async function fetchOverviewValues() {
    try {
      setIsLoadingOverview(true);
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/get_op_overview_receita?version_id=${version.id}&sk_grupo_cliente=${customer.sk_grupo_cliente}`
      );

      setOverviewData(response.data);
    } catch (error) {
      console.log(`Error fetching data ${error}`);
    } finally {
      setIsLoadingOverview(false);
    }
  }

  function onInnitialize() {
    setIsRefreshCritic(false);
  }

  useEffect(() => {
    if (isRefreshCritic) {
      fetchDataValues();
      fetchOverviewValues();
      onInnitialize();
    }
  }, [isRefreshCritic, dataInfo, overviewData]);

  useEffect(() => {
    fetchDataValues();
    fetchOverviewValues();
  }, []);

  const props = {
    t,
    infoDataType,
    switchInfoDataType,
    dataInfo,
    setDataInfo,
    fetchDataValues,
    overviewData,
  };

  return (
    <Div>
      <div className="content-container">
        <div className="content">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <InfoTitle>{t('Gráfico comparativo', { ns: 'maxion' })} </InfoTitle>

            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <Button
                variant="contained"
                color="secondary"
                mb={5}
                onClick={() => setOpenModalReportProductDetails(true)}
              >
                {t('Produtos de forma detalhada', { ns: 'maxion' })}
              </Button>

              <div
                className="fixed switch"
                style={{
                  display: 'flex',
                  justifyContent: ' center',
                  alignItems: 'center',
                }}
              >
                <p>{t('Valor', { ns: 'maxion' })}</p>
                <Switch
                  onChange={() =>
                    switchInfoDataType(
                      infoDataType === 'value' ? 'volume' : 'value'
                    )
                  }
                  variant="outlined"
                />
                <p>{t('Volume', { ns: 'maxion' })}</p>
              </div>
            </div>
          </div>
          {isLoadingDataInfo === true ? (
            <div className="spinner-container">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <GraficoPO {...props} />
            </div>
          )}
        </div>
        <div className="content">
          <InfoTitle> {t('Tabela comparativa', { ns: 'maxion' })} </InfoTitle>
          {isLoadingDataInfo === true ? (
            <div className="spinner-container">
              <CircularProgress />
            </div>
          ) : (
            <TabelaPO {...props} />
          )}
        </div>
        <div className="content">
          <InfoTitle> {t('Overview de receita', { ns: 'maxion' })} </InfoTitle>
          {isLoadingOverview === true ? (
            <div className="spinner-container">
              <CircularProgress />
            </div>
          ) : (
            <OverviewReceita {...props} />
          )}
        </div>
      </div>

      {
        <ModalPoReportProductsDetails
          t={t}
          versionId={version.id}
          customerId={customer.sk_grupo_cliente}
          openModal={openModalReportProductDetails}
          setOpenModal={setOpenModalReportProductDetails}
        />
      }
    </Div>
  );
}
