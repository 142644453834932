import { CircularProgress as CircularProgressMUI } from '@mui/material';
import {
  AppRegistration as AppRegistrationIconMUI,
  ExpandMore as ExpandMoreIconMUI,
} from '@mui/icons-material/';

export function AppRegistrationIcon(props) {
  return <AppRegistrationIconMUI {...props} />;
}

export function ExpandMoreIcon(props) {
  return <ExpandMoreIconMUI {...props} />;
}

export function CircularProgress(props) {
  return <CircularProgressMUI {...props} />;
}
