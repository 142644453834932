const baseItems = {
  page: 'Previsão de faturamento',
  buttons: [
    {
      title: 'Cycle Plan',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-IHS/Painel10-CyclePlan',
      height: '2500px',
    },
  ],
};

export default baseItems;
