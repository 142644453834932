import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { POContext } from '../../../../../../../contexts/POContext';
import axios from '../../../../../../../utils/axios';
import { toast } from 'react-toastify';

import {
  Chip as MuiChip,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell as TableCellMui,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { spacing } from '@mui/system';

import Loading from '../../../../../../../components/Loading';
import MenuHamburguer from './MenuHamburguer';
import ModalNewVersion from './ModalNewVersion';
import ModalEditVersion from './ModalEditVersion';
import ModalDeleteVersion from './ModalDeleteVersion';
import ModalCloneVersion from './ModalCloneVersion';
import ModalReactivateVersion from './ModalReactivateVersion';
import useAuth from '../../../../../../../hooks/useAuth';
import { checkPermission } from '../../../../../../../utils/permissions';

// REAVISAR PERMISSOES ROLES E TRADUCAO -----------------------------------------------------------------------------------------------------------

const TableCell = styled(TableCellMui)`
  color: #4b4d4f;
`;

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 200px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    t,
    userHasPermissionToInteract,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'year',
      alignment: 'center',
      label: t('Ano', { ns: 'maxion' }),
      sortable: false,
    },
    {
      id: 'name',
      alignment: 'center',
      label: t('Nome', { ns: 'maxion' }),
      sortable: false,
    },
    {
      id: 'base_date',
      alignment: 'center',
      label: t('Base', { ns: 'maxion' }),
      sortable: false,
    },
    {
      id: 'observations',
      alignment: 'center',
      label: t('Observações', { ns: 'maxion' }),
      sortable: false,
    },
    {
      id: 'createdAt',
      alignment: 'center',
      label: t('Data de criação', { ns: 'maxion' }),
      sortable: false,
    },
    {
      id: 'updatedAt',
      alignment: 'center',
      label: t('Data de modificação', { ns: 'maxion' }),
      sortable: false,
    },
    {
      id: 'final_version',
      alignment: 'center',
      label: t('Status', { ns: 'maxion' }),
      sortable: false,
    },
  ];

  if (userHasPermissionToInteract) {
    headCells.push({
      id: 'actions',
      alignment: 'center',
      label: t('Ações', { ns: 'maxion' }),
      sortable: false,
    });
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            align={headCell.alignment}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <span style={{ display: 'flex' }}>
                {headCell.label}
                <div style={{ width: '18px', heigth: '18px' }} />
              </span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, onDelete, t } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {t('Versões selecionada(s)', { ns: 'maxion' })}
          </Typography>
        ) : (
          <Typography
            variant="h3"
            id="tableTitle"
            fontSize="25px"
            color="#4b4d4f"
            fontWeight="500"
          >
            {t('Versões', { ns: 'maxion' })}
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      {numSelected > 0 && (
        <Tooltip title={t('Excluir', { ns: 'maxion' })} onClick={onDelete}>
          <IconButton aria-label={t('Excluir', { ns: 'maxion' })} size="large">
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default function OPVersionTable({
  t,
  openModalNewVersion,
  setOpenModalNewVersion,
  filteredYears,
}) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(null);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loadingRows, setLoadingRows] = useState(true);

  const [openModalEditVersion, setOpenModalEditVersion] = useState(false);
  const [openModalDeleteVersion, setOpenModalDeleteVersion] = useState(false);
  const [openModalReactivateVersion, setOpenModalReactivateVersion] =
    useState(false);
  const [openModalCloneVersion, setOpenModalCloneVersion] = useState(false);

  const { setInvisibleNumber, setVersionId, setVersion } =
    useContext(POContext);

  const { user } = useAuth();

  const userHasPermissionToInteract = user && checkPermission(user, [], [9]);

  const fetchVersions = useCallback(async () => {
    try {
      setLoadingRows(true);
      let tableData;

      if (!filteredYears || filteredYears.length === 0) {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API}po_versions?order_by=deleted_at&order_type=desc`
        );
        tableData = response.data;
      } else {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API}po_versions/year/${filteredYears}?order_by=deleted_at&order_type=desc`
        );
        toast.success(t('Versões filtradas com sucesso'), { ns: 'maxion' });

        tableData = response.data;
      }

      const updatedRows = tableData.map((row) => {
        const base_date = row.base_date;
        const monthRaw = parseInt(base_date.substring(5, 7));
        const prevision = 12 - monthRaw;
        const base_date_formatted = `${monthRaw}+${prevision}`;

        const dateString = row.base_date;
        const year = parseInt(dateString.substring(0, 4));
        const adjustedYear = year + 1;

        const createdDate = row.created_at ? new Date(row.created_at) : null;
        const updatedDate = row.updated_at ? new Date(row.updated_at) : null;
        const deletedDate = row.deleted_at ? new Date(row.deleted_at) : null;

        const createdDateFormatted = createdDate
          ? format(createdDate, 'dd/MM/yyyy')
          : 'N/A';
        const updatedDateFormatted = updatedDate
          ? format(updatedDate, 'dd/MM/yyyy')
          : 'N/A';
        const deletedDateFormatted = deletedDate
          ? format(deletedDate, 'dd/MM/yyyy')
          : 'N/A';

        return {
          ...row,
          year: adjustedYear,
          base: base_date_formatted,
          newCreated_at: createdDateFormatted,
          newUpdated_at: updatedDateFormatted,
          newDeleted_at: deletedDateFormatted,
        };
      });

      setLoadingRows(false);
      setRows(updatedRows);
    } catch (error) {
      setLoadingRows(false);
      console.log(error);
    }
  }, [filteredYears]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchVersions();
  }, [filteredYears, fetchVersions]);

  useEffect(() => {
    if (filteredYears && filteredYears.length > 0) {
    }
  }, [fetchVersions, filteredYears]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function handleSelectedRow(row) {
    setSelectedRow(row);
  }

  return (
    <div>
      <Paper style={{ position: 'relative', margin: '0px 20px' }}>
        <EnhancedTableToolbar
          userHasPermissionToInteract={userHasPermissionToInteract}
          numSelected={selected.length}
          t={t}
        />

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              userHasPermissionToInteract={userHasPermissionToInteract}
              t={t}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setVersion(row);
                        setVersionId(row.id);
                        setInvisibleNumber(1);
                      }}
                    >
                      <TableCell>
                        {row.year === null ? 'N/A' : row.year}
                      </TableCell>
                      <TableCell>
                        {row.name === null ? 'N/A' : row.name}
                      </TableCell>
                      <TableCell>
                        {row.base === null ? 'N/A' : row.base}
                      </TableCell>
                      <TableCell>
                        {row.observations === null ? 'N/A' : row.observations}
                      </TableCell>
                      <TableCell>
                        {row.newCreated_at === null ? 'N/A' : row.newCreated_at}
                      </TableCell>
                      <TableCell>
                        {row.newUpdated_at === null ? 'N/A' : row.newUpdated_at}
                      </TableCell>
                      <TableCell>
                        {row.final_version ? (
                          <Chip
                            color="tertiary"
                            size="small"
                            mr={1}
                            mb={1}
                            label={t('Versão final', {
                              ns: 'maxion',
                            }).toUpperCase()}
                          />
                        ) : row.deleted_at === null ? (
                          <Chip
                            color="secondary"
                            size="small"
                            mr={1}
                            mb={1}
                            label={t('Ativo', {
                              ns: 'maxion',
                            }).toUpperCase()}
                          />
                        ) : (
                          <Chip
                            color="warning"
                            size="small"
                            mr={1}
                            mb={1}
                            label={t('Inativo', {
                              ns: 'maxion',
                            }).toUpperCase()}
                          />
                        )}
                      </TableCell>
                      {userHasPermissionToInteract && (
                        <TableCell>
                          <div
                            onClick={(event) => {
                              handleSelectedRow(row);
                              event.stopPropagation();
                            }}
                          >
                            <MenuHamburguer
                              fetchVersions={fetchVersions}
                              setOpenModalEditVersion={setOpenModalEditVersion}
                              setOpenModalDeleteVersion={
                                setOpenModalDeleteVersion
                              }
                              setOpenModalCloneVersion={
                                setOpenModalCloneVersion
                              }
                              setOpenModalReactivateVersion={
                                setOpenModalReactivateVersion
                              }
                              row={row}
                              t={t}
                            />
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={`${t('Linhas por página', {
              ns: 'maxion',
            })}:`}
            labelDisplayedRows={({ from, to, count }) =>
              ` ${t('Exibindo', { ns: 'maxion' })} ${from}-${to} ${t('de', {
                ns: 'maxion',
              })} ${count} ${t('itens', { ns: 'maxion' })}`
            }
          />
        </TableContainer>

        {loadingRows && <Loading />}
      </Paper>
      {openModalEditVersion && (
        <ModalEditVersion
          fetchVersions={fetchVersions}
          openModalEditVersion={openModalEditVersion}
          setOpenModalEditVersion={setOpenModalEditVersion}
          row={selectedRow}
          t={t}
        />
      )}
      {openModalDeleteVersion && (
        <ModalDeleteVersion
          fetchVersions={fetchVersions}
          openModalDeleteVersion={openModalDeleteVersion}
          setOpenModalDeleteVersion={setOpenModalDeleteVersion}
          row={selectedRow}
          t={t}
        />
      )}
      {openModalCloneVersion && (
        <ModalCloneVersion
          fetchVersions={fetchVersions}
          openModalCloneVersion={openModalCloneVersion}
          setOpenModalCloneVersion={setOpenModalCloneVersion}
          row={selectedRow}
          t={t}
        />
      )}
      {openModalReactivateVersion && (
        <ModalReactivateVersion
          fetchVersions={fetchVersions}
          openModalReactivateVersion={openModalReactivateVersion}
          setOpenModalReactivateVersion={setOpenModalReactivateVersion}
          row={selectedRow}
          t={t}
        />
      )}
      {openModalNewVersion && (
        <ModalNewVersion
          fetchVersions={fetchVersions}
          openModalNewVersion={openModalNewVersion}
          setOpenModalNewVersion={setOpenModalNewVersion}
          row={selectedRow}
          t={t}
        />
      )}
    </div>
  );
}
