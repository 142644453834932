import React from 'react';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import SignInComponent from './components/SignInComponent';

const Wrapper = styled.div`
  background-color: transparent;
  padding: 40px;
  color: rgba(0, 0, 0, 0.87);

  h2 {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    color: #fafafa;
  }
`;

function SignIn({ t }) {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Login" />

        <h2>{t('Entre com sua conta de rede', { ns: 'maxion' })}</h2>

        <SignInComponent t={t} />
      </Wrapper>
    </React.Fragment>
  );
}

export default withTranslation('maxion')(SignIn);
