import * as C from '../../Versions/NovosProdutos/components/styles';
import { Button } from '@mui/material';
import { Form } from 'formik';
import UnitSelectAsync from '../../../../../../components/UnitSelectAsync';
import { useContext } from 'react';
import { ReportContext } from './ReportProvider';

export default function ReportGeneralFilters({ t }) {
  const {
    initialValues,
    isLoadingParameters,
    dataParameters,
    formikFilter,
    resetFilters,
    versionYears,
    isLoadingYears,
  } = useContext(ReportContext);

  return (
    <>
      <C.Card>
        <C.CardContent>
          <form onSubmit={formikFilter.handleSubmit}>
            <div className="item">
              <p>{t('Ano do Plano', { ns: 'maxion' })}*</p>
              <div>
                <UnitSelectAsync
                  limitTags={0}
                  size="small"
                  name="report_date"
                  value={
                    formikFilter.values.report_date === '' ||
                    versionYears.length === 0
                      ? null
                      : formikFilter.values.report_date
                  }
                  label={t('Ano do Plano', { ns: 'maxion' })}
                  loading={isLoadingYears}
                  disabled={isLoadingYears}
                  loadingText={t('Carregando', { ns: 'maxion' })}
                  options={versionYears ? versionYears : []}
                  getOptionLabel={(option) => option.label}
                  onBlur={formikFilter.handleBlur}
                  onChange={(event, value) =>
                    formikFilter.setFieldValue('report_date', value)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.key === value.key
                  }
                  disableCloseOnSelect
                />
              </div>
            </div>
            <div className="item">
              <p>{t('Versões', { ns: 'maxion' })}</p>
              <div>
                <UnitSelectAsync
                  limitTags={0}
                  size="small"
                  name="versions"
                  value={
                    formikFilter.values.versions === ''
                      ? null
                      : formikFilter.values.versions
                  }
                  label={t('Versões', { ns: 'maxion' })}
                  loading={isLoadingParameters}
                  disabled={isLoadingParameters}
                  loadingText={t('Carregando', { ns: 'maxion' })}
                  options={
                    dataParameters.versions ? dataParameters.versions : []
                  }
                  getOptionLabel={(option) => option.label}
                  onBlur={formikFilter.handleBlur}
                  onChange={(event, value) =>
                    formikFilter.setFieldValue('versions', value)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.key === value.key
                  }
                  disableCloseOnSelect
                  multiple
                />
              </div>
            </div>
            <div className="item">
              <p>{t('Bases', { ns: 'maxion' })}</p>
              <div>
                <UnitSelectAsync
                  limitTags={0}
                  size="small"
                  name="bases"
                  value={
                    formikFilter.values.bases === ''
                      ? null
                      : formikFilter.values.bases
                  }
                  label={t('Bases', { ns: 'maxion' })}
                  loading={isLoadingParameters}
                  disabled={isLoadingParameters}
                  loadingText={t('Carregando', { ns: 'maxion' })}
                  options={
                    dataParameters.base_dates ? dataParameters.base_dates : []
                  }
                  getOptionLabel={(option) => option.label}
                  onBlur={formikFilter.handleBlur}
                  onChange={(event, value) =>
                    formikFilter.setFieldValue('bases', value)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.key === value.key
                  }
                  disableCloseOnSelect
                  multiple
                />
              </div>
            </div>
            <div className="item">
              <p>{t('Clientes', { ns: 'maxion' })}</p>
              <div>
                <UnitSelectAsync
                  limitTags={0}
                  size="small"
                  name="bases"
                  value={
                    formikFilter.values.customers === ''
                      ? null
                      : formikFilter.values.customers
                  }
                  label={t('Clientes', { ns: 'maxion' })}
                  loading={isLoadingParameters}
                  disabled={isLoadingParameters}
                  loadingText={t('Carregando', { ns: 'maxion' })}
                  options={
                    dataParameters.customers ? dataParameters.customers : []
                  }
                  getOptionLabel={(option) => option.label}
                  onBlur={formikFilter.handleBlur}
                  onChange={(event, value) =>
                    formikFilter.setFieldValue('customers', value)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.key === value.key
                  }
                  disableCloseOnSelect
                  multiple
                />
              </div>
            </div>

            <div>
              <div
                style={{
                  flexDirection: 'row',
                  marginTop: 26,
                }}
              >
                <Button
                  color="primary"
                  autoFocus
                  type="submit"
                  variant="contained"
                  style={{ width: 120 }}
                >
                  {t('Filtrar', { ns: 'maxion' })}
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  onClick={() => {
                    const selectedYear = formikFilter.values.report_date;

                    formikFilter.setValues({
                      ...initialValues,
                      report_date: selectedYear,
                    });
                    resetFilters();
                  }}
                  type="button"
                  variant="contained"
                  style={{ width: 120, marginLeft: 10 }}
                >
                  {t('Limpar', { ns: 'maxion' })}
                </Button>
              </div>
            </div>
          </form>
        </C.CardContent>
      </C.Card>
    </>
  );
}
