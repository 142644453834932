import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import axios from '../../../../../utils/axios';

import { months } from '../../../Critica/monthsValueArray';

import { UnitSelect } from '../../../../../components/Select';
import { Button } from '../../../../../components/Buttons';
import { TextField } from '../../../../../components/TextField';
import { CircularProgress } from '../../../../../components/Icons';

import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Breadcrumbs,
  Divider,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

const Result = styled.div`
  padding: 25px 0;
`;

const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
`;

const CardContent = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;

  span {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }
`;

const ButtonFilter = styled(Button)`
  background-color: #008cca;
  width: 160px;
  height: 35px;
  font-size: 15px;
`;

const InputDate = styled(TextField)`
  min-width: 290px;
  border-radius: 4px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray_500};
`;

const ClosedDate = styled.b`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray_500};
  float: right;
`;

const BoxCritica = styled.div`
  background: #eff3f9;
  padding: 20px;
  margin-bottom: 20px;
  /* max-width: 45%; */
  border-radius: 3px;

  h2 {
    color: ${({ theme }) => theme.colors.blue_200};
    font-weight: 400;
  }

  span {
    background-color: #fff;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const KeyboardArrowUp = styled(KeyboardArrowUpIcon)`
  background: #498ac9;
  fill: #fff;
  border-radius: 50%;
`;

const Overflow = styled.div`
  max-height: 40vh;
  overflow-y: auto;
`;

const TableCritica = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #c8dcee;
  }

  th {
    padding: 4px 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #2a97cf;
  }

  td {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray_500};
    text-align: center;
    padding: 7px 0;
  }

  .title-row {
    text-align: left;
    display: block;
    padding-left: 15px;
    font-weight: 600;
  }

  .row-light-blue {
    background-color: #f4f8fc;
  }
`;

const TextError = styled.p`
  color: ${({ theme }) => theme.colors.error}!important;
  font-size: 12px !important;
  position: absolute;
  top: 38px;
  left: 3px;
`;

function Row({ item }) {
  const [open, setOpen] = useState(false);
  const type = item.critica_type;

  function formatDate(created_at) {
    const date = new Date(created_at).toLocaleDateString('pt-br');
    const time = new Date(created_at).toLocaleTimeString('pt-br').split(':');
    const hour = `${time[0]}:${time[1]}`;

    return `${date} ${hour}`;
  }

  function formatType(type) {
    let formatedText = '';

    switch (type) {
      case 'LINEAR_VOLUME':
        formatedText = 'Crítica por volume linear';
        break;
      case 'DETALHADA_VALOR':
        formatedText = 'Crítica por preço';
        break;
      case 'DETALHADA_VOLUME':
        formatedText = 'Crítica por volume detalhada';
        break;
      default:
        formatedText = '';
        break;
    }

    return formatedText;
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ borderBottom: open ? 'none' : '' }}>
          {formatDate(item.created_at)}
        </TableCell>

        <TableCell style={{ borderBottom: open ? 'none' : '' }}>
          {formatType(type)}
        </TableCell>

        <TableCell style={{ borderBottom: open ? 'none' : '' }}>
          {item.description}
        </TableCell>

        <TableCell style={{ borderBottom: open ? 'none' : '' }}>
          {item.user_name}
        </TableCell>

        <TableCell style={{ borderBottom: open ? 'none' : '' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{
            padding: '0px 16px',
            borderBottom: open ? '1px solid rgba(224, 224, 224, 1)' : '0',
            margin: open ? '0 10px 40px 10px' : '',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {item.critica_type === 'LINEAR_VOLUME' && (
              <BoxCritica>
                <span>
                  <TableCritica>
                    <thead>
                      <tr>
                        <th scope="col" width={20} />

                        {item.valuesMonths.map((item, index) => {
                          return (
                            <th scope="col" key={index}>
                              {item.abbreviation}
                              {/* {t(`${column}`, { ns: "maxion" })} */}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    <tbody>
                      <tr className="row-light-blue">
                        <td />

                        {item.valuesMonths.map((item, index) => {
                          return <td key={index}>{`${item.pct}%`}</td>;
                        })}
                      </tr>
                    </tbody>
                  </TableCritica>
                </span>
              </BoxCritica>
            )}

            {(type === 'DETALHADA_VALOR' || type === 'DETALHADA_VOLUME') && (
              <Overflow>
                <BoxCritica>
                  {item.values.map((item, index) => (
                    <React.Fragment key={index}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2px',
                          marginTop: index !== 0 ? '25px' : '',
                          marginBottom: '10px',
                        }}
                      >
                        <b>Produto: {item.name}</b>

                        {item.observation !== null && (
                          <p>Observação: {item.observation}</p>
                        )}
                      </div>

                      <span>
                        <TableCritica>
                          <thead>
                            <tr>
                              <th scope="col" width={100} />

                              {item.valuesMonths.map((item, index) => {
                                return (
                                  <th scope="col" key={index}>
                                    {item.abbreviation}
                                    {/* {t(`${column}`, { ns: "maxion" })} */}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>

                          <tbody>
                            {type === 'DETALHADA_VALOR' && (
                              <React.Fragment>
                                <tr className="row-light-blue">
                                  <td className="title-row">Negociação</td>

                                  {item.valuesMonths.map((item, index) => {
                                    return (
                                      <td key={index}>
                                        {`${item.pct_negoc}%`}
                                      </td>
                                    );
                                  })}
                                </tr>

                                <tr className="row-light-blue">
                                  <td className="title-row">Produção</td>

                                  {item.valuesMonths.map((item, index) => {
                                    return (
                                      <td key={index}>{`${item.pct_prod}%`}</td>
                                    );
                                  })}
                                </tr>

                                <tr className="row-light-blue">
                                  <td className="title-row">Punit</td>

                                  {item.valuesMonths.map((item, index) => {
                                    return (
                                      <td key={index}>
                                        {`${item.pct_punit}%`}
                                      </td>
                                    );
                                  })}
                                </tr>
                              </React.Fragment>
                            )}

                            {type === 'DETALHADA_VOLUME' && (
                              <tr className="row-light-blue">
                                <td className="title-row" />

                                {item.valuesMonths.map((item, index) => {
                                  return <td key={index}>{`${item.pct}%`}</td>;
                                })}
                              </tr>
                            )}
                          </tbody>
                        </TableCritica>
                      </span>
                    </React.Fragment>
                  ))}
                </BoxCritica>
              </Overflow>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function HistoryTable({ data, t }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>{t('Data', { ns: 'maxion' })}</TableCell>
            <TableCell>{t('Tipo', { ns: 'maxion' })}</TableCell>
            <TableCell>{t('Descrição', { ns: 'maxion' })}</TableCell>
            <TableCell>{t('Usuário', { ns: 'maxion' })}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <Row key={index} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ComponentHistoryCriticas({ t }) {
  const [customers, setCustomers] = useState([]);

  const [dataFechamento, setDataFechamento] = useState(null);
  const [extend, setExtend] = useState(false);

  const [logs, setLogs] = useState(null);

  const [loadingFilter, setLoadingFilter] = useState(false);

  useEffect(() => {
    Promise.all([fetchCustomers()], [fetchClosingDate()]);
  }, []);

  //Buscando clientes
  async function fetchCustomers() {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}criticas/grupo_cliente`,
        {
          search: '',
        }
      );

      setCustomers(data);
    } catch (error) {
      console.log(`Erro fetching customers:  ${error}`);
    }
  }

  //Buscando data de Fechamento
  async function fetchClosingDate() {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}parameters/name/MES_ULTIMO_FECHAMENTO`
      );

      if (data.value === null) return;

      const split = data.value.split('-');

      const year = split[0];
      const month = split[1];

      let extend = null;

      if (Number(month) === 12) {
        extend = false;
      } else if (Number(month) > 6) {
        extend = true;
      } else {
        extend = false;
      }

      setExtend(extend);
      setDataFechamento({ month, year });
    } catch (error) {
      console.log(`Error fetching closing date: ${error}`);
    }
  }

  //Função disparada quando o usuário clicar no botão "Filtrar"
  async function handleFilter(values) {
    try {
      const split = values.date.split('/');
      const baseDate = `${split[1]}-${split[0]}-01`;
      const customerSk = customers[values.customer].sk;

      setLoadingFilter(true);

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}criticas/get_criticas_history`,
        {
          sk_grupo_cliente: [customerSk],
          base_date: baseDate,
        }
      );

      (await data.length) !== 0 ? formatDataLogs(data) : setLogs(data);

      setLoadingFilter(false);
    } catch (error) {
      console.log(error);
    }
  }

  function newBaseMonths() {
    const baseMonths = [...months];

    const arrayMonths = baseMonths
      .map((month, index) => {
        if (month.id > Number(dataFechamento.month)) {
          return {
            ...month,
            year: Number(dataFechamento.year),
          };
        }

        return null;
      })
      .filter((month) => month !== null);

    if (extend) {
      const length = months.length;

      const newArray = months
        .map((month, index) => {
          if (index <= 2) {
            return {
              ...month,
              id: months[length - 1].id + (index + 1),
              year: Number(dataFechamento.year) + 1,
            };
          }

          return null;
        })
        .filter((month) => month !== null);

      arrayMonths.push(...newArray);
    }

    return arrayMonths;
  }

  function formatDataLogs(data) {
    const newArrayValues = [];

    const baseMonths = newBaseMonths();

    data.forEach((item) => {
      if (item.critica_type === 'LINEAR_VOLUME') {
        newArrayValues.push({
          user_id: item.user_id,
          user_name: item.user_name,
          description: item.description,
          created_at: item.created_at,
          sk_grupo_cliente: item.sk_grupo_cliente,
          descricao_grupo_cliente: item.descricao_grupo_cliente,
          critica_type: item.critica_type,
          base_date: item.base_date,
          observation: item.observation,
          valuesMonths: groupByItems(item, baseMonths, item.critica_type),
        });

        return;
      }

      if (item.critica_type === 'DETALHADA_VALOR' || 'DETALHADA_VOLUME') {
        const created_at = item.created_at;
        const isIndexCriticaAlreadyExists = newArrayValues.findIndex(
          (findItem) => findItem.created_at === created_at
        );

        if (isIndexCriticaAlreadyExists !== -1) {
          newArrayValues[isIndexCriticaAlreadyExists].values.push({
            sk_produto: item.sk_produto,
            name: item.descricao_produto,
            observation: item.observation,
            valuesMonths: groupByItems(item, baseMonths, item.critica_type),
          });

          return;
        }

        newArrayValues.push({
          user_id: item.user_id,
          user_name: item.user_name,
          description: item.description,
          created_at: item.created_at,
          sk_grupo_cliente: item.sk_grupo_cliente,
          descricao_grupo_cliente: item.descricao_grupo_cliente,
          critica_type: item.critica_type,
          base_date: item.base_date,
          observation: item.observation,
          values: [
            {
              sk_produto: item.sk_produto,
              name: item.descricao_produto,
              observation: item.observation,
              valuesMonths: groupByItems(item, baseMonths, item.critica_type),
            },
          ],
        });

        return;
      }
    });

    setLogs(newArrayValues);
  }

  function groupByItems(item, baseMonths, type) {
    const newArray = [];
    const limitCount = 12;

    if (type === 'LINEAR_VOLUME' || 'DETALHADA_VOLUME') {
      for (let i = 0; i <= limitCount; i++) {
        const key = `m${i.toString().padStart(2, '0')}_pct`;

        if (baseMonths[i] && item[key] !== null && item[key] !== undefined) {
          newArray.push({
            pct: item[key],
            abbreviation: `${baseMonths[i].abbreviation} ${baseMonths[i].year}`,
          });
        }
      }
    }

    if (type === 'DETALHADA_VALOR') {
      for (let i = 0; i <= limitCount; i++) {
        const keyProd = `m${i.toString().padStart(2, '0')}_pct_prod`;
        const keyPerUnit = `m${i.toString().padStart(2, '0')}_pct_punit`;
        const keyNegoc = `m${i.toString().padStart(2, '0')}_pct_negoc`;

        if (
          baseMonths[i] &&
          (item[keyProd] || item[keyPerUnit] || item[keyNegoc])
        ) {
          newArray.push({
            pct_prod: item[keyProd],
            pct_punit: item[keyPerUnit],
            pct_negoc: item[keyNegoc],
            abbreviation: `${baseMonths[i].abbreviation} ${baseMonths[i].year}`,
          });
        }
      }
    }

    return newArray;
  }

  const validationSchema = Yup.object().shape({
    customer: Yup.number().required(t('Campo obrigatório', { ns: 'maxion' })),
    date: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])\/[0-9]{4}$/,
        `${t('Insira uma data no formato', { ns: 'maxion' })} MM/YYYY`
      )
      .required(t('Campo obrigatório', { ns: 'maxion' })),
  });

  const formik = useFormik({
    initialValues: {
      customer: '',
      date: '',
    },
    validationSchema,
    onSubmit: handleFilter,
  });

  return (
    <div style={{ width: '100%' }}>
      <Card>
        <CardContent>
          <span>
            <p>{t('Selecione um cliente', { ns: 'maxion' })}</p>

            <div>
              <UnitSelect
                width={290}
                items={customers}
                name="customer"
                fieldItem="descricao_grupo_cliente"
                label={t('Cliente', { ns: 'maxion' })}
                value={formik.values.customer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.customer && Boolean(formik.errors.customer)
                }
              />

              {formik.errors.customer && formik.touched.customer && (
                <TextError>{formik.errors.customer}</TextError>
              )}
            </div>
          </span>

          <span>
            <p>{t('Informe a data base', { ns: 'maxion' })}</p>

            <div style={{ position: 'relative' }}>
              <InputDate
                label={t('Data base', { ns: 'maxion' })}
                size="small"
                name="date"
                placeholder="Ex: 05/2023"
                value={formik.values.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.date && Boolean(formik.errors.date)}
              />

              {formik.errors.date && formik.touched.date && (
                <TextError>{formik.errors.date}</TextError>
              )}
            </div>
          </span>

          <ButtonFilter
            variant="contained"
            type="submit"
            disabled={
              customers.length === 0 ||
              loadingFilter ||
              formik.values.date === '' ||
              formik.values.customer === ''
            }
            onClick={() => formik.handleSubmit()}
          >
            {loadingFilter ? (
              <CircularProgress size={15} />
            ) : (
              t('Filtrar', { ns: 'maxion' })
            )}
          </ButtonFilter>

          <ClosedDate>
            {dataFechamento &&
              `${t('Data de fechamento', { ns: 'maxion' })}: ${
                dataFechamento.month
              }/${dataFechamento.year}`}
          </ClosedDate>
        </CardContent>
      </Card>

      {logs && (
        <Result>
          {logs.length > 0 ? (
            <HistoryTable data={logs} t={t} />
          ) : (
            `${t('Nenhum item encontrado', { ns: 'maxion' })}.`
          )}
        </Result>
      )}
    </div>
  );
}

function HistoryCriticas({ t }) {
  return (
    <div style={{ width: '100%' }}>
      <Infobar t={t}>
        <div>
          <div>
            <h2>{t('Previsão de faturamento', { ns: 'maxion' })}</h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

              <p>{t('Números Maxion', { ns: 'maxion' })} </p>

              <p>{t('Previsão de faturamento', { ns: 'maxion' })}</p>

              <span>{t('Histórico de críticas', { ns: 'maxion' })}</span>
            </Breadcrumbs>
          </div>
        </div>

        <Divider className="divider" />
      </Infobar>

      <ComponentHistoryCriticas t={t} />
    </div>
  );
}

export default withTranslation('maxion')(HistoryCriticas);
