import React from 'react';
import styled from '@emotion/styled';

import { CircularProgress } from '@mui/material';

const Box = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`;

export default function Loading() {
  return (
    <Box>
      <CircularProgress />
    </Box>
  );
}
