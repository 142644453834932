import React, { useContext, useEffect, useState } from 'react';
import { ReportContext } from './ReportProvider';
import styled from '@emotion/styled';
import { Button, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import _ from 'lodash';
import { maskitoParseNumber } from '@maskito/kit';
import Collapse from '@mui/material/Collapse';
import TextFieldMaskNumberV2 from '../../../../../../components/TextFieldMaskNumberV2';
import { formatNumberLocale } from '../../../../../../utils/formats';
import exportToExcelMonthly from './exportToExcelMonthly';

export default function TableReportMonth({ t }) {
  const { dataRowsMonth, colsReportMonth, isLoadingReportMonth, rowsCustomer } =
    useContext(ReportContext);

  const TableDiv = styled.div`
    .table-container {
      margin: 20px;
      height: 100vh !important;
      overflow-y: auto;
      width: 100%;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
    }

    table tr:last-child .border-bottom {
      border-bottom: 2px solid #0783c2;
    }

    th {
      color: #0783c2;
      background-color: #c2ddf0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    th,
    td {
      padding: 5px;
      text-align: center;
      font-size: 14px;
    }

    thead th {
      background-color: #c2ddf0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .row-title th {
      background-color: #f4f7fc;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 20px !important;
    }

    .row-collapsible {
      background-color: #0783c2;
    }

    tbody tr:nth-child(even) {
      background-color: #f4f8fc;
    }

    .border-left {
      border-left: 2px solid #0783c2;
    }

    .border-right {
      border-right: 2px solid #0783c2;
    }

    .border-top {
      border-top: 2px solid #0783c2;
    }

    tr {
      line-height: 15px;
    }
  `;

  const BoxContent = styled.div`
    min-width: 100%;
    background-color: #fff;
    padding: 25px 30px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
      rgb(0 0 0 / 5%) 0px 1px 3px -1px;

    h2 {
      font-size: 28px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.blue_200};
    }

    .info-plano {
      margin: 40px 0;
      padding: 0 20px;

      .flex {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        width: 80%;
        justify-content: space-between;
      }

      .column {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px;

      p {
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.blue_200};
        flex: 0 0 auto;
      }

      b {
        color: ${({ theme }) => theme.colors.gray_300};
      }
    }

    .observations {
      flex: 1 1 auto;
      white-space: normal;
      word-wrap: break-word;
      overflow: visible;
      min-width: 0;
    }

    .workDays-and-vehicles {
      position: relative;
      margin: 40px 0;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.blue_200};
        flex: 0 0 auto;
        margin-bottom: 10px;
      }
    }
  `;

  const CellData = styled(TableCell)`
    width: 150px;
    text-align: center;
  `;

  const CellIconIntern = styled(TableCell)`
    width: 64px;
  `;

  const BoxContentMain = styled(BoxContent)`
    min-height: calc(100vh - 650px);
    padding: 3px;
    min-width: 100%;
    overflow-x: auto;
  `;

  const CellIconTotals = styled(TableCell)`
    width: 64px;
    background-color: #c2ddf0;
  `;

  const CellIconCollapsible = styled(TableCell)`
    width: 50px;
    background-color: #c2ddf0;
  `;

  const CellCustomerCollapsible = styled(TableCell)`
    width: 350px;
    background-color: #c2ddf0;
    color: #0783c2;
  `;

  const CellDataCollapsible = styled(TableCell)`
    width: 150px;
    background-color: #c2ddf0;
    text-align: center;
  `;

  const CellCustomer = styled(TableCell)`
    width: 350px;
  `;

  function Rows(props) {
    const { row, idx, t } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [open, setOpen] = React.useState(row[1].open);

    const { updateTotalValuesMonth } = useContext(ReportContext);

    const [variationDetails, setVariationDetails] = useState({
      value_total: [],
      volume_total: [],
      work_days: [],
      volume_per_day: [],
      value_total_per_volume: [],
      value_m_per_day: [],
    });

    useEffect(() => {
      if (row[1]) {
        setVariationDetails({
          value_total: row[1] ? row[1].value_total : 0,
          volume_total: row[1] ? row[1].volume_total : 0,
          work_days: row[1] ? row[1].work_days : 0,
          volume_per_day: row[1] ? row[1].volume_per_day : 0,
          value_total_per_volume: row[1] ? row[1].value_total_per_volume : 0,
          value_m_per_day: row[1] ? row[1].value_m_per_day : 0,
        });
      }
    }, [row]);

    const calcTotals = (value, type, idxCol) => {
      const copyDataRows = _.cloneDeep(dataRowsMonth);

      let cloneRowDetail = _.cloneDeep(variationDetails);
      const valueInput = formatFloat(value);

      cloneRowDetail.value_total[idxCol] = valueInput || 0;

      let total = 0;
      for (let i = 0; i < cloneRowDetail.value_total.length - 1; i++) {
        total += cloneRowDetail.value_total[i];
      }

      cloneRowDetail.value_total[cloneRowDetail.value_total.length - 1] = total;

      let value_total_per_volume_list = [
        ...cloneRowDetail.value_total_per_volume,
      ];
      value_total_per_volume_list[idxCol] =
        cloneRowDetail.volume_total[idxCol] !== 0
          ? valueInput / cloneRowDetail.volume_total[idxCol]
          : 0;

      let value_m_per_day_wd_list = [...cloneRowDetail.value_m_per_day];
      value_m_per_day_wd_list[idxCol] =
        cloneRowDetail.work_days[idxCol] !== 0
          ? valueInput / 1000000 / cloneRowDetail.work_days[idxCol]
          : 0;

      cloneRowDetail = {
        ...cloneRowDetail,
        value_total_per_volume: value_total_per_volume_list,
        value_m_per_day: value_m_per_day_wd_list,
      };

      copyDataRows[row[0]] = {
        ...copyDataRows[row[0]],
        ...cloneRowDetail,
        open: row[1].open || true,
      };

      for (let i = 0; i < copyDataRows['TOTAL'].value_total.length; i++) {
        let totalValue = 0;
        Object.entries(copyDataRows).map((row) => {
          if (!['TOTAL'].includes(row[0])) {
            const itemTotal = row[1].value_total[i];
            totalValue += itemTotal;
          }
        });
        copyDataRows['TOTAL'].value_total[i] = totalValue;
      }

      setVariationDetails(cloneRowDetail);

      if (type === 'input') {
        return cloneRowDetail;
      } else if (type === 'blur') {
        return copyDataRows;
      }
    };

    const calcTotalsDetails = (value, rowDetail, type, idxCol) => {
      const copyDataRows = _.cloneDeep(dataRowsMonth);

      let cloneRowDetail = _.cloneDeep(variationDetails);
      const valueInput = formatFloat(value);

      cloneRowDetail[rowDetail.key][idxCol] = valueInput || 0;

      let total = 0;
      for (let i = 0; i < cloneRowDetail[rowDetail.key].length - 1; i++) {
        total += cloneRowDetail[rowDetail.key][i];
      }

      cloneRowDetail[rowDetail.key][cloneRowDetail[rowDetail.key].length - 1] =
        total;

      switch (rowDetail.key) {
        case 'volume_total':
          let value_total_per_volume_list = [
            ...cloneRowDetail.value_total_per_volume,
          ];
          value_total_per_volume_list[idxCol] =
            valueInput !== 0
              ? cloneRowDetail.value_total[idxCol] / valueInput
              : 0;

          let volume_per_day_list = [...cloneRowDetail.volume_per_day];
          volume_per_day_list[idxCol] =
            formatFloat(cloneRowDetail.work_days[idxCol]) !== 0
              ? valueInput / cloneRowDetail.work_days[idxCol]
              : 0;

          cloneRowDetail = {
            ...cloneRowDetail,
            value_total_per_volume: value_total_per_volume_list,
            volume_per_day: volume_per_day_list,
          };
          break;
        case 'work_days':
          let volume_per_day_wd_list = [...cloneRowDetail.volume_per_day];
          volume_per_day_wd_list[idxCol] =
            formatFloat(valueInput) !== 0
              ? cloneRowDetail.volume_total[idxCol] / valueInput
              : 0;

          let value_m_per_day_wd_list = [...cloneRowDetail.value_m_per_day];
          value_m_per_day_wd_list[idxCol] =
            valueInput !== 0
              ? cloneRowDetail.value_total[idxCol] / 1000000 / valueInput
              : 0;

          cloneRowDetail = {
            ...cloneRowDetail,
            volume_per_day: volume_per_day_wd_list,
            value_m_per_day: value_m_per_day_wd_list,
          };
          break;
      }

      setVariationDetails(cloneRowDetail);

      copyDataRows[row[0]] = {
        ...copyDataRows[row[0]],
        ...cloneRowDetail,
        open: row[1].open || true,
      };

      if (type === 'input') {
        return cloneRowDetail;
      } else if (type === 'blur') {
        return copyDataRows;
      }
    };

    function formatFloat(value) {
      return value ? maskitoParseNumber(value.toString(), ',') : 0;
    }

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          {['TOTAL', 'OUTROS'].includes(row[0]) ? (
            <CellIconTotals />
          ) : (
            <CellIconCollapsible>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </CellIconCollapsible>
          )}
          {/* Primeira linha referente ao cliente */}
          <CellCustomerCollapsible>
            {t(row[0].trim(), { ns: 'maxion' })}
          </CellCustomerCollapsible>

          {!['TOTAL', 'OUTROS'].includes(row[0]) &&
            row[1] &&
            row[1].value_total &&
            row[1].value_total.map((col, idxCol) => {
              return (
                <CellDataCollapsible key={`${row.sk_grupo_cliente}-${idxCol}`}>
                  <TextFieldMaskNumberV2
                    mask={{
                      precision: 2,
                      decimalSeparator: ',',
                      thousandSeparator: '.',
                    }}
                    style={{
                      backgroundColor: 'white',
                    }}
                    disabled={['TOTAL', 'OUTROS'].includes(row[0])}
                    value={
                      variationDetails && isEditing
                        ? variationDetails.value_total[idxCol]
                        : row && row[1] && row[1].value_total[idxCol]
                    }
                    onBlur={(e) => {
                      const copyDataRows = calcTotals(
                        e.target.value,
                        'blur',
                        idxCol
                      );
                      updateTotalValuesMonth(copyDataRows);

                      if (isEditing) {
                        setIsEditing(false);
                      }
                    }}
                    onInput={(e) => {
                      calcTotals(e, 'input', idxCol);
                      if (!isEditing) {
                        setIsEditing(true);
                      }
                    }}
                  />
                </CellDataCollapsible>
              );
            })}
          {['TOTAL', 'OUTROS'].includes(row[0]) &&
            row[1] &&
            row[1].value_total &&
            row[1].value_total.map((col, idxCol) => {
              return (
                <CellDataCollapsible key={`${row.sk_grupo_cliente}-${idxCol}`}>
                  <p style={{ lineHeight: 3 }}>{formatNumberLocale(col, 2)}</p>
                </CellDataCollapsible>
              );
            })}
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={15}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table>
                <TableBody>
                  {rowsCustomer &&
                    rowsCustomer.map((rowDetail, idxRowDetail) => {
                      let decimalPlaces = 2;

                      switch (rowDetail.key) {
                        case 'value_m_per_day':
                          decimalPlaces = 4;
                          break;
                        case 'value_total_per_volume':
                          decimalPlaces = 2;
                          break;
                        default:
                          decimalPlaces = 0;
                          break;
                      }

                      return (
                        <TableRow
                          key={`month-${row.sk_grupo_cliente}-${rowDetail.key}-${idxRowDetail}`}
                        >
                          <CellIconIntern />
                          <CellCustomer>
                            {t(rowDetail.label.trim(), { ns: 'maxion' })}
                          </CellCustomer>
                          {row[1][rowDetail.key] &&
                            row[1][rowDetail.key].length > 0 &&
                            row[1][rowDetail.key].map((col, idxCol) => {
                              return (
                                <CellData
                                  key={`${row.sk_grupo_cliente}-${col}-${idxCol}`}
                                >
                                  <TextFieldMaskNumberV2
                                    mask={{
                                      precision: decimalPlaces,
                                      decimalSeparator: ',',
                                      thousandSeparator: '.',
                                    }}
                                    style={{
                                      backgroundColor: 'white',
                                    }}
                                    value={
                                      variationDetails[rowDetail.key][idxCol]
                                    }
                                    disabled={
                                      [
                                        'volume_per_day',
                                        'value_total_per_volume',
                                        'value_m_per_day',
                                      ].includes(rowDetail.key) ||
                                      row[1][rowDetail.key].length - 1 ===
                                        idxCol
                                    }
                                    onBlur={(e) => {
                                      const copyDataRows = calcTotalsDetails(
                                        e.target.value,
                                        rowDetail,
                                        'blur',
                                        idxCol
                                      );
                                      updateTotalValuesMonth(copyDataRows);

                                      if (isEditing) {
                                        setIsEditing(false);
                                      }
                                    }}
                                    onInput={(e) => {
                                      calcTotalsDetails(
                                        e.target.value,
                                        rowDetail,
                                        'input',
                                        idxCol
                                      );

                                      if (!isEditing) {
                                        setIsEditing(true);
                                      }
                                    }}
                                  />
                                </CellData>
                              );
                            })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const handleExport = () => {
    exportToExcelMonthly(dataRowsMonth, colsReportMonth, rowsCustomer, t);
  };

  return (
    <>
      <BoxContentMain>
        {isLoadingReportMonth && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 60,
            }}
          >
            <CircularProgress size={50} />
          </div>
        )}
        {!isLoadingReportMonth && (
          <TableDiv>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                padding: 20,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleExport}
              >
                {t('Exportar relatório mensal para Excel', { ns: 'maxion' })}
              </Button>
            </div>
            <Table
              aria-label="collapsible table"
              stickyHeader
              sx={{
                width: 'auto',
              }}
            >
              <TableHead>
                <TableRow className={'row-title'}>
                  <CellIconIntern />
                  <CellCustomer>{t('CLIENTES', { ns: 'maxion' })}</CellCustomer>
                  {colsReportMonth &&
                    colsReportMonth.length > 0 &&
                    colsReportMonth.map((column, idxCol) => (
                      <CellData
                        component="th"
                        key={`${column}-${idxCol}`}
                        // className={`${idxCol === 0 ? 'border-left' : ''} ${
                        //     idxCol === colsReportMonth.length - 1
                        //         ? 'border-right'
                        //         : ''
                        // } border-top`}
                      >
                        {t(column, { ns: 'maxion' })}
                      </CellData>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataRowsMonth &&
                  Object.entries(dataRowsMonth).length > 0 &&
                  Object.entries(dataRowsMonth).map((row, idxRow) => (
                    <Rows key={`row-tb-${idxRow}`} row={row} t={t} />
                  ))}
              </TableBody>
            </Table>
            {/*</TableContainer>*/}
          </TableDiv>
        )}
      </BoxContentMain>
    </>
  );
}
