import React, { useState } from 'react';
import { List, Edit } from '@mui/icons-material';
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDeleteProduct from './ModalDeleteProduct';

import { checkPermission } from '../../../../../../../utils/permissions';
import useAuth from '../../../../../../../hooks/useAuth';

export default function HamburguerProduct({
  fetchSemaphore,
  fetchTableItems,
  t,
  row,
  setFormikInitialValues,
  isEditingProduct,
  setRowId,
  setIsEditingProduct,
}) {
  const { user } = useAuth();
  const userHasPermissionToManage = user && checkPermission(user, [], [9, 10]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalDeleteProduct, setOpenModalDeleteProduct] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleEdit() {
    setIsEditingProduct(true);

    setRowId(row.id);

    handleClose();

    const newValues = {
      name: row.name && row.name,
      cliente: row.sk_cliente && {
        sk: row.sk_cliente,
        codigo_e_nome_cliente: row.codigo_cliente + ' - ' + row.nome_cliente,
      },
      organizacao: row.sk_organizacao && {
        sk: row.sk_organizacao,
        descricao_organizacao:
          row.codigo_organizacao + ' - ' + row.descricao_organizacao,
      },
      segmento: row.sk_segmento && {
        sk: row.sk_segmento,
        descricao_segmento:
          row.codigo_segmento + ' - ' + row.descricao_segmento,
      },
      aplicacao: row.sk_aplicacao && {
        sk: row.sk_aplicacao,
        descricao_aplicacao:
          row.codigo_aplicacao + ' - ' + row.descricao_aplicacao,
      },
      hierarquia: row.sk_hierarquia_produto && {
        sk: row.sk_hierarquia_produto,
        descricao_hierarquia_produto:
          row.codigo_hierarquia_produto +
          ' - ' +
          row.descricao_hierarquia_produto,
      },
      codigo_produto_cliente:
        row.codigo_produto_cliente && row.codigo_produto_cliente,
      sap_id: row.sap_id && row.sap_id,
      custo: row.unitary_value
        ? String(row.unitary_value).replace(/\./g, ',')
        : row.unitary_value,
    };

    setRowId(row.id);

    setFormikInitialValues(newValues);
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <List />
      </IconButton>

      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {user && (
          <div>
            <MenuItem
              loading={loading}
              disabled={!userHasPermissionToManage}
              onClick={handleEdit}
            >
              <ListItemIcon>
                {!loading ? (
                  <Edit fontSize="small" color="inherit" />
                ) : (
                  <CircularProgress size={15} color="inherit" />
                )}
              </ListItemIcon>
              <ListItemText>{t('Editar', { ns: 'maxion' })}</ListItemText>
            </MenuItem>
          </div>
        )}
        {user && (
          <div>
            <MenuItem
              loading={loading}
              disabled={!userHasPermissionToManage}
              onClick={() => setOpenModalDeleteProduct(true)}
            >
              <ListItemIcon>
                {!loading ? (
                  <DeleteIcon fontSize="small" color="inherit" />
                ) : (
                  <CircularProgress size={15} color="inherit" />
                )}
              </ListItemIcon>
              <ListItemText>{t('Excluir', { ns: 'maxion' })}</ListItemText>
            </MenuItem>
          </div>
        )}
      </Menu>
      {openModalDeleteProduct && (
        <ModalDeleteProduct
          fetchSemaphore={fetchSemaphore}
          fetchTableItems={fetchTableItems}
          t={t}
          row={row}
          open={openModalDeleteProduct}
          setOpen={setOpenModalDeleteProduct}
        />
      )}
    </div>
  );
}
