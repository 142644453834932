import React, { createContext, useEffect, useState } from 'react';
import axios from '../../../../../../utils/axios';
import { useFormik } from 'formik';

export const ReportContext = createContext({});

export function ReportProvider({ children, t }) {
  const initValues = {
    versions: [],
    bases: [],
    customers: [],
    report_date: '',
  };

  const [initialValues, setInitialValues] = useState(initValues);
  const [dataParameters, setDataParameters] = useState([]);
  const [isLoadingParameters, setIsLoadingParameters] = useState(false);
  const [isLoadingYears, setIsLoadingYears] = useState(false);
  const [reportData, setReportData] = useState({
    columns: [],
    rows: [],
  });
  const [reportDataMonth, setReportDataMonth] = useState({
    columns: [],
    data: {},
  });
  const [isLoadingReport, setIsLoadingReport] = useState(true);
  const [isLoadingReportMonth, setIsLoadingReportMonth] = useState(true);
  const [columns, setColumns] = useState([]);
  const [columnsBase, setColumnsBase] = useState([]);
  const [columnsVersion, setColumnsVersion] = useState([]);
  const [columnsCalc, setColumnsCalc] = useState([]);
  const [columnsSimulate, setColumnsSimulate] = useState([]);
  const rowsCustomer = [
    // {
    //   key: 'value_total',
    //   label: 'Receita',
    // },
    {
      key: 'volume_total',
      label: 'Veículos',
    },
    {
      key: 'work_days',
      label: 'Dias úteis',
    },
    {
      key: 'volume_per_day',
      label: 'Veículos/dia',
    },
    {
      key: 'value_total_per_volume',
      label: 'R$/veículos',
    },
    {
      key: 'value_m_per_day',
      label: 'R$M/dia',
    },
  ];
  const [dataRows, setDataRows] = useState([]);
  const [dataRowsMonth, setDataRowsMonth] = useState([]);
  const [colsReportMonth, setColsReportMonth] = useState([]);
  const [versionYear, setVersionYear] = useState(null);
  const [versionYears, setVersionYears] = useState([]);
  const [listTotals, setListTotals] = useState([]);

  useEffect(() => {
    console.log('listTotals', listTotals);
  }, [listTotals]);

  const getGeneralReportParameters = () => {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/report-general-parameters?version_year=${versionYear}`
      )
      .then((data) => {
        setIsLoadingParameters(false);
        setDataParameters(data.data);

        getGeneralReportData([], [], [], versionYear);
        getGeneralReportDataMonth([], null, versionYear);
      })
      .catch((error) => {
        console.error('Error report parameters', error);
      });
  };

  const getGeneralReportData = (versions, customers, bases, versions_year) => {
    setIsLoadingReport(true);

    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/report-general`,
        {
          versions: versions,
          customers: customers,
          base_dates: bases,
          report_date: versions_year,
        }
      )
      .then((data) => {
        setReportData(data.data);
        setIsLoadingReport(false);

        let columnsTb = [];

        const colVarVersion =
          data.data.columns.versions.length > 1
            ? `${t('Variação', { ns: 'maxion' })} ${
                data.data.columns.versions[
                  data.data.columns.versions.length - 1
                ]
              } x ${
                data.data.columns.versions[
                  data.data.columns.versions.length - 2
                ]
              }`
            : t('Variação', { ns: 'maxion' });

        columnsTb.push(
          colVarVersion,
          t('Simulação', { ns: 'maxion' }),
          `${t('Variação (Simulado)', { ns: 'maxion' })} x ${
            data.data.columns.versions[data.data.columns.versions.length - 1]
          }`
        );

        setColumns([
          '',
          ...data.data.columns.base_dates,
          ...data.data.columns.versions,
          ...columnsTb,
        ]);

        setColumnsCalc([
          ...data.data.columns.base_dates,
          ...data.data.columns.versions,
        ]);

        setColumnsSimulate(columnsTb);

        // gera totais por colunas

        setDataRows(data.data.data);
        setColumnsBase(data.data.columns.base_dates);
        setColumnsVersion(data.data.columns.versions);

        setListTotals(data.data.data['TOTAL']);

        // recupera versoes vindos do backend
      })
      .catch((error) => {
        console.error('Error report', error);
      });
  };

  const getGeneralReportDataMonth = (customers, version_id, version_year) => {
    setIsLoadingReport(true);

    axios
      .post(
        `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/report-general-monthly`,
        {
          customers: customers,
          version_id: version_id,
          version_year: version_year,
        }
      )
      .then((data) => {
        setReportDataMonth(data.data);
        setIsLoadingReportMonth(false);

        setColsReportMonth(data.data.columns);

        setDataRowsMonth(data.data.data);

        // recupera versoes vindos do backend
      })
      .catch((error) => {
        console.error('Error report', error);
      });
  };

  const getVersionYears = () => {
    setIsLoadingYears(true);

    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}po_versions/years`)
      .then((data) => {
        setVersionYear((data.data[0] - 1).toString());
        const versionYears = data.data.map((item) => {
          return { key: item.toString(), label: item.toString() };
        });
        setVersionYears(versionYears);

        formikFilter.setFieldValue('report_date', versionYears[0]);
      })
      .catch((error) => {
        console.error('Error report parameters', error);
      })
      .finally(() => {
        setIsLoadingYears(false);
      });
  };

  useEffect(() => {
    getVersionYears();
  }, []);

  useEffect(() => {
    if (versionYear) {
      getGeneralReportParameters();
    }
  }, [versionYear]);

  const formikFilter = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const versionIds = values.versions.map((item) => {
        return item.key;
      });

      // ordena os ids das versoes
      versionIds.sort((a, b) => a - b);

      const baseIds = values.bases.map((item) => {
        return item.key;
      });

      const customerIds = values.customers.map((item) => {
        return item.key;
      });

      getGeneralReportData(versionIds, customerIds, baseIds, versionYear);
      getGeneralReportDataMonth(
        customerIds,
        versionIds[versionIds.length - 1],
        versionYear
      );

      console.log('values', values, versionIds, baseIds, customerIds);
    },
  });

  const resetFilters = () => {
    getGeneralReportData([], [], [], versionYear);
    getGeneralReportDataMonth([], null, versionYear);
  };

  useEffect(() => {
    console.log('dataRow aaa a a as', dataRows);
  }, [dataRows]);

  const updateTotalValues = (values) => {
    console.log('dataRow values provider', values);
    setListTotals(values['TOTAL']);
    setDataRows(values);
  };

  const updateTotalValuesMonth = (values) => {
    console.log('dataRow values provider month', values);
    setDataRowsMonth(values);
  };

  return (
    <ReportContext.Provider
      value={{
        getGeneralReportParameters,
        getGeneralReportData,
        initialValues,
        isLoadingParameters,
        isLoadingReport,
        dataParameters,
        reportData,
        formikFilter,
        resetFilters,
        columns,
        dataRows,
        rowsCustomer,
        columnsBase,
        columnsVersion,
        columnsCalc,
        columnsSimulate,
        versionYears,
        isLoadingYears,
        dataRowsMonth,
        colsReportMonth,
        getGeneralReportDataMonth,
        isLoadingReportMonth,
        setDataRows,
        updateTotalValues,
        updateTotalValuesMonth,
        listTotals,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}
