import ExcelJS from 'exceljs';
import { months } from '../../../../../Critica/monthsValueArray';
import axios from '../../../../../../../utils/axios';

function arrayPushItemsByBaseDate(version, array, field) {
  const year = version.year;

  for (let i = 0; i < 12; i++) {
    const key = `m${i.toString().padStart(2, '0')}${field}`;
    let aux = '';
    let width = '';
    let style = null;

    switch (field) {
      case '_volume':
        aux = `${year}`;
        width = 20;
        break;
      case '_valor_sem_reajuste':
        aux = `${year}`;
        width = 20;
        style = { numFmt: '"R$" #,##0.00_-' };
        break;
      case '_valor_com_reajuste':
        aux = `${year}`;
        width = 20;
        style = { numFmt: '"R$" #,##0.00_-' };
        break;
      case '_pct_reajuste':
        aux = `${year}`;
        width = 20;
        style = { numFmt: '0.00%' };
        break;
      default:
        break;
    }

    array.push({
      header: `${months[i].month} ${aux}`,
      key: key,
      width: width,
      style: style,
    });
  }
}

function objectPushItemsByBaseDate(object, arrayItem, field) {
  for (let i = 0; i <= 12; i++) {
    const key = `m${i.toString().padStart(2, '0')}${field}`;

    object[key] =
      arrayItem[key] !== null && arrayItem[key] !== undefined
        ? arrayItem[key]
        : null;
  }
}

function calculatePercent(valorReajustado, valorOriginal) {
  // Verifica se os valores são números válidos e se valorOriginal é diferente de zero
  if (
    typeof valorReajustado !== 'number' ||
    typeof valorOriginal !== 'number' ||
    valorOriginal === 0
  ) {
    return 0;
  }

  // Não há necessidade de multiplicação por 100, pois na formatação do excel já faz automático.
  const diferencaPercentual = (valorReajustado - valorOriginal) / valorOriginal;

  return diferencaPercentual;
}

export async function exportToExcel(version) {
  try {
    const workbook = new ExcelJS.Workbook();

    const worksheetVolume = workbook.addWorksheet('Volume (com reajuste)');
    const worksheetValorSemReajuste = workbook.addWorksheet(
      'Valor (sem reajuste)'
    );
    const worksheetValorComReajuste = workbook.addWorksheet(
      'Valor (com reajuste)'
    );
    const worksheetPercentuaisReajuste = workbook.addWorksheet(
      'Percentuais de reajuste'
    );

    //Colunas do Excel
    const worksheetColumns = [
      {
        header: 'Código do cliente',
        key: 'codigo_cliente',
        width: 20,
      },
      {
        header: 'Cliente',
        key: 'nome_cliente',
        width: 40,
      },
      {
        header: 'Organização vendas',
        key: 'codigo_organizacao',
        width: 20,
      },
      {
        header: 'Grupo cliente',
        key: 'descricao_grupo_cliente',
        width: 40,
      },
      {
        header: 'Hierarquia produtos',
        key: 'codigo_hierarquia_produto',
        width: 20,
      },
      {
        header: 'Descrição da hierarquia',
        key: 'descricao_hierarquia_produto',
        width: 30,
      },
      {
        header: 'Código do segmento',
        key: 'codigo_segmento',
        width: 20,
      },
      {
        header: 'Descrição do segmento',
        key: 'descricao_segmento',
        width: 30,
      },
      {
        header: 'Código do produto',
        key: 'codigo_produto',
        width: 20,
      },
      {
        header: 'Descrição do produto',
        key: 'descricao_produto',
        width: 50,
      },
    ];

    const worksheetColumnsVolume = [...worksheetColumns];
    arrayPushItemsByBaseDate(version, worksheetColumnsVolume, '_volume');

    const worksheetColumnsValorSemReajuste = [...worksheetColumns];
    arrayPushItemsByBaseDate(
      version,
      worksheetColumnsValorSemReajuste,
      '_valor_sem_reajuste'
    );

    const worksheetColumnsValorComReajuste = [...worksheetColumns];
    arrayPushItemsByBaseDate(
      version,
      worksheetColumnsValorComReajuste,
      '_valor_com_reajuste'
    );

    const worksheetColumnsPercentuaisReajuste = [...worksheetColumns];
    arrayPushItemsByBaseDate(
      version,
      worksheetColumnsPercentuaisReajuste,
      '_pct_reajuste'
    );

    worksheetColumnsVolume.push({
      header: 'Total',
      key: 'volume_total',
      width: 20,
    });

    worksheetColumnsValorSemReajuste.push({
      header: 'Total',
      key: 'valor_sem_reajuste_total',
      width: 20,
    });

    worksheetColumnsValorComReajuste.push({
      header: 'Total',
      key: 'valor_com_reajuste_total',
      width: 20,
    });

    //Requisição
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API}po_versions/export/${version.id}`
    );

    //Criando novo array com os valores de "volume"
    const newDataVolume = data.map((item) => {
      const newItem = {
        base_date: item.base_date,
        codigo_segmento: item.codigo_segmento,
        descricao_segmento: item.descricao_segmento,
        codigo_hierarquia_produto: item.codigo_hierarquia_produto,
        descricao_hierarquia_produto: item.descricao_hierarquia_produto,
        codigo_cliente: item.codigo_cliente,
        nome_cliente: item.nome_cliente,
        descricao_grupo_cliente: item.descricao_grupo_cliente,
        codigo_organizacao: item.codigo_organizacao,
        descricao_organizacao: item.descricao_organizacao,
        codigo_produto: item.codigo_produto,
        descricao_produto: item.descricao_produto,
        volume_total: item.volume_total,
      };

      objectPushItemsByBaseDate(newItem, item, '_volume');

      return newItem;
    });

    //Criando novo array com os valores de "valor sem reajuste"
    const newDataValorSemReajuste = data.map((item) => {
      const newItem = {
        base_date: item.base_date,
        codigo_segmento: item.codigo_segmento,
        descricao_segmento: item.descricao_segmento,
        codigo_hierarquia_produto: item.codigo_hierarquia_produto,
        descricao_hierarquia_produto: item.descricao_hierarquia_produto,
        codigo_cliente: item.codigo_cliente,
        nome_cliente: item.nome_cliente,
        descricao_grupo_cliente: item.descricao_grupo_cliente,
        codigo_organizacao: item.codigo_organizacao,
        descricao_organizacao: item.descricao_organizacao,
        codigo_produto: item.codigo_produto,
        descricao_produto: item.descricao_produto,
        valor_sem_reajuste_total: item.valor_sem_reajuste_total,
      };

      objectPushItemsByBaseDate(newItem, item, '_valor_sem_reajuste');

      return newItem;
    });

    //Criando novo array com os valores de "valor com reajuste"
    const newDataValorComReajuste = data.map((item) => {
      const newItem = {
        base_date: item.base_date,
        codigo_segmento: item.codigo_segmento,
        descricao_segmento: item.descricao_segmento,
        codigo_hierarquia_produto: item.codigo_hierarquia_produto,
        descricao_hierarquia_produto: item.descricao_hierarquia_produto,
        codigo_cliente: item.codigo_cliente,
        nome_cliente: item.nome_cliente,
        descricao_grupo_cliente: item.descricao_grupo_cliente,
        codigo_organizacao: item.codigo_organizacao,
        descricao_organizacao: item.descricao_organizacao,
        codigo_produto: item.codigo_produto,
        descricao_produto: item.descricao_produto,
        valor_com_reajuste_total: item.valor_com_reajuste_total,
      };

      objectPushItemsByBaseDate(newItem, item, '_valor_com_reajuste');

      return newItem;
    });

    // Criando novo array copm os valores de "percentuais de reajuste"
    const newDataPercentuaisReajuste = data.map((item) => {
      const newItem = {
        base_date: item.base_date,
        codigo_hierarquia_produto: item.codigo_hierarquia_produto,
        descricao_hierarquia_produto: item.descricao_hierarquia_produto,
        codigo_cliente: item.codigo_cliente,
        nome_cliente: item.nome_cliente,
        descricao_grupo_cliente: item.descricao_grupo_cliente,
        codigo_organizacao: item.codigo_organizacao,
        descricao_organizacao: item.descricao_organizacao,
        codigo_produto: item.codigo_produto,
        descricao_produto: item.descricao_produto,
      };

      for (let i = 0; i < 12; i++) {
        const key = `m${i.toString().padStart(2, '0')}`;

        const percent = calculatePercent(
          item[`${key}_valor_com_reajuste`],
          item[`${key}_valor_sem_reajuste`]
        );

        newItem[`${key}_pct_reajuste`] = percent;
      }

      return newItem;
    });

    // Definir cabeçalhos (volume, valor)
    worksheetVolume.columns = worksheetColumnsVolume;
    worksheetValorSemReajuste.columns = worksheetColumnsValorSemReajuste;
    worksheetValorComReajuste.columns = worksheetColumnsValorComReajuste;
    worksheetPercentuaisReajuste.columns = worksheetColumnsPercentuaisReajuste;

    // Preencher linhas - volume
    newDataVolume.forEach((item) => {
      worksheetVolume.addRow(item);
    });

    // Preencher linhas - valores sem reajuste
    newDataValorSemReajuste.forEach((item) => {
      worksheetValorSemReajuste.addRow(item);
    });

    // Preencher linhas - valores com reajuste
    newDataValorComReajuste.forEach((item) => {
      worksheetValorComReajuste.addRow(item);
    });

    // Preencher linhas - percentuais reajuste
    newDataPercentuaisReajuste.forEach((item) => {
      worksheetPercentuaisReajuste.addRow(item);
    });

    // Defina a célula específica em cada coluna do cabeçalho para colorir
    const colorHeader = (worksheet) => {
      for (
        let colIndex = 1;
        colIndex <= worksheetColumnsVolume.length;
        colIndex++
      ) {
        const cell = worksheet.getCell(1, colIndex); // Linha 1 (cabeçalho), coluna atual
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF4C8BC9' },
        }; // Cor de preenchimento
        cell.font = { bold: true, color: { argb: 'FFFFFF' } }; // Texto em negrito com fonte branca
      }
    };

    // Aplicar formatação às primeiras linhas de todas as planilhas
    colorHeader(worksheetVolume);
    colorHeader(worksheetValorSemReajuste);
    colorHeader(worksheetValorComReajuste);
    colorHeader(worksheetPercentuaisReajuste);

    const date = new Date().toISOString().slice(0, 10);

    // Gerar o arquivo Excel
    return workbook.xlsx.writeBuffer().then((buffer) => {
      const excelData = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const excelFile = URL.createObjectURL(excelData);
      const link = document.createElement('a');
      link.href = excelFile;
      link.download = `AOP-Controladoria-${version.name}-${date}.xlsx`;
      link.click();
    });
  } catch (error) {
    console.log(error);
  }
}
