import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { FileContext } from '../../../../../contexts/FileContext';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import axios from '../../../../../utils/axios';

import Loading from '../../../../../components/Loading';
import CardPermissions from '../../../../../components/CardPermissions';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextError = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

function ModalNewFold({ open, setOpen, t, getFolders }) {
  const { file, setFile } = useContext(FileContext);

  const initialValuesBlank = {
    parent_id: file.id,
    file_type_id: 1,
    url: '',
    name: '',
    description: '',
    permissoes: [],
  };

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesBlank);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${t('Campo obrigatório', { ns: 'maxion' })}!`),
    description:
      file.id !== '1'
        ? Yup.string().required(`${t('Campo obrigatório', { ns: 'maxion' })}!`)
        : '',
  });

  async function handleSubmit(values, { resetForm }) {
    setLoading(true);

    await axios
      .post(`${process.env.REACT_APP_PUBLIC_API}files`, values)
      .then((response) => {
        getFolders();
        setOpen(false);
        setInitialValues(initialValuesBlank);
        resetForm();
        setFile(null);
      })
      .catch(({ response }) => {
        console.log(response);
        if (response.data.detail === 'File already exist') {
          toast.error(`${t('Nome já cadastrado', { ns: 'maxion' })}!`);
          return;
        }
        setOpen(false);
        resetForm();
      });

    setLoading(false);
  }

  function handleClose(resetForm) {
    setFile(null);
    setOpen(false);
    resetForm();
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        handleReset,
        isSubmitting,
      }) => (
        <Dialog
          open={open}
          onClose={() => handleClose(handleReset)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            {file.id === '1' ? (
              t('Nova categoria', { ns: 'maxion' })
            ) : (
              <>
                {t('Novo item na categoria', { ns: 'maxion' })}
                {t(` ${file.name}`, { ns: 'maxion' })}
              </>
            )}
          </DialogTitle>
          <DialogContent>
            <Form>
              <TextField
                margin="dense"
                id="name"
                label={t('Nome', { ns: 'maxion' })}
                type="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                fullWidth
                variant="outlined"
                error={Boolean(touched.name && errors.name)}
              />

              {errors.name && touched.name && errors.name && (
                <TextError>{errors.name}</TextError>
              )}

              {file.id !== '1' && (
                <TextField
                  style={{ marginTop: 20 }}
                  margin="dense"
                  id="description"
                  label={t('Descrição', { ns: 'maxion' })}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  fullWidth
                  variant="outlined"
                  error={Boolean(touched.description && errors.description)}
                />
              )}

              {file.id !== '1' &&
                errors.description &&
                touched.description &&
                errors.description && (
                  <TextError>{errors.description}</TextError>
                )}
            </Form>

            <CardPermissions setFieldValue={setFieldValue} values={values} />
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={() => handleClose(handleReset)}>
              {t('Cancelar', { ns: 'maxion' })}
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              {loading
                ? t('Enviando', { ns: 'maxion' })
                : t('Enviar', { ns: 'maxion' })}
            </Button>
          </DialogActions>

          {loading && <Loading />}
        </Dialog>
      )}
    </Formik>
  );
}

export default withTranslation('maxion')(ModalNewFold);
