import axios, { CancelToken as CancelTokenAxios } from 'axios';
import { isValidToken } from './jwt';

export const CancelToken = CancelTokenAxios;
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  // console.log("axios url", config.url)

  let urlEscape = [
    `${process.env.REACT_APP_PUBLIC_API}auth/login`,
    `${process.env.REACT_APP_PUBLIC_API}auth/current-user`,
    `${process.env.REACT_APP_PUBLIC_API}auth/refresh-token`,
  ];

  return new Promise(async (resolve, reject) => {
    if (!urlEscape.includes(config.url)) {
      let accessToken = localStorage.getItem('accessToken');
      // console.log("axios valid", isValidToken(accessToken))
      // console.log("axios storage", localStorage.getItem("accessToken"))
      // console.log("axios config", config)
      // console.log("axios typeof token", typeof accessToken)
      // console.log("axios CONDICAO", !isValidToken && typeof accessToken != 'undefined', !isValidToken(accessToken), typeof accessToken)

      if (!isValidToken(accessToken) && typeof accessToken != 'undefined') {
        let newAccessToken = await refreshToken(accessToken);
        window.localStorage.setItem('accessToken', newAccessToken);

        config.headers.common.Authorization = `Bearer ${newAccessToken}`;
      } else {
        config.headers.common.Authorization = `Bearer ${accessToken}`;
      }
    }
    resolve(config);
  });
});

const refreshToken = async (accessToken) => {
  const response = await axios.post(
    `${process.env.REACT_APP_PUBLIC_API}auth/refresh-token`,
    {},
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  console.log('axios response refresh', response);

  const { usertoken } = response.data;

  return usertoken;
};

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log("error axios", error)
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
//   }
// );

export default axiosInstance;
