import React, { useState, useContext } from 'react';
import { List } from '@mui/icons-material';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { checkPermission } from '../../../../../../../utils/permissions';
import { POContext } from '../../../../../../../contexts/POContext';
import useAuth from '../../../../../../../hooks/useAuth';
import ModalDelete from './ModalDelete';
import ModalNewCalendarAndSegment from './ModalNewCalendarAndSegment';
import ModalNewProduct from '../../NovosProdutos';
import ModalVoltarEtapa from '../../VoltarEtapa';

export default function MenuHamburguer({
  t,
  row,
  fetchTableItems,
  version,
  isPlanActive,
}) {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalNewCalendarAndSegment, setOpenModalNewCalendarAndSegment] =
    useState(false);
  const [openModalNewProduct, setOpenModalNewProduct] = useState(false);
  const [openModalVoltarEtapa, setOpenModalVoltarEtapa] = useState(false);
  const [etapa, setEtapa] = useState(undefined);

  const {
    setInvisibleNumber,
    setCustomerId,
    setCustomer,
    setVersionId,
    setMetricaPO,
  } = useContext(POContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { user } = useAuth();

  const items = [
    {
      children: t('Dias úteis / veículos', { ns: 'maxion' }),
      roles: [],
      dependFields: [],
      props: {
        onClick: () => {
          setOpenModalNewCalendarAndSegment(true);
          setAnchorEl(null);
        },
      },
    },
    {
      children: t('Novos produtos', { ns: 'maxion' }),
      roles: [],
      dependFields: [],
      props: {
        onClick: () => {
          setOpenModalNewProduct(true);
          setCustomer(row);
          setAnchorEl(null);
        },
      },
    },
    {
      children: t('Mercado', { ns: 'maxion' }),
      roles: [],
      dependFields: ['calendar', 'segmentation'],
      props: {
        onClick: () => {
          setCustomerId(row.sk_grupo_cliente);
          setVersionId(row.version_id);
          setCustomer(row);
          setInvisibleNumber(2);
        },
      },
    },
    {
      children: t('Preço', { ns: 'maxion' }),
      roles: [],
      dependFields: ['aop'],
      props: {
        onClick: () => {
          setCustomer(row);
          setInvisibleNumber(3);
          setMetricaPO('value');
        },
      },
    },
    {
      children: t('Volume', { ns: 'maxion' }),
      roles: [],
      dependFields: ['aop'],
      props: {
        onClick: () => {
          setCustomer(row);
          setInvisibleNumber(3);
          setMetricaPO('volume');
        },
      },
    },
    {
      children: t('Voltar mercado', { ns: 'maxion' }),
      roles: [9],
      dependFields: ['aop'],
      props: {
        onClick: () => {
          setEtapa('mercado');
          setCustomer(row);
          setOpenModalVoltarEtapa(true);
          setAnchorEl(null);
        },
      },
    },
    {
      children: t('Voltar preço', { ns: 'maxion' }),
      roles: [9],
      dependFields: ['operational_plan_value'],
      props: {
        onClick: () => {
          setEtapa('preço');
          setCustomer(row);
          setOpenModalVoltarEtapa(true);
          setAnchorEl(null);
        },
      },
    },
    {
      children: t('Voltar volume', { ns: 'maxion' }),
      roles: [9],
      dependFields: ['operational_plan_volume'],
      props: {
        onClick: () => {
          setEtapa('volume');
          setCustomer(row);
          setOpenModalVoltarEtapa(true);
          setAnchorEl(null);
        },
      },
    },
    {
      children: t('Excluir', { ns: 'maxion' }),
      roles: [9, 10],
      dependFields: [],
      props: {
        style: { color: 'red', fontWeight: '600' },
        disabled: !isPlanActive,
        onClick: () => {
          setOpenModalDelete(true);
          setAnchorEl(null);
        },
      },
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        size="medium"
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <List fontSize="medium" />
      </IconButton>

      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {items.map((item, index) => {
          const hasPermission = user && checkPermission(user, [], item.roles);
          const isFieldsFinished = item.dependFields.every(
            (field) =>
              row[field] === 'finished' ||
              (field === 'operational_plan_value' &&
                row[field] !== 'pending') ||
              (field === 'operational_plan_volume' && row[field] !== 'pending')
          );

          if (item.roles.length > 0 && !hasPermission) {
            return null;
          }

          return (
            <MenuItem
              key={index}
              disabled={
                (item.roles.length > 0 && !hasPermission) ||
                (item.dependFields.length > 0 && !isFieldsFinished)
              }
              {...item.props}
            >
              {item.children}
            </MenuItem>
          );
        })}
      </Menu>

      {openModalDelete && (
        <ModalDelete
          t={t}
          open={openModalDelete}
          setOpen={setOpenModalDelete}
          fetchTableItems={fetchTableItems}
          row={row}
        />
      )}

      {openModalNewCalendarAndSegment && (
        <ModalNewCalendarAndSegment
          t={t}
          open={openModalNewCalendarAndSegment}
          setOpen={setOpenModalNewCalendarAndSegment}
          fetchTableItems={fetchTableItems}
          item={row}
          version={version}
          isPlanActive={isPlanActive}
        />
      )}

      {openModalNewProduct && (
        <ModalNewProduct
          t={t}
          open={openModalNewProduct}
          setOpen={setOpenModalNewProduct}
          fetchSemaphore={fetchTableItems}
          item={row}
          version={version}
          isPlanActive={isPlanActive}
        />
      )}

      {openModalVoltarEtapa && (
        <ModalVoltarEtapa
          etapa={etapa}
          t={t}
          open={openModalVoltarEtapa}
          setOpen={setOpenModalVoltarEtapa}
          fetchSemaphore={fetchTableItems}
          item={row}
          version={version}
          isPlanActive={isPlanActive}
        />
      )}
    </div>
  );
}
