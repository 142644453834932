import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';
const Iframe = styled.iframe`
  width: 100%;
  margin: 0 auto;
  min-height: 110vh;
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
`;

function MontarIframe(iframe, t) {
  return (
    <Iframe
      key={iframe.title}
      title={iframe.title}
      src={iframe.src}
      scrolling="yes"
      frameBorder="no"
    />
  );
}

function Content({ t, baseInfos, invisibleNumber }) {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    if (invisibleNumber !== undefined) {
      const validation = contents.some(
        ({ page }) => page.index === invisibleNumber
      );
      if (validation === false) {
        setContents([
          ...contents,
          {
            page: {
              index: invisibleNumber,
              item:
                baseInfos[invisibleNumber].type !== 'tableau'
                  ? baseInfos[invisibleNumber].element
                  : MontarIframe(baseInfos[invisibleNumber], t),
            },
          },
        ]);
      }
    }
  }, [contents, invisibleNumber, baseInfos]);

  return (
    <div>
      {contents.map((item, index) => {
        return (
          <span
            key={index}
            style={{
              display: item.page.index === invisibleNumber ? '' : 'none',
            }}
          >
            {item.page.item}
          </span>
        );
      })}
    </div>
  );
}

export default withTranslation('maxion')(Content);
