const colors = {
  error: '#d32f2f',
  transparent: 'transparent',
  black: '#000000',
  white: '#FAFAFA',
  white_smoke: '#F7F9FC',
  cyan: '#E1E1E6',
  blue_200: '#4a8ac7',
  blue_250: '#4c8bc9',
  blue_300: '#0783c2',
  blue_400: '#376fd0',
  blue_600: '#05527a',
  blue_700: '#162C43',
  blue_gradient:
    'linear-gradient(180deg, rgba(7,131,194,1) 10%, rgba(7,131,194,1) 20%, rgba(27,70,100,1) 80%)',
  orange: '#e76c2b',
  gray_300: '#999',
  gray_500: '#4b4d4f',
};

export default colors;
