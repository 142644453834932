import React, { useState } from 'react';
import styled from '@emotion/styled';
import Loading from '../../../../../../../components/Loading';

import MenuHamburguer from './MenuHamburguer';
import Semaphore from './Semaphore';

const Overflow = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
`;

const BoxTable = styled.div`
  position: relative;
  margin-top: 30px;
  min-height: 495px;
  min-width: 1000px;

  .flex {
    display: flex;
  }

  .invisible {
    color: transparent;
  }

  h3 {
    text-align: center;
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .customer {
    justify-content: left !important;
    text-align: left !important;
    padding-left: 20px;
  }

  .item {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 15px;
    font-weight: 500;
  }
`;

const BoxHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const BoxTableContent = styled.div`
  width: 100%;
  height: 350px;
`;

const BoxTableRow = styled.div`
  background-color: ${({ indexRow }) =>
    indexRow % 2 === 0 ? '#F4F8FC' : '#FFFFFF'};
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 70px;
  align-items: center;
`;

const BoxStats = styled.div`
  background-color: transparent;
  padding: 10px;
  text-transform: uppercase;
  font-size: 16px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  button {
    background-color: transparent;
    border: 1px solid #3182ce;
    border-radius: 6px;
    color: #3182ce;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover:not(:disabled) {
      background-color: #3182ce;
      color: #fff;
    }

    &:disabled {
      pointer-events: none;
    }

    &.active {
      background-color: #3182ce;
      color: #fff;
      pointer-events: none;
    }
  }
`;

function Header({ t }) {
  return (
    <BoxHeader>
      <div
        style={{
          display: 'flex',
          width: '50%',
          justifyContent: 'space-around',
        }}
      >
        <div style={{ width: '35%' }}>
          <h3 className="invisible">-</h3>
          <div className="customer item">{t('Cliente', { ns: 'maxion' })}</div>
        </div>

        <div style={{ width: '65%' }}>
          <h3 className="title">
            {t('Análise de mercado', { ns: 'maxion' }).toUpperCase()}
          </h3>
          <div style={{ display: 'flex' }}>
            <div className="item">
              {t('Dias úteis', { ns: 'maxion' })}
              /<br />
              {t('Veículos', { ns: 'maxion' })}
            </div>

            <div className="item">
              {t('Novos', { ns: 'maxion' })}
              <br />
              {t('Produtos', { ns: 'maxion' })}
            </div>

            <div className="item">{t('Mercado', { ns: 'maxion' })}</div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          width: '50%',
          justifyContent: 'space-around',
        }}
      >
        <div style={{ width: '50%' }}>
          <h3>{t('Plano operacional', { ns: 'maxion' }).toUpperCase()}</h3>
          <div className="flex">
            <div className="item">{t('Preço', { ns: 'maxion' })}</div>

            <div className="item">{t('Volume', { ns: 'maxion' })}</div>
          </div>
        </div>

        <div style={{ width: '50%' }}>
          <h3 className="invisible">-</h3>
          <div className="flex">
            <div className="item">{t('Status', { ns: 'maxion' })}</div>

            <div className="item">{t('Ações', { ns: 'maxion' })}</div>
          </div>
        </div>
      </div>
    </BoxHeader>
  );
}

function Stats({ status, t }) {
  return (
    <BoxStats style={{ color: status === 'completed' ? '#76BE56' : '#FF0000' }}>
      {status === 'completed'
        ? t('Completo', { ns: 'maxion' })
        : t('Incompleto', { ns: 'maxion' })}
    </BoxStats>
  );
}

function Row({ t, row, indexRow, fetchTableItems, version, isPlanActive }) {
  function getAllStats() {
    const keys = [
      'calendar',
      'segmentation',
      'aop',
      'operational_plan_value',
      'operational_plan_volume',
    ];

    const isAllFinished = keys.every((key) => {
      return row[key] === 'finished';
    });

    return isAllFinished ? 'completed' : 'incomplete';
  }

  return (
    <BoxTableRow indexRow={indexRow}>
      <div
        style={{
          display: 'flex',
          width: '50%',
          justifyContent: 'space-around',
        }}
      >
        {/* CLIENTE */}
        <div style={{ width: '35%' }} className="customer item">
          {row.descricao_grupo_cliente}
        </div>

        <div style={{ display: 'flex', width: '65%' }}>
          {/* DIAS UTEIS/VEICULOS */}
          <div className="item">
            <Semaphore t={t} row={row} currentField="calendar" />
          </div>

          {/* NOVOS PRODUTOS */}
          <div className="item">
            <Semaphore t={t} row={row} currentField="new_product" />
          </div>

          {/* MERCADO */}
          <div className="item">
            <Semaphore t={t} row={row} currentField="aop" />
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          width: '50%',
          justifyContent: 'space-around',
        }}
      >
        <div style={{ display: 'flex', width: '50%' }}>
          {/* PLANO PREÇO */}
          <div className="item">
            <Semaphore t={t} row={row} currentField="operational_plan_value" />
          </div>

          {/* PLANO VOLUME */}
          <div className="item">
            <Semaphore t={t} row={row} currentField="operational_plan_volume" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '50%' }}>
          {/* STATUS */}
          <div className="item">
            <Stats status={getAllStats(row)} t={t} />
          </div>

          {/* AÇÕES */}
          <div className="item">
            <MenuHamburguer
              version={version}
              isPlanActive={isPlanActive}
              fetchTableItems={fetchTableItems}
              row={row}
              t={t}
            />
          </div>
        </div>
      </div>
    </BoxTableRow>
  );
}

export default function Table({
  t,
  items,
  loadingItems,
  fetchTableItems,
  version,
  isPlanActive,
}) {
  const ITEMS_PER_PAGE = 5;
  const MAX_PAGE_BUTTONS = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / ITEMS_PER_PAGE);

  const pageData = items.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  function handlePageClick(event) {
    const nextPage = parseInt(event.target.textContent);
    setCurrentPage(nextPage);
  }

  function renderPageButtons() {
    let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_BUTTONS / 2));
    let endPage = Math.min(totalPages, startPage + MAX_PAGE_BUTTONS - 1);

    const pageButtons = [];

    if (startPage > 1) {
      pageButtons.push(
        <button onClick={() => setCurrentPage(currentPage - MAX_PAGE_BUTTONS)}>
          ...
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={handlePageClick}
          disabled={i === currentPage}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      pageButtons.push(
        <button
          key={pageButtons.length + 1}
          onClick={() => setCurrentPage(currentPage + MAX_PAGE_BUTTONS)}
        >
          ...
        </button>
      );
    }

    return pageButtons.length > 1 ? pageButtons : null;
  }

  return (
    <Overflow>
      <BoxTable>
        <Header t={t} />

        {loadingItems === false && items.length > 0 && (
          <React.Fragment>
            <BoxTableContent>
              {pageData.map((item, index) => (
                <div key={index}>
                  <Row
                    version={version}
                    t={t}
                    row={item}
                    indexRow={index}
                    fetchTableItems={fetchTableItems}
                    isPlanActive={isPlanActive}
                  />
                </div>
              ))}
            </BoxTableContent>

            <Pagination>{renderPageButtons()}</Pagination>
          </React.Fragment>
        )}

        {loadingItems === false && items.length === 0 && (
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#4b4d4f',
              fontWeight: 600,
              fontSize: '17px',
              minHeight: '500px',
            }}
          >
            <p>
              {t('Esta versão ainda não possui clientes', { ns: 'maxion' })}
            </p>
          </div>
        )}

        {loadingItems && <Loading />}
      </BoxTable>
    </Overflow>
  );
}
