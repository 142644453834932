import { createContext, useEffect, useReducer } from 'react';
import _ from 'lodash';

import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      // console.log("INITIALIZE")
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(
            `${process.env.REACT_APP_PUBLIC_API}auth/current-user`
          );
          let user = response.data;

          // console.log("User", response, user);

          let userPermissions = _.map(user.permissions, 'id');
          let permissionsRoles = [];

          _.map(user.roles, (role) => {
            permissionsRoles.push(_.map(role.permissions, 'id'));
          });

          let permissions = userPermissions;

          user.permissionsList = permissions;
          user.rolesList = _.map(user.roles, 'id');

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (username, password) => {
    let formData = new FormData();

    formData.append('username', username);
    formData.append('password', password);

    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_API}auth/login`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    // console.log("response login", response.data)
    const { accessToken, user } = response.data;

    let userPermissions = _.map(user.permissions, 'id');
    let permissionsRoles = [];

    _.map(user.roles, (role) => {
      permissionsRoles.push(_.map(role.permissions, 'id'));
    });

    let permissions = _.union(userPermissions, _.flatten(permissionsRoles));

    user.permissionsList = permissions;
    user.rolesList = _.map(user.roles, 'id');

    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signOut = async () => {
    setSession(null);
    window.localStorage.setItem('accessToken', '');
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/auth/sign-up', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
