const baseItems = {
  page: 'Mercado',
  buttons: [
    {
      title: 'Informações Financeiras',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-Concorrentes/Financeiro',
      height: '2300px',
    },
  ],
};

export default baseItems;
