import styled from '@emotion/styled';

import { Accordion as AccordionMUI } from '../../../../../../../components/Accordion';
import { Button as ButtonMUI } from '../../../../../../../components/Buttons';

export const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

export const Card = styled.div`
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;

  h1,
  h2,
  h3 {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }

  h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 28px;
  }
`;

export const CardContent = styled.div`
  width: 100%;

  form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 25px;
    padding-bottom: 20px;
  }

  .item {
    display: flex;
    gap: 8px;
    flex-direction: column;

    @media (max-width: 1700px) {
      flex-direction: column;
      align-items: normal;
      gap: 10px;
    }
  }

  .add-button {
    display: flex;
    align-items: end;
    justify-content: start;
    height: 100%;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
    min-width: 185px;
  }
`;

export const TextError = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

export const ClosedDate = styled.b`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray_500};
`;

export const Button = styled(ButtonMUI)`
  background-color: #008cca;
  width: 160px;
  height: 35px;
  font-size: 15px;
`;

export const DivTables = styled.div`
  min-width: 1000px;
  overflow: auto;
`;

export const Results = styled.div`
  padding-top: 30px;
  border-top: 1px solid #ccc;
`;

export const Accordion = styled(AccordionMUI)`
  position: static;

  .MuiAccordionSummary-root {
    padding: 0 8px 0 0;
  }

  header {
    border-bottom: 1px solid #ccc;

    svg {
      width: 35px;
      height: 35px;
      color: #399dd2;
      float: right;
    }

    span {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
`;

export const Save = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 50px;
  margin: 0 10px;
  border-top: 1px solid #ccc;

  span {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }

  input {
    display: block;
    min-width: 400px;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #ccc;
    outline: none;
  }

  .close {
    background-color: #9a9a9a;

    :disabled {
      background-color: #d3d3d3;
    }
  }
`;

export const Flag = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: $#4a8ac7;

  p {
    color: $#4a8ac7;
  }
`;
