import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import useAuth from '../../../../../../../hooks/useAuth';
import { useNavBlock } from '../../../../../../../hooks/useNavBlock';
import { checkPermission } from '../../../../../../../utils/permissions';
import { useMaskito } from '@maskito/react';
import { POContext } from '../../../../../../../contexts/POContext';

import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import axios from '../../../../../../../utils/axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Tooltip, Button, Alert, AlertTitle } from '@mui/material';
import TableCalculationDistribuition from './TableCalculationDistribuition';
import ModalDescart from './ModalDescart';
import Loading from '../../../../../../../components/Loading';

const Overflow = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 50px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-width: 1050px;

  .title {
    background-color: #c2ddf0;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue_300};
    height: 40px;
    line-height: 40px;
    width: 100%;
    font-size: 15px;
  }

  .column {
    width: 10%;

    .item {
      padding-left: 10px;
      justify-content: left;
    }
  }

  .input {
    width: 100%;
    height: 35px;
    text-align: center;
    width: 100%;
    border: none;
    font-size: 14px;
    background-color: #c2ddf0;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
    font-size: 14px;
  }
`;

const Save = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 50px;
  margin-top: 50px;
  border-top: 1px solid #ccc;

  input {
    display: block;
    min-width: 400px;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #ccc;
    outline: none;
  }

  .buttons {
    display: flex;
    gap: 30px;
  }
`;

const segments = [
  { label: 'Caminhões', key: 'TRUCK', code: 'Z02' },
  { label: 'Ônibus', key: 'BUS', code: 'Z01' },
  { label: 'Pickups', key: 'PICKUP', code: 'Z04' },
];

const numberOptions = {
  precision: 2,
  decimalSeparator: ',',
  thousandSeparator: '.',
};

const maskitoNumberOptions = maskitoNumberOptionsGenerator(numberOptions);

function formatNumber(number) {
  if (number === null || number === undefined || isNaN(number)) {
    return '*';
  }

  const maskitoValue = new Intl.NumberFormat(
    'pt-BR',
    maskitoNumberOptions
  ).format(number);

  if (Math.abs(number) >= 1000000) {
    let formattedNumber;
    let tooltipTitle;

    if (number >= 1000000000 || number <= -1000000000) {
      formattedNumber =
        (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
      tooltipTitle = maskitoValue;
    } else {
      formattedNumber = (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      tooltipTitle = maskitoValue;
    }

    return (
      <Tooltip title={tooltipTitle}>
        <span style={{ cursor: 'default' }}>{formattedNumber}</span>
      </Tooltip>
    );
  }

  return maskitoValue;
}

function formatPercent(number) {
  if (number === null || number === undefined || isNaN(number)) {
    return '*';
  }

  const maskitoValue = new Intl.NumberFormat(
    'pt-BR',
    maskitoNumberOptions
  ).format(number);

  return `${maskitoValue}%`;
}

function InputNumberMask({ ...rest }) {
  const inputRef = useMaskito({ options: maskitoNumberOptions });

  return <input {...rest} ref={inputRef} />;
}

export default function TableCalculationVehicles({
  t,
  versionId,
  customerId,
  isPlanActive,
  refreshTables,
  onInnitialize,
  fetchParameters,
  setRefreshTablesBySegment,
}) {
  const [data, setData] = useState(null);
  const [responseApi, setResponseApi] = useState(null);
  const [inputOfField, setInputOfField] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [distributionErrors, setDistributionErrors] = useState({});
  const [openModalDescart, setOpenModalDescart] = useState(false);
  const observations = useRef(null);

  const {
    version,
    customer,
    setIsEditingAop,
    setInvisibleNumber,
    setCustomer,
  } = useContext(POContext);
  const { user } = useAuth();

  const finishedAop = customer && customer.aop === 'finished';
  const userHasPermissionToManage = user && checkPermission(user, [], [9]);
  const isDisabledManage =
    !userHasPermissionToManage || !isPlanActive || finishedAop;

  const fetchData = useCallback(async () => {
    try {
      setLoadingData(true);

      const { data: apiData } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_aop/get_market_info_calculation/version_id/${versionId}/sk_grupo_cliente/${customerId}`
      );

      // Percorre o data por segmento
      Object.keys(apiData).forEach((segment) => {
        const segmentData = apiData[segment];
        const { quantity_distribution } = segmentData;
        observations.current = apiData[segment].observations;

        // Calcula o total da distribuição
        const totalQuantityDistribution = quantity_distribution.reduce(
          (total, value) => total + value,
          0
        );

        // Adiciona os novos campos ao objeto do segmento
        apiData[segment] = {
          ...segmentData,
          total_quantity_distribution: totalQuantityDistribution,
        };
      });

      setData(apiData);
      setResponseApi(apiData);
      setLoadingData(false);
    } catch (error) {
      console.log(error);
      setLoadingData(false);
      toast.error(t('Erro ao buscar valores de distribuição e veículos'));
    }
  }, [t, versionId, customerId]);

  useEffect(() => {
    if (versionId && customerId) {
      fetchData();
    }
  }, [versionId, customerId, fetchData]);

  useEffect(() => {
    if (refreshTables) {
      fetchData();
      setDistributionErrors({});
      onInnitialize();
    }
  }, [refreshTables, onInnitialize, fetchData]);

  function equalsObjects(objetct1, objetct2) {
    // Obtém as chaves dos dois objetos
    const chavesObjeto1 = _.keys(objetct1);
    const chavesObjeto2 = _.keys(objetct2);

    // Verifica as chaves comuns entre os dois objetos
    const chavesComuns = chavesObjeto1.filter((chave) =>
      chavesObjeto2.includes(chave)
    );

    // Cria objetos temporários apenas com as chaves comuns
    const tempObjeto1 = {};
    const tempObjeto2 = {};

    chavesComuns.forEach((chave) => {
      tempObjeto1[chave] = objetct1[chave];
      tempObjeto2[chave] = objetct2[chave];
    });

    // Verifica se os objetos temporários são iguais
    return _.isEqual(tempObjeto1, tempObjeto2);
  }

  useEffect(() => {
    const isEdit = !equalsObjects(data, responseApi);
    setIsEditingAop(isEdit);
  }, [data, responseApi, setIsEditingAop]);

  // Bloquear navegação
  useNavBlock(!equalsObjects(data, responseApi));

  function verifyInputOfField(segment, field) {
    if (inputOfField?.segment === segment && inputOfField?.field === field) {
      return true;
    } else {
      return false;
    }
  }

  function verifyQuantityDistribution(data) {
    const errors = {};

    segments.forEach((segment) => {
      const totalPlan = data[segment.key].quantity_plan;
      const totalDistributed = data[segment.key].quantity_distribution.reduce(
        (acc, value) => acc + (value || 0), // tratar valores nulos
        0
      );

      // Calcular a diferença entre o plano total e a distribuição total
      const difference = Math.abs(totalPlan - totalDistributed);

      // Se a diferença for maior que 5, adicionar um erro
      if (difference > 5) {
        errors[segment.label] = `${t('Distribuição incorreta', {
          ns: 'maxion',
        })} - ${t('total plano', { ns: 'maxion' })}: ${totalPlan}, ${t(
          'total distribuição',
          { ns: 'maxion' }
        )}: ${totalDistributed}, ${t('diferença de', {
          ns: 'maxion',
        })}: ${difference}`;
      }
    });

    setDistributionErrors(errors);
  }

  function calculateDistribution(segmentData) {
    const totalWorkDays = segmentData.work_days.reduce((acc, value) => {
      return (acc += value);
    }, 0);

    const totalPlan = segmentData.quantity_plan;

    const newArrayDistruition = segmentData?.quantity_distribution.map(
      (value, indexMonth) => {
        const workDaysMonth = segmentData?.work_days[indexMonth];

        if (!totalPlan || !workDaysMonth) {
          return 0;
        } else {
          const newValue = (totalPlan / totalWorkDays) * workDaysMonth;
          return Math.round(newValue);
        }
      }
    );

    return newArrayDistruition;
  }

  function calculateJpd(segmentData) {
    return segmentData.quantity_distribution.map((value, indexMonth) => {
      const workDaysMonth = segmentData.work_days[indexMonth];
      if (!value || !workDaysMonth) {
        return 0;
      } else {
        return Math.round(value / workDaysMonth);
      }
    });
  }

  function recalculateSegmentData(data, segment, field) {
    const newData = _.cloneDeep(data);
    let segmentData = newData[segment];

    if (!segmentData) {
      return;
    }

    const quantityActual = segmentData.quantity_actual;
    let quantityPlan = segmentData.quantity_plan;

    // Se entrada for campo de evolução recalcular quantidade da projeção e veic nat/ext
    if (field === 'pct_evolution') {
      const pctEvolution = segmentData['pct_evolution'];

      if (pctEvolution === 100) {
        quantityPlan = quantityActual * 2;
      } else {
        quantityPlan = quantityActual * (1 + pctEvolution / 100);
      }

      const pctNat = segmentData.pct_nat;
      const pctExt = segmentData.pct_ext;

      segmentData.quantity_plan = Math.round(quantityPlan);
      segmentData.vehicle_quantity_nat = Math.round(
        (pctNat / 100) * quantityPlan
      );
      segmentData.vehicle_quantity_ext = Math.round(
        (pctExt / 100) * quantityPlan
      );
      segmentData.quantity_distribution = calculateDistribution(segmentData);
      segmentData.jpd = calculateJpd(segmentData);
      segmentData.total_quantity_distribution =
        segmentData.quantity_distribution.reduce(
          (total, value) => total + (value || 0),
          0
        );
    }

    if (field === 'pct_nat' || field === 'pct_ext') {
      let pctNat = segmentData.pct_nat;
      let pctExt = segmentData.pct_ext;

      // Ajusta pctNat e pctExt para garantir que a soma seja 100%
      if (inputOfField.field === 'pct_nat') {
        pctExt = 100 - pctNat;
      } else if (inputOfField.field === 'pct_ext') {
        pctNat = 100 - pctExt;
      }

      segmentData.pct_nat = pctNat;
      segmentData.pct_ext = pctExt;
      segmentData.vehicle_quantity_nat = Math.round(
        (pctNat / 100) * quantityPlan
      );
      segmentData.vehicle_quantity_ext = Math.round(
        (pctExt / 100) * quantityPlan
      );
    }

    verifyQuantityDistribution(newData);
    setData(newData);
  }

  function handleBlur(segment, field, value) {
    setInputOfField(null);
    if (value === '') return;

    const valueWithStandardHyphen = value.replace(/−/g, '-');
    const valueWithoutThousandSeparator = valueWithStandardHyphen.replace(
      /\./g,
      ''
    );
    const valueWithDot = valueWithoutThousandSeparator.replace(',', '.');
    const numberValue = parseFloat(valueWithDot);

    const newData = { ...data };
    newData[segment][field] = numberValue;
    recalculateSegmentData(newData, segment, field);
  }

  function handleClick(segment, field) {
    setInputOfField({ segment: segment, field: field });
  }

  async function handleSave(finalize) {
    try {
      setLoadingSubmit(true);

      const segmentsArray = segments.map((segment) => {
        const segmentData = data[segment.key];
        const vehicles = {};

        segmentData.quantity_distribution.forEach((value, index) => {
          vehicles[`vehicles_m${index.toString().padStart(2, '0')}`] = value;
        });

        return {
          code_segment: segment.code, // Use the correct segment code here
          pct_nat: segmentData.pct_nat,
          pct_ext: segmentData.pct_ext,
          pct_evolution: segmentData.pct_evolution,
          ...vehicles,
        };
      });

      const apiPayload = {
        version_id: versionId,
        sk_grupo_cliente: customerId,
        observations: observations.current,
        finalize: finalize,
        segments: segmentsArray,
      };

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}po_aop/upsert`,
        apiPayload
      );

      if (finalize === true) {
        toast.success(
          t('Análise de mercado finalizada com sucesso', { ns: 'maxion' })
        );

        setInvisibleNumber(1);
      } else {
        const newCustomer = { ...customer, aop: 'ongoing' };
        setCustomer(newCustomer);

        toast.success(
          t('Análise de mercado salva com sucesso', { ns: 'maxion' })
        );
        fetchData();
      }

      fetchParameters();

      setLoadingSubmit(false);
      setRefreshTablesBySegment(true);
    } catch (error) {
      console.log(error);
      toast.error(
        t('Erro ao atualizar veículos e distribuição', { ns: 'maxion' })
      );
      setLoadingSubmit(false);
    }
  }

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <Overflow>
        <Box>
          <div className="title">
            {t('Veículos', { ns: 'maxion' }).toUpperCase()}
          </div>

          <div style={{ display: 'flex', width: '100%' }}>
            <div className="column">
              <div className="title">{t('Segmento', { ns: 'maxion' })}</div>
              {segments.map((segment, index) => (
                <div key={index} className="item">
                  {t(segment.label, { ns: 'maxion' })}
                </div>
              ))}
            </div>

            <div style={{ display: 'flex', width: '90%' }}>
              <div style={{ width: '30%' }}>
                <header style={{ display: 'flex', width: '100%' }}>
                  <div className="title">
                    {t('Atual', { ns: 'maxion' })} ({Number(version.year) - 1})
                  </div>
                  <div className="title">
                    {t('Projeção', { ns: 'maxion' })} ({Number(version.year)})
                  </div>
                </header>

                <div>
                  {segments.map((segment, index) => (
                    <div key={index} style={{ display: 'flex', width: '100%' }}>
                      <div className="item">
                        {formatNumber(data?.[segment.key]?.quantity_actual)}
                      </div>
                      <div className="item">
                        {formatNumber(data?.[segment.key]?.quantity_plan)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ width: '10%' }}>
                <header style={{ display: 'flex', width: '100%' }}>
                  <div className="title">{t('Evolução', { ns: 'maxion' })}</div>
                </header>

                <div>
                  {segments.map((segment, index) => {
                    const pctEvolution = data?.[segment.key]?.pct_evolution;
                    const quantityActual = data?.[segment.key]?.quantity_actual;
                    const isCanEdit =
                      Boolean(quantityActual) &&
                      !isDisabledManage &&
                      pctEvolution !== null &&
                      pctEvolution !== undefined;

                    return (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          width: '100%',
                          background: '#C2DDF0',
                        }}
                      >
                        {verifyInputOfField(segment.key, 'pct_evolution') ? (
                          <InputNumberMask
                            autoFocus
                            maxLength={5}
                            className="input"
                            defaultValue={formatNumber(pctEvolution)}
                            onBlur={(e) => {
                              handleBlur(
                                segment.key,
                                'pct_evolution',
                                e.target.value
                              );
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleBlur(
                                  segment.key,
                                  'pct_evolution',
                                  e.target.value
                                );
                              }
                            }}
                          />
                        ) : (
                          <div
                            className="item"
                            style={{ cursor: isCanEdit ? 'pointer' : 'auto' }}
                            onClick={() => {
                              if (isCanEdit) {
                                handleClick(segment.key, 'pct_evolution');
                              }
                            }}
                          >
                            {formatPercent(pctEvolution)}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div style={{ width: '30.01%' }}>
                <header style={{ display: 'flex', width: '100%' }}>
                  <div className="title">
                    {t('Brasil (% / veículos)', { ns: 'maxion' })}
                  </div>
                </header>

                <div>
                  {segments.map((segment, index) => {
                    const pctNat = data?.[segment.key]?.pct_nat;
                    const vehicleNat =
                      data?.[segment.key]?.vehicle_quantity_nat;
                    const quantityPlan = data?.[segment.key]?.quantity_plan;
                    const isCanEdit =
                      Boolean(quantityPlan) &&
                      !isDisabledManage &&
                      pctNat !== null &&
                      pctNat !== undefined;

                    return (
                      <div
                        key={index}
                        style={{ display: 'flex', width: '100%' }}
                      >
                        <div style={{ width: '100%', background: '#C2DDF0' }}>
                          {verifyInputOfField(segment.key, 'pct_nat') ? (
                            <InputNumberMask
                              autoFocus
                              maxLength={5}
                              className="input"
                              defaultValue={formatNumber(pctNat)}
                              onBlur={(e) => {
                                handleBlur(
                                  segment.key,
                                  'pct_nat',
                                  e.target.value
                                );
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleBlur(
                                    segment.key,
                                    'pct_nat',
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          ) : (
                            <div
                              className="item"
                              style={{ cursor: isCanEdit ? 'pointer' : 'auto' }}
                              onClick={() => {
                                if (isCanEdit) {
                                  handleClick(segment.key, 'pct_nat');
                                }
                              }}
                            >
                              {formatPercent(pctNat)}
                            </div>
                          )}
                        </div>

                        <div className="item">{formatNumber(vehicleNat)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div style={{ width: '30.01%' }}>
                <header style={{ display: 'flex', width: '100%' }}>
                  <div className="title">
                    {t('Exportação (% / veículos)', { ns: 'maxion' })}
                  </div>
                </header>

                {segments.map((segment, index) => {
                  const pctExt = data?.[segment.key]?.pct_ext;
                  const vehicleExt = data?.[segment.key]?.vehicle_quantity_ext;
                  const quantityPlan = data?.[segment.key]?.quantity_plan;
                  const isCanEdit =
                    Boolean(quantityPlan) &&
                    !isDisabledManage &&
                    pctExt !== null &&
                    pctExt !== undefined;

                  return (
                    <div key={index} style={{ display: 'flex', width: '100%' }}>
                      <div style={{ width: '100%', background: '#C2DDF0' }}>
                        {verifyInputOfField(segment.key, 'pct_ext') ? (
                          <input
                            autoFocus
                            maxLength={5}
                            className="input"
                            defaultValue={formatNumber(pctExt)}
                            onBlur={(e) => {
                              handleBlur(
                                segment.key,
                                'pct_ext',
                                e.target.value
                              );
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleBlur(
                                  segment.key,
                                  'pct_ext',
                                  e.target.value
                                );
                              }
                            }}
                          />
                        ) : (
                          <div
                            className="item"
                            style={{ cursor: isCanEdit ? 'pointer' : 'auto' }}
                            onClick={() => {
                              if (isCanEdit) {
                                handleClick(segment.key, 'pct_ext');
                              }
                            }}
                          >
                            {formatPercent(pctExt)}
                          </div>
                        )}
                      </div>

                      <div className="item">{formatNumber(vehicleExt)}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Box>
      </Overflow>

      {Object.keys(distributionErrors).length > 0 && (
        <div>
          {Object.entries(distributionErrors).map(([segment, error]) => (
            <div key={segment} style={{ margin: '5px 0' }}>
              <Alert severity="error" key={segment}>
                <AlertTitle>{segment}</AlertTitle>
                {error}
              </Alert>
            </div>
          ))}
        </div>
      )}

      <TableCalculationDistribuition
        t={t}
        data={data}
        setData={setData}
        segments={segments}
        isPlanActive={isPlanActive}
        verifyQuantityDistribution={verifyQuantityDistribution}
        calculateJpd={calculateJpd}
      />

      <Save>
        <input
          required
          disabled={isDisabledManage}
          placeholder={`${t('Descrição', { ns: 'maxion' })}:`}
          ref={observations}
        />

        <div className="buttons">
          <Button
            disabled={
              isDisabledManage || Object.keys(distributionErrors).length > 0
            }
            variant="contained"
            color="primary"
            onClick={() => handleSave(false)}
          >
            {t('Salvar', { ns: 'maxion' })}
          </Button>

          <Button
            disabled={
              isDisabledManage || Object.keys(distributionErrors).length > 0
            }
            variant="contained"
            color="primary"
            onClick={() => handleSave(true)}
          >
            {t('Finalizar', { ns: 'maxion' })}
          </Button>

          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              const equals = equalsObjects(data, responseApi);

              if (!equals) {
                setOpenModalDescart(true);
              } else {
                setInvisibleNumber(1);
              }
            }}
          >
            {t('Voltar', { ns: 'maxion' })}
          </Button>
        </div>
      </Save>

      {(loadingData || loadingSubmit) && <Loading />}

      {openModalDescart && (
        <ModalDescart
          open={openModalDescart}
          setOpen={setOpenModalDescart}
          setInvisibleNumber={setInvisibleNumber}
          t={t}
        />
      )}
    </div>
  );
}
