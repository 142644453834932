const baseItems = {
  buttons: [
    {
      title: 'Gráficos',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-FRAGA/Painel5',
      height: '2200px',
    },
    {
      title: 'Tabelas',
      type: 'tableau',
      index: 1,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-FRAGA/Painel6',
      height: '1000px',
    },
  ],
};

export default baseItems;
