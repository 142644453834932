import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import * as C from './styles.js';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import axios from '../../../../../../../utils/axios.js';

import { Button, CircularProgress } from '@mui/material';

import UnitSelectAsync from '../../../../../../../components/UnitSelectAsync.js';
import { TextField } from '@mui/material';
import TextFieldMaskNumber from '../../../../../../../components/TextFieldMaskNumber.js';

export default function InputsNewProduct({
  fetchSemaphore,
  fetchTableItems,
  row,
  t,
  version,
  formikInitialValues,
  setFormikInitialValues,
  isEditingProduct,
  setIsEditingProduct,
  rowId,
}) {
  const [segmentos, setSegmentos] = useState([]);
  const [aplicacoes, setAplicacoes] = useState([]);
  const [organizacoes, setOrganizacoes] = useState([]);
  const [hierarquias, setHierarquias] = useState([]);
  const [clientes, setClientes] = useState([]);

  const [loadingSegmentos, setLoadingSegmentos] = useState(true);
  const [loadingOrganizacao, setLoadingOrganizacao] = useState(true);
  const [loadingHierarquia, setLoadingHierarquia] = useState(true);
  const [loadingAplicacao, setLoadingAplicacao] = useState(true);
  const [loadingClientes, setLoadingClientes] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  //Config Formik + Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
    cliente: Yup.object()
      .required(t('Campo obrigatório', { ns: 'maxion' }))
      .typeError(t('Formato inválido', { ns: 'maxion' })),
    organizacao: Yup.object().required(
      t('Campo obrigatório', { ns: 'maxion' })
    ),
    codigo_produto_cliente: Yup.string()
      .required(t('Campo obrigatório', { ns: 'maxion' }))
      .typeError(t('Formato inválido', { ns: 'maxion' })),
    custo: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
  });

  async function handleCreate(values) {
    try {
      setLoadingSubmit(true);

      const isValid = (value) =>
        value !== undefined && value !== null && value > 0;
      const isValidString = (value) =>
        value !== undefined && value !== null && value.length > 0;

      const body = {
        id: isValid(rowId) ? rowId : null,
        version_id: isValid(row.version_id) ? row.version_id : null,
        year: isValid(version.year) ? version.year : null,
        sk_grupo_cliente: isValid(row.sk_grupo_cliente)
          ? row.sk_grupo_cliente
          : null,
        name: isValidString(values.name) ? values.name : null,
        sk_cliente: isValid(values.cliente?.sk) ? values.cliente.sk : null,
        sk_organizacao: isValid(values.organizacao?.sk)
          ? values.organizacao.sk
          : null,
        sk_segmento: isValid(values.segmento?.sk) ? values.segmento.sk : null,
        sk_aplicacao: isValid(values.aplicacao?.sk)
          ? values.aplicacao.sk
          : null,
        sk_hierarquia_produto: isValid(values.hierarquia?.sk)
          ? values.hierarquia.sk
          : null,
        codigo_produto_cliente: isValidString(values.codigo_produto_cliente)
          ? values.codigo_produto_cliente
          : null,
        sap_id: isValidString(values.sap_id) ? values.sap_id : null,
        unitary_value: isValidString(values.custo)
          ? values.custo.replace('.', '').replace(',', '.')
          : null,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}po_new_products`,
        body
      );

      console.log('response product', response);

      if (response.data.exists) {
        toast.success(
          t('Produto existente! Os dados foram atualizados', { ns: 'maxion' })
        );
      } else {
        toast.success(t('Produto criado com sucesso', { ns: 'maxion' }));
      }

      setIsEditingProduct(false);

      handleClean();

      fetchTableItems();
    } catch (error) {
      if (error.response.data.detail === 'Product already exists') {
        toast.error(t('O produto já existe', { ns: 'maxion' }));
        console.log(`Error submiting data: ${error.response.data.detail}`);
      } else {
        toast.error(t('Erro ao criar produto', { ns: 'maxion' }));
        console.log(`Error submiting data: ${error}`);
      }
    } finally {
      setLoadingSubmit(false);
    }
  }

  async function handleEdit(values) {
    try {
      setLoadingSubmit(true);

      const isValid = (value) =>
        value !== undefined && value !== null && value > 0;
      const isValidString = (value) =>
        value !== undefined && value !== null && value.length > 0;

      const body = {
        id: isValid(rowId) ? rowId : null,
        version_id: isValid(row.version_id) ? row.version_id : null,
        year: isValid(version.year) ? version.year : null,
        sk_grupo_cliente: isValid(row.sk_grupo_cliente)
          ? row.sk_grupo_cliente
          : null,
        name: isValidString(values.name) ? values.name : null,
        sk_cliente: isValid(values.cliente?.sk) ? values.cliente.sk : null,
        sk_organizacao: isValid(values.organizacao?.sk)
          ? values.organizacao.sk
          : null,
        sk_segmento: isValid(values.segmento?.sk) ? values.segmento.sk : null,
        sk_aplicacao: isValid(values.aplicacao?.sk)
          ? values.aplicacao.sk
          : null,
        sk_hierarquia_produto: isValid(values.hierarquia?.sk)
          ? values.hierarquia.sk
          : null,
        codigo_produto_cliente: isValidString(values.codigo_produto_cliente)
          ? values.codigo_produto_cliente
          : null,
        sap_id: isValidString(values.sap_id) ? values.sap_id : null,
        unitary_value: isValidString(values.custo)
          ? values.custo.replace('.', '').replace(',', '.')
          : null,
      };

      await axios.put(
        `${process.env.REACT_APP_PUBLIC_API}po_new_products`,
        body
      );

      toast.success(t('Produto editado com sucesso', { ns: 'maxion' }));

      setIsEditingProduct(false);

      setFormikInitialValues({
        name: '',
        cliente: '',
        organizacao: '',
        aplicacao: '',
        hierarquia: '',
        segmento: '',
        codigo_produto_cliente: '',
        sap_id: '',
        custo: '',
      });

      fetchTableItems();
    } catch (error) {
      toast.error(t('Erro ao editar produto', { ns: 'maxion' }));
      console.log(`Error submiting data: ${error}`);
    } finally {
      setLoadingSubmit(false);
    }
  }

  async function handleClean() {
    setIsEditingProduct(false);
    setFormikInitialValues({
      name: '',
      cliente: '',
      organizacao: '',
      aplicacao: '',
      hierarquia: '',
      segmento: '',
      codigo_produto_cliente: '',
      sap_id: '',
      custo: '',
    });

    formik.resetForm({
      values: {
        name: '',
        cliente: '',
        organizacao: '',
        aplicacao: '',
        hierarquia: '',
        segmento: '',
        codigo_produto_cliente: '',
        sap_id: '',
        custo: '',
      },
    });
  }

  useEffect(async () => {
    try {
      await Promise.all([
        fetchClientes(),
        fetchSegmentos(),
        fetchAplicacoes(),
        fetchOrganizacoes(),
        fetchHierarquias(),
      ]);
    } catch (error) {
      console.log(error);
    }
  }, [formikInitialValues]);

  //   Buscando clientes
  async function fetchClientes() {
    try {
      const body = {
        sk_grupo_cliente: row.sk_grupo_cliente,
        base_date: version.base_date,
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}criticas/clientes_filtrados`,
        body
      );

      setClientes(data);
    } catch (error) {
      console.log(`Error fetching data:  ${error}`);
    } finally {
      setLoadingClientes(false);
    }
  }

  //Buscando segmentos
  async function fetchSegmentos() {
    try {
      setLoadingSegmentos(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/segmentos`,
        {
          search: '',
        }
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_segmento: `${item.codigo_segmento} - ${item.descricao_segmento}`,
        };
      });

      setSegmentos(newArray);
    } catch (error) {
      console.log(`Error fetching data:  ${error}`);
    } finally {
      setLoadingSegmentos(false);
    }
  }

  //Buscando aplicações
  async function fetchAplicacoes() {
    try {
      setLoadingAplicacao(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/aplicacoes`
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_aplicacao: `${item.codigo_aplicacao} - ${item.descricao_aplicacao}`,
        };
      });

      setAplicacoes(newArray);
    } catch (error) {
      console.log(`Error fetching data:  ${error}`);
    } finally {
      setLoadingAplicacao(false);
    }
  }

  //Buscando organizações
  async function fetchOrganizacoes() {
    try {
      setLoadingOrganizacao(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/organizacoes`
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_organizacao: `${item.codigo_organizacao} - ${item.descricao_organizacao}`,
        };
      });
      setOrganizacoes(newArray);
    } catch (error) {
      console.log(`Error fetching data:  ${error}`);
    } finally {
      setLoadingOrganizacao(false);
    }
  }

  //Buscando hierarquias
  async function fetchHierarquias() {
    try {
      setLoadingHierarquia(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/hierarquia-produtos`
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_hierarquia_produto: `${item.codigo_hierarquia_produto} - ${item.descricao_hierarquia_produto}`,
        };
      });

      setHierarquias(newArray);
    } catch (error) {
      console.log(`Error fetching data:  ${error}`);
    } finally {
      setLoadingHierarquia(false);
    }
  }

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    onSubmit: isEditingProduct === true ? handleEdit : handleCreate,
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleChangeValuesFormik = async (value, name) => {
    setFieldValue(name, value);

    const newValues = { ...values };
    newValues[name] = value;
  };

  return (
    <div style={{ width: '100%' }}>
      <C.Card>
        <C.CardContent>
          <form onSubmit={handleSubmit}>
            {/* Organização */}
            <div className="item">
              <p>{t('Selecione a organização', { ns: 'maxion' })} *</p>
              <div>
                <UnitSelectAsync
                  size="small"
                  name="organizacao"
                  value={values.organizacao === '' ? null : values.organizacao}
                  label={t('Organização', { ns: 'maxion' })}
                  loading={loadingOrganizacao}
                  disabled={loadingOrganizacao}
                  loadingText={t('Buscando organizações...', { ns: 'maxion' })}
                  options={organizacoes}
                  getOptionLabel={(option) => option.descricao_organizacao}
                  onBlur={handleBlur}
                  onChange={(event, value) =>
                    handleChangeValuesFormik(value, 'organizacao')
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.sk === value.sk
                  }
                />

                {errors.organizacao && touched.organizacao && (
                  <C.TextError>{errors.organizacao}</C.TextError>
                )}
              </div>
            </div>

            {/* Cliente */}
            <div className="item">
              <p>{t('Selecione o cliente SAP', { ns: 'maxion' })} *</p>
              <div>
                <UnitSelectAsync
                  size="small"
                  limitTags={0}
                  name="cliente"
                  value={values.cliente === '' ? null : values.cliente}
                  label={t('Cliente', { ns: 'maxion' })}
                  loading={loadingClientes}
                  disabled={loadingClientes}
                  loadingText={(t('Buscando clientes...'), { ns: 'maxion' })}
                  options={clientes}
                  getOptionLabel={(option) => option.codigo_e_nome_cliente}
                  onBlur={handleBlur}
                  isOptionEqualToValue={(option, value) =>
                    option.sk === value.sk
                  }
                  onChange={(event, value) => setFieldValue('cliente', value)}
                />

                {errors.cliente && touched.cliente && (
                  <C.TextError>{errors.cliente}</C.TextError>
                )}
              </div>
            </div>

            {/* Segmento */}
            <div className="item">
              <p style={{ display: 'flex' }}>
                <p>{t('Selecione o segmento', { ns: 'maxion' })}</p>
                <span style={{ height: '100%', width: '25px' }} />
              </p>

              <div>
                <UnitSelectAsync
                  size="small"
                  name="segmento"
                  value={values.segmento === '' ? null : values.segmento}
                  label={t('Segmento', { ns: 'maxion' })}
                  loading={loadingSegmentos}
                  disabled={loadingSegmentos}
                  loadingText={(t('Buscando segmentos...'), { ns: 'maxion' })}
                  options={segmentos}
                  getOptionLabel={(option) => option.descricao_segmento}
                  onBlur={handleBlur}
                  onChange={(event, value) =>
                    handleChangeValuesFormik(value, 'segmento')
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.sk === value.sk
                  }
                />

                {errors.segmento && touched.segmento && (
                  <C.TextError>{errors.segmento}</C.TextError>
                )}
              </div>
            </div>

            {/* Aplicação */}
            <div className="item">
              <p style={{ display: 'flex' }}>
                <p>{t('Selecione a aplicação', { ns: 'maxion' })}</p>
                <span style={{ height: '100%', width: '54px' }} />
              </p>

              <div>
                <UnitSelectAsync
                  size="small"
                  name="aplicacao"
                  value={values.aplicacao === '' ? null : values.aplicacao}
                  label={t('Aplicação', { ns: 'maxion' })}
                  loading={loadingAplicacao}
                  disabled={loadingAplicacao}
                  loadingText={(t('Buscando aplicações...'), { ns: 'maxion' })}
                  options={aplicacoes}
                  getOptionLabel={(option) => option.descricao_aplicacao}
                  onBlur={handleBlur}
                  onChange={(event, value) =>
                    handleChangeValuesFormik(value, 'aplicacao')
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.sk === value.sk
                  }
                />

                {errors.aplicacao && touched.aplicacao && (
                  <C.TextError>{errors.aplicacao}</C.TextError>
                )}
              </div>
            </div>

            {/* Hierarquia */}
            <div className="item">
              <p>{t('Selecione a hierarquia', { ns: 'maxion' })}</p>
              <div>
                <UnitSelectAsync
                  limitTags={0}
                  size="small"
                  name="hierarquia"
                  value={values.hierarquia === '' ? null : values.hierarquia}
                  label={t('Hierarquia', { ns: 'maxion' })}
                  loading={loadingHierarquia}
                  disabled={loadingHierarquia}
                  loadingText={t('Buscando hierarquias...', { ns: 'maxion' })}
                  options={hierarquias}
                  getOptionLabel={(option) =>
                    option.descricao_hierarquia_produto
                  }
                  onBlur={handleBlur}
                  onChange={(event, value) =>
                    handleChangeValuesFormik(value, 'hierarquia')
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.sk === value.sk
                  }
                  disableCloseOnSelect
                />

                {errors.hierarquia && touched.hierarquia && (
                  <C.TextError>{errors.hierarquia}</C.TextError>
                )}
              </div>
            </div>

            {/* Código cliente */}
            <div className="item">
              <p>{t('Código no cliente', { ns: 'maxion' })} *</p>

              <div>
                <TextField
                  fullWidth
                  size="small"
                  name="codigo_produto_cliente"
                  value={values.codigo_produto_cliente}
                  label={t('Código do produto no cliente', { ns: 'maxion' })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.codigo_produto_cliente &&
                  touched.codigo_produto_cliente && (
                    <C.TextError>{errors.codigo_produto_cliente}</C.TextError>
                  )}
              </div>
            </div>

            {/* Nome */}
            <div className="item">
              <p>{t('Nome do produto', { ns: 'maxion' })} *</p>

              <div>
                <TextField
                  fullWidth
                  size="small"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  label={t('Nome do produto', { ns: 'maxion' })}
                  onBlur={handleBlur}
                />

                {errors.name && touched.name && (
                  <C.TextError>{errors.name}</C.TextError>
                )}
              </div>
            </div>

            {/* Custo */}
            <div className="item">
              <p>{t('Preço', { ns: 'maxion' })} *</p>

              <div>
                <TextFieldMaskNumber
                  fullWidth
                  size="small"
                  name="custo"
                  value={values.custo}
                  onInput={handleChange}
                  label={t('Preço', { ns: 'maxion' })}
                  onBlur={handleBlur}
                  mask={{
                    precision: 2,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                  }}
                />

                {errors.custo && touched.custo && (
                  <C.TextError>{errors.custo}</C.TextError>
                )}
              </div>
            </div>

            {/* SAP */}
            <div className="item">
              <p>{t('Código SAP', { ns: 'maxion' })}</p>

              <div>
                <TextField
                  fullWidth
                  size="small"
                  name="sap_id"
                  value={values.sap_id}
                  label={t('Código SAP', { ns: 'maxion' })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.sap_id && touched.sap_id && (
                  <C.TextError>{errors.sap_id}</C.TextError>
                )}
              </div>
            </div>

            <div className="add-button">
              {isEditingProduct ? (
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button
                    sx={{ height: '40px', width: '140px' }}
                    variant="outlined"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {!loadingSubmit ? (
                      t('Editar', { ns: 'maxion' })
                    ) : (
                      <CircularProgress size={15} />
                    )}
                  </Button>
                  <Button
                    sx={{ height: '40px', width: '140px' }}
                    variant="outlined"
                    type="submit"
                    onClick={() => handleClean()}
                  >
                    {t('Cancelar', { ns: 'maxion' })}
                  </Button>
                </div>
              ) : (
                <Button
                  sx={{ height: '40px', width: '140px' }}
                  variant="outlined"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {!loadingSubmit ? (
                    t('Adicionar', { ns: 'maxion' })
                  ) : (
                    <CircularProgress size={15} />
                  )}
                </Button>
              )}
            </div>
          </form>
        </C.CardContent>
      </C.Card>
    </div>
  );
}
