import React, { useState, useEffect, useContext } from 'react';
import { checkPermission } from '../../../../../../utils/permissions';
import useAuth from '../../../../../../hooks/useAuth';
import Loading from '../../../../../../components/Loading';
import { useFormik } from 'formik';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styled from '@emotion/styled';
import axios from '../../../../../../utils/axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogTitle as DialogTitleMUI,
  DialogContent as DialogContentMUI,
  DialogActions as DialogActionsMUI,
  DialogContentText,
  Button,
  Divider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { POContext } from '../../../../../../contexts/POContext';

const DialogTitle = styled(DialogTitleMUI)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 12px 20px;
`;

const DialogContent = styled(DialogContentMUI)`
  padding: 25px 20px;
`;

const DialogActions = styled(DialogActionsMUI)`
  background-color: #f6f9fc;
`;

const Input = styled.input`
  background-color: #fff !important;
  margin-right: 10px;
  padding: 6px 8px !important;
  min-width: 200px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  color: ${({ theme }) => theme.colors.gray_500};

  ::placeholder {
    color: #9a9a9a;
    font-weight: 400;
  }
`;

export default function ModalVoltarEtapa({
  fetchSemaphore,
  etapa,
  t,
  open,
  setOpen,
  item,
  isPlanActive,
}) {
  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const { version, customer, setCustomer } = useContext(POContext);

  async function handleSubmit() {
    setLoading(true);

    const body = {
      version_id: version.id,
      sk_grupo_cliente: customer.sk_grupo_cliente,
    };

    try {
      if (etapa === 'mercado') {
        await axios.post(
          `${process.env.REACT_APP_PUBLIC_API}po_aop/aop_reset`,
          body
        );
      } else if (etapa === 'preço') {
        await axios.post(
          `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/value_reset`,
          body
        );
      } else if (etapa === 'volume') {
        await axios.post(
          `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/volume_reset`,
          body
        );
      }

      setCustomer({ ...customer, aop: 'pending' });
      fetchSemaphore();

      toast.success(t('Etapa retrocedida com sucesso'), { ns: 'maxion' });
    } catch (error) {
      toast.error(
        t('Algo deu errado ao retroceder etapas. Tente novamente mais tarde'),
        { ns: 'maxion' }
      );
      console.log(`Error submitting data: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  const { user } = useAuth();

  const { i18n } = useTranslation();

  const key = i18n.language === 'pt-BR' ? 'CONFIRMAR' : 'CONFIRM';

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('Voltar etapa', { ns: 'maxion' })}
        <IconButton
          onClick={() => setOpen(false)}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(`Esta ação irá descartar a análise de ${etapa} realizada`, {
            ns: 'maxion',
          })}
          <br />
          <b style={{ color: '#FF0000' }}>
            {t('Isto não poderá ser desfeito', { ns: 'maxion' })}.
          </b>
        </DialogContentText>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Input
          placeholder={`${t('Digite a palavra', { ns: 'maxion' })} ${key}`}
          onChange={(e) => setValueInput(e.target.value)}
          value={valueInput}
        />

        <Button
          color="primary"
          autoFocus
          disabled={loading || valueInput !== key}
          onClick={() => handleSubmit()}
        >
          {loading ? (
            <CircularProgress size="16px" />
          ) : (
            t('Voltar etapa', { ns: 'maxion' })
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
