import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import { checkPermission } from '../../../../../../../utils/permissions';
import useAuth from '../../../../../../../hooks/useAuth';
import Loading from '../../../../../../../components/Loading';
import axios from '../../../../../../../utils/axios';
import ModalRecalculate from '../../ModalRecalculate';
import { POContext } from '../../../../../../../contexts/POContext';

const Form = styled.div`
  display: flex;
  flex-direction: column;

  .info-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 8px;

    span {
      display: flex;
      align-items: center;
      gap: 8px;

      P {
        color: ${({ theme }) => theme.colors.blue_300};
      }

      b {
        color: ${({ theme }) => theme.colors.gray_500};
        font-weight: 400;
      }
    }
  }

  .form-items {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20px;

    h5 {
      margin-bottom: 10px;
      margin-left: 5px;
      color: ${({ theme }) => theme.colors.blue_300};
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

const initialValuesCalendar = [
  { code: 'm00', nome: 'Janeiro', value: '' },
  { code: 'm01', nome: 'Fevereiro', value: '' },
  { code: 'm02', nome: 'Março', value: '' },
  { code: 'm03', nome: 'Abril', value: '' },
  { code: 'm04', nome: 'Maio', value: '' },
  { code: 'm05', nome: 'Junho', value: '' },
  { code: 'm06', nome: 'Julho', value: '' },
  { code: 'm07', nome: 'Agosto', value: '' },
  { code: 'm08', nome: 'Setembro', value: '' },
  { code: 'm09', nome: 'Outubro', value: '' },
  { code: 'm10', nome: 'Novembro', value: '' },
  { code: 'm11', nome: 'Dezembro', value: '' },
];

const initialValuesSegments = [
  {
    nome: 'Caminhões',
    code: 'Z02',
    value: '',
  },
  {
    nome: 'Ônibus',
    code: 'Z01',
    value: '',
  },
  {
    nome: 'Pickups',
    code: 'Z04',
    value: '',
  },
];

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export default function ModalEditParameters({
  item,
  open,
  setOpen,
  fetchTableItems,
  t,
  version,
  isPlanActive,
}) {
  const [initialValuesFormik, setInitialValuesFormik] = useState({
    calendar: initialValuesCalendar,
    segments: initialValuesSegments,
  });
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const [openModalRecalculate, setOpenModalRecalculate] = useState(false);
  const { customer: customerContext } = useContext(POContext);

  const finishedAop = item && item.aop === 'finished';
  const userHasPermissionToManage = user && checkPermission(user, [], [9, 10]);
  const isDisabledManage =
    finishedAop || !userHasPermissionToManage || !isPlanActive;

  const customer = item.descricao_grupo_cliente;
  const year = version.year;

  const validationSchemaFormik = Yup.object().shape({
    calendar: Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .typeError(t('Formato inválido', { ns: 'maxion' }))
          .min(0, t('Quantidade inválida'))
          .test({
            name: 'max',
            message: t('Quantidade inválida'),
            test: (value, context) =>
              value === undefined ? true : value <= context.parent.maxDays,
          })
          .test('is-integer', t('Formato inválido'), (value) =>
            value === undefined ? true : Number.isInteger(value)
          ),
      })
    ),
    segments: Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .typeError(t('Formato inválido', { ns: 'maxion' }))
          .test('is-integer', t('Formato inválido'), (value) =>
            value === undefined ? true : Number.isInteger(value)
          ),
      })
    ),
  });

  const fetchParameters = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_version_parameters/version-id/${version.id}/grupo-cliente/${item.sk_grupo_cliente}`
      );

      const valuesCalendar = data?.calendar || {};
      const valuesSegments = data?.segments || [];

      const newArrayCalendar = initialValuesCalendar.map((item, index) => {
        const newValue =
          valuesCalendar[item.code] !== undefined &&
          valuesCalendar[item.code] !== null
            ? valuesCalendar[item.code]
            : '';

        return {
          ...item,
          value: newValue,
          maxDays: getDaysInMonth(year, index + 1),
        };
      });

      const objSegments = valuesSegments.reduce((acumulador, item) => {
        acumulador[item.code_segment] = item.quantity;
        return acumulador;
      }, {});

      const newArraySegments = initialValuesSegments.map((item) => {
        const newValue =
          objSegments[item.code] !== undefined &&
          objSegments[item.code] !== null
            ? objSegments[item.code]
            : '';
        return {
          ...item,
          value: newValue,
        };
      });

      setInitialValuesFormik({
        calendar: newArrayCalendar,
        segments: newArraySegments,
      });
    } catch (error) {
      console.log(`Error submiting data: ${error}`);
    } finally {
      setLoading(false);
    }
  }, [version.id, item.sk_grupo_cliente, year]);

  useEffect(() => fetchParameters(), [fetchParameters]);

  function handleOpen() {
    setOpenModalRecalculate(true);
  }

  async function handleCreate(values) {
    try {
      setLoading(true);

      const body = {
        version_id: version.id,
        sk_grupo_cliente: item.sk_grupo_cliente,
        calendar: values.calendar.reduce((acumulador, item) => {
          acumulador[item.code] =
            item.value === '' ? null : parseInt(item.value);
          return acumulador;
        }, {}),
        segments: values.segments.map((item) => {
          return {
            code_segment: item.code,
            quantity: item.value === '' ? null : parseInt(item.value),
          };
        }),
      };

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}po_version_parameters`,
        body
      );

      toast.success(
        t('Parametrização atualizada com sucesso', { ns: 'maxion' })
      );
      setLoading(false);
      fetchTableItems();
    } catch (error) {
      toast.error(t('Erro ao enviar parametrização', { ns: 'maxion' }));
      console.log(`Error submiting data: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: initialValuesFormik,
    onSubmit: handleCreate,
    validationSchema: validationSchemaFormik,
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik;

  function handleClear() {
    const newValuesCalendar = values.calendar.map((item) => {
      return {
        ...item,
        value: '',
      };
    });

    const newValuesSegments = values.segments.map((item) => {
      return {
        ...item,
        value: '',
      };
    });

    setFieldValue('calendar', newValuesCalendar);
    setFieldValue('segments', newValuesSegments);
  }

  return (
    <div>
      {openModalRecalculate &&
        customerContext &&
        customerContext.aop !== 'pending' && (
          <ModalRecalculate
            open={openModalRecalculate}
            setOpen={setOpenModalRecalculate}
            fetchTableItems={fetchTableItems}
            handleCreate={handleCreate}
            formik={formik}
            t={t}
          />
        )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {t('Dias úteis e veículos', { ns: 'maxion' })}
        </DialogTitle>
        <DialogContent>
          <Form>
            <div className="info-form">
              <span>
                <p>{t('Cliente', { ns: 'maxion' })}:</p>
                <b>{customer}</b>
              </span>

              <span>
                <p>{t('Ano', { ns: 'maxion' })}:</p>
                <b>{year}</b>
              </span>
            </div>

            <div className="form-items">
              <div>
                <h5>{t('Dias úteis', { ns: 'maxion' })}</h5>

                <div className="inputs">
                  <Grid container spacing={2}>
                    {values.calendar.map((item, index) => (
                      <Grid item xs={2} key={index}>
                        <TextField
                          disabled={isDisabledManage}
                          fullWidth
                          variant="outlined"
                          name={`calendar.${index}.value`}
                          margin="dense"
                          label={t(item.nome, { ns: 'maxion' })}
                          value={item.value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.calendar &&
                            touched.calendar[index] &&
                            Boolean(errors.calendar && errors.calendar[index])
                          }
                          helperText={
                            touched.calendar &&
                            touched.calendar[index] &&
                            errors.calendar &&
                            errors.calendar[index] &&
                            errors.calendar[index].value
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>

              <div>
                <h5>{t('Veículos', { ns: 'maxion' })}</h5>

                <div className="inputs">
                  <Grid container spacing={3}>
                    {values.segments.map((item, index) => (
                      <Grid item xs={4} key={index}>
                        <TextField
                          disabled={isDisabledManage}
                          name={`segments.${index}.value`}
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          label={t(item.nome, { ns: 'maxion' })}
                          value={item.value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.segments &&
                            touched.segments[index] &&
                            Boolean(errors.segments && errors.segments[index])
                          }
                          helperText={
                            touched.segments &&
                            touched.segments[index] &&
                            errors.segments &&
                            errors.segments[index] &&
                            errors.segments[index].value
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={() => setOpen(false)}>
            {t('Cancelar', { ns: 'maxion' })}
          </Button>

          <Button
            disabled={isSubmitting || isDisabledManage}
            onClick={handleClear}
          >
            {t('Limpar', { ns: 'maxion' })}
          </Button>

          <Button
            onClick={
              customerContext && customerContext.aop !== 'pending'
                ? handleOpen
                : handleSubmit
            }
            disabled={isSubmitting || isDisabledManage}
          >
            {isSubmitting
              ? t('Enviando', { ns: 'maxion' })
              : t('Enviar', { ns: 'maxion' })}
          </Button>
        </DialogActions>
        {loading && <Loading />}
      </Dialog>
    </div>
  );
}
