import {
  Accordion as AccordionMUI,
  AccordionSummary as AccordionSummaryMUI,
  AccordionDetails as AccordionDetailsMUI,
} from '@mui/material';

export function Accordion({ children, ...rest }) {
  return <AccordionMUI {...rest}>{children}</AccordionMUI>;
}

export function AccordionSummary({ children, ...rest }) {
  return <AccordionSummaryMUI {...rest}>{children}</AccordionSummaryMUI>;
}

export function AccordionDetails({ children, ...rest }) {
  return <AccordionDetailsMUI {...rest}>{children}</AccordionDetailsMUI>;
}
