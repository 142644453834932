const baseItems = {
  buttons: [
    {
      title: 'Concorrentes',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-Concorrentes/Concorrentes',
      height: '4000px',
    },
  ],
};

export default baseItems;
