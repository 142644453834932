import React, { useState, useContext, useRef } from 'react';
import styled from '@emotion/styled';
import axios from '../../../../../../../utils/axios';
import useAuth from '../../../../../../../hooks/useAuth';
import { POContext } from '../../../../../../../contexts/POContext';
import { toast } from 'react-toastify';
import { checkPermission } from '../../../../../../../utils/permissions';
import { Button } from '@mui/material';
import ModalDescart from './ModalDescartCritic';
import Loading from '../../../../../../../components/Loading';

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 50px;
  margin-top: 50px;
  border-top: 1px solid #ccc;
  position: relative;

  input {
    display: block;
    min-width: 400px;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #ccc;
    outline: none;
  }

  .buttons {
    display: flex;
    gap: 30px;
  }
`;

export default function SaveCritic({ t }) {
  const [openModalDescart, setOpenModalDescart] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const { user } = useAuth();

  const {
    version,
    customer,
    metricaPO,
    isPlanActive,
    criticDetails,
    setCriticDetails,
    setInvisibleNumber,
    setIsRefreshCritic,
    setIsSave,
  } = useContext(POContext);

  const observations = useRef(null);

  const finishedFields =
    customer &&
    (customer.calendar === 'finished' ||
      customer.segmentation === 'finished' ||
      customer.aop === 'finished');

  const userHasPermissionToManage = user && checkPermission(user, [], [9, 10]);

  const customerField = `operational_plan_${metricaPO}`;

  const isDisabledManage =
    !userHasPermissionToManage ||
    !isPlanActive ||
    !finishedFields ||
    customer?.[customerField] === 'finished';

  async function handleSave(finalize) {
    try {
      setLoadingSave(true);

      const body = {
        version_id: version.id,
        sk_grupo_cliente: customer.sk_grupo_cliente,
        type: metricaPO,
        finalize: finalize,
        description: observations.current.value,
        details: criticDetails,
      };

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/save_critic`,
        body
      );

      setCriticDetails([]);

      if (finalize) {
        toast.success(t('Crítica finalizada com sucesso', { ns: 'maxion' }));
        setInvisibleNumber(1);
      } else {
        toast.success(t('Crítica salva com sucesso.', { ns: 'maxion' }));
        setIsSave(true);
        setIsRefreshCritic(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSave(false);
    }
  }

  if (!userHasPermissionToManage) {
    return null;
  }

  return (
    <Box>
      <input
        required
        disabled={isDisabledManage}
        placeholder={`${t('Descrição', { ns: 'maxion' })}:`}
        ref={observations}
      />

      <div className="buttons">
        <Button
          disabled={isDisabledManage || criticDetails.length === 0}
          variant="contained"
          color="primary"
          onClick={() => handleSave(false)}
        >
          {t('Salvar', { ns: 'maxion' })}
        </Button>

        <Button
          disabled={isDisabledManage}
          variant="contained"
          color="primary"
          onClick={() => handleSave(true)}
        >
          {t('Finalizar', { ns: 'maxion' })}
        </Button>

        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            if (criticDetails.length > 0) {
              setOpenModalDescart(true);
            } else {
              setInvisibleNumber(1);
            }
          }}
        >
          {t('Voltar', { ns: 'maxion' })}
        </Button>
      </div>

      {openModalDescart && (
        <ModalDescart
          open={openModalDescart}
          setOpen={setOpenModalDescart}
          onDescart={() => setInvisibleNumber(1)}
          t={t}
        />
      )}

      {loadingSave && <Loading />}
    </Box>
  );
}
