import React from 'react';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';

import { Paper as MuiPaper } from '@mui/material';
import { spacing } from '@mui/system';

import baseLayoutItems from '../components/Navbar/baseLayoutItems';
import Navbar from '../components/Navbar/Navbar';

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  background: ${({ theme }) => theme.colors.white_smoke};
  flex: 1;
  padding: 50px 60px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

export default function BaseLayout({ children }) {
  return (
    <Root>
      <AppContent>
        <Navbar items={baseLayoutItems} />
        <MainContent>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
}
