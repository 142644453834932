import React, { createContext, useState } from 'react';

export const CriticaInvisibleNumberContext = createContext({});

export function CriticaInvisibleNumberProvider({ children }) {
  const [invisibleNumber, setInvisibleNumber] = useState(0);

  const [customersContext, setCustomersContext] = useState([]);
  const [currentCustomerContext, setCurrentCustomerContext] = useState(null);
  const [currentYearContext, setCurrentYearContext] = useState(null);

  return (
    <CriticaInvisibleNumberContext.Provider
      value={{
        invisibleNumber,
        setInvisibleNumber,
        customersContext,
        setCustomersContext,
        currentCustomerContext,
        setCurrentCustomerContext,
        currentYearContext,
        setCurrentYearContext,
      }}
    >
      {children}
    </CriticaInvisibleNumberContext.Provider>
  );
}
