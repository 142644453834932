import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../utils/axios';

export const POContext = createContext({});

export function POProvider({ children }) {
  const [invisibleNumber, setInvisibleNumber] = useState(0);
  const [pendingNumber, setPendingNumber] = useState(null);

  const [versionId, setVersionId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [version, setVersion] = useState(null);

  const [isEditingAop, setIsEditingAop] = useState(false);

  const [metricaPO, setMetricaPO] = useState('valor');
  const [isPlanActive, setIsPlanActive] = useState(null);
  const [criticDetails, setCriticDetails] = useState([]);
  const [isRefreshCritic, setIsRefreshCritic] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isNewProductOrOnlyNew, setIsNewProductOrOnlyNew] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (pendingNumber !== null) {
      setInvisibleNumber(pendingNumber);
      setPendingNumber(null);
    }
  }, [pendingNumber]);

  const setInvisibleNumberWithCheck = useCallback(
    (number) => {
      if (criticDetails.length > 0 || isEditingAop) {
        const text = t(
          'Há alterações não salvas. Deseja descartar as alterações e sair?',
          { ns: 'maxion' }
        );

        const confirm = window.confirm(text);

        if (confirm) {
          setPendingNumber(number);
        }
      } else {
        setInvisibleNumber(number);
      }
    },
    [t, criticDetails, isEditingAop]
  );

  const cleanCriticDetails = useCallback(async () => {
    try {
      if (version && customer && metricaPO !== '') {
        setCriticDetails([]);

        const body = {
          version_id: version.id,
          sk_grupo_cliente: customer.sk_grupo_cliente,
          type: metricaPO,
        };

        await axios.post(
          `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/clean_critic`,
          body
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [customer, metricaPO, version, setCriticDetails]);

  return (
    <POContext.Provider
      value={{
        invisibleNumber,
        setInvisibleNumber,
        setInvisibleNumberWithCheck,
        versionId,
        setVersionId,
        customerId,
        setCustomerId,
        customer,
        setCustomer,
        version,
        setVersion,
        metricaPO,
        setMetricaPO,
        isPlanActive,
        setIsPlanActive,
        criticDetails,
        setCriticDetails,
        isRefreshCritic,
        setIsRefreshCritic,
        isNewProductOrOnlyNew,
        setIsNewProductOrOnlyNew,
        cleanCriticDetails,
        isSave,
        setIsSave,
        isEditingAop,
        setIsEditingAop,
      }}
    >
      {children}
    </POContext.Provider>
  );
}
