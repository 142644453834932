import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

const List = styled.ul`
  background: ${({ theme }) => theme.colors.blue_700};
  border-top: 3px solid ${({ theme }) => theme.colors.blue_300};
  min-width: 230px;
  list-style: none;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  a {
    display: block;
    padding: 13px 15px;
    width: 100%;
    cursor: pointer;
    text-decoration: none;

    p {
      color: ${({ theme }) => theme.colors.white};
      font-size: 14px;
      font-weight: 500;
    }

    :hover {
      background-color: #144a6e;

      p {
        color: #d38434;
      }
    }
  }
`;

export default function NavbarNavList({ items, t }) {
  return (
    <List>
      {items.map((item, index) => (
        <NavLink key={index} to={item.href}>
          <p>{t(`${item.title}`, { ns: 'maxion' })}</p>
        </NavLink>
      ))}
    </List>
  );
}
