import React, { useContext, useState } from 'react';
import {
  Delete,
  GetApp,
  Done,
  Edit,
  List,
  Autorenew,
} from '@mui/icons-material';
import {
  Divider,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import useAuth from '../../../../../../../hooks/useAuth';
import { checkPermission } from '../../../../../../../utils/permissions';
import axios from '../../../../../../../utils/axios';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { POContext } from '../../../../../../../contexts/POContext';
import { exportToExcel } from './exportToExcel';

export default function MenuHamburguer({
  fetchVersions,
  setOpenModalEditVersion,
  setOpenModalDeleteVersion,
  setOpenModalCloneVersion,
  setOpenModalReactivateVersion,
  row,
  t,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const { version } = useContext(POContext);

  const { user } = useAuth();

  const userHasPermissionExport = user && checkPermission(user, [], [9]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFinish = async () => {
    try {
      setLoading(true);

      const body = {
        id: row.id,
        final_version: true,
      };

      await axios.put(`${process.env.REACT_APP_PUBLIC_API}po_versions`, body);

      setLoading(false);
      toast.success(t('Versão finalizada com sucesso', { ns: 'maxion' }));
      handleClose();
      fetchVersions();
    } catch (error) {
      toast.error(t('Erro ao finalizar versão', { ns: 'maxion' }));
      console.log(`Error submiting data: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      setLoadingExport(true);

      await exportToExcel(row);

      handleClose();
    } catch (error) {
      console.log(error);
      toast.error(t('Erro ao exportar versão', { ns: 'maxion' }));
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <List />
      </IconButton>

      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {user && checkPermission(user, [], [9]) ? (
          <div>
            <MenuItem
              loading={loading}
              disabled={
                row.deleted_at !== null || row.final_version === true || loading
              }
              onClick={() => handleFinish()}
            >
              <ListItemIcon>
                {!loading ? (
                  <Done fontSize="small" color="inherit" />
                ) : (
                  <CircularProgress size={15} color="inherit" />
                )}
              </ListItemIcon>
              <ListItemText>
                {t('Finalizar versão', { ns: 'maxion' })}
              </ListItemText>
            </MenuItem>

            <MenuItem
              disabled={
                row.deleted_at !== null || row.final_version === true || loading
              }
              onClick={() => {
                handleClose();
                setOpenModalEditVersion(true);
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('Editar', { ns: 'maxion' })}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenModalCloneVersion(true);
              }}
            >
              <ListItemIcon>
                <FileCopyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('Duplicar', { ns: 'maxion' })}</ListItemText>
            </MenuItem>
          </div>
        ) : null}

        {userHasPermissionExport && (
          <MenuItem disabled={loadingExport} onClick={handleExport}>
            <ListItemIcon>
              {!loadingExport ? (
                <GetApp fontSize="small" />
              ) : (
                <CircularProgress size={15} color="inherit" />
              )}
            </ListItemIcon>
            <ListItemText>{t('Exportar', { ns: 'maxion' })}</ListItemText>
          </MenuItem>
        )}

        {user &&
        checkPermission(user, [], [9]) &&
        row.final_version !== true ? (
          <div>
            <Divider />

            {row.deleted_at == null ? (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenModalDeleteVersion(true);
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('Inativar', { ns: 'maxion' })}</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenModalReactivateVersion(true);
                }}
              >
                <ListItemIcon>
                  <Autorenew fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('Reativar', { ns: 'maxion' })}</ListItemText>
              </MenuItem>
            )}
          </div>
        ) : null}
      </Menu>
    </div>
  );
}
