import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from '../../../../../../../utils/axios';

import Loading from '../../../../../../../components/Loading';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
  Dialog,
  DialogTitle as DialogTitleMUI,
  DialogContent as DialogContentMUI,
  DialogActions as DialogActionsMUI,
  DialogContentText,
  Button,
  Divider,
} from '@mui/material';

const DialogTitle = styled(DialogTitleMUI)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 12px 20px;

  svg {
    color: #d2d2d2;
    font-weight: 1000;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
`;

const DialogContent = styled(DialogContentMUI)`
  padding: 25px 20px;
`;

const DialogActions = styled(DialogActionsMUI)`
  background-color: #f6f9fc;
`;

const Input = styled.input`
  background-color: #fff !important;
  margin-right: 10px;
  padding: 6px 8px !important;
  min-width: 200px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  color: ${({ theme }) => theme.colors.gray_500};

  ::placeholder {
    color: #9a9a9a;
    font-weight: 400;
  }
`;

export default function ModalReactivateVersion({
  fetchVersions,
  openModalReactivateVersion,
  setOpenModalReactivateVersion,
  row,
  t,
}) {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [valueInput, setValueInput] = useState('');

  const key = i18n.language === 'pt-BR' ? 'REATIVAR' : 'REACTIVATE';

  function handleClose() {
    setOpenModalReactivateVersion(false);
  }

  async function handleReactivate() {
    try {
      setLoading(true);

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}po_versions/reactivate/${row.id}`
      );

      setOpenModalReactivateVersion(false);
      setLoading(false);
      toast.success(t('Versão reativada com sucesso'), { ns: 'maxion' });
      fetchVersions();
    } catch (error) {
      toast.error(t('Erro ao reativar a versão'), { ns: 'maxion' });
      console.log(`Error submiting data: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={openModalReactivateVersion}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('Reativar', { ns: 'maxion' })}

        <CloseRoundedIcon
          onClick={() => {
            setOpenModalReactivateVersion(false);
          }}
        />
      </DialogTitle>

      <Divider />

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            'Se já houver uma versão ativa, ao realizar esta operação a versão anterior será marcada como inativa',
            {
              ns: 'maxion',
            }
          )}
          <br />
          <b style={{ color: '#FF0000' }}>
            {t('Deseja confirmar essa ação?', { ns: 'maxion' })}
          </b>
        </DialogContentText>
      </DialogContent>

      <Divider />

      {loading && <Loading />}
      <DialogActions>
        <Input
          placeholder={`${t('Digite a palavra', {
            ns: 'maxion',
          })} ${key}`}
          onChange={(e) => setValueInput(e.target.value)}
          value={valueInput}
        />
        <Button
          autoFocus
          color="primary"
          disabled={loading || valueInput !== key}
          onClick={handleReactivate}
        >
          {t('Reativar', { ns: 'maxion' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
