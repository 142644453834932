//Importações
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { CacheProvider } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Material UI
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// Tradução
import './i18n';
//Theme
import createTheme from './theme';
import useTheme from './hooks/useTheme';
//Routes
import routes from './routes';
//Utils
import createEmotionCache from './utils/createEmotionCache';
//Contexts
import { AuthProvider } from './contexts/JWTContext';
import { GreetingProvider } from './contexts/GreetingContext';
import { RefreshProvider } from './contexts/RefreshContext';
import { GetUsersProvider } from './contexts/GetUsersContext';
import { FileProvider } from './contexts/FileContext';
import { CriticaInvisibleNumberProvider } from './contexts/CriticaInvisibleNumberContext';
import { POProvider } from './contexts/POContext';

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Maxion" defaultTitle="Maxion" />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>
              <GetUsersProvider>
                <GreetingProvider>
                  <POProvider>
                    <CriticaInvisibleNumberProvider>
                      <RefreshProvider>
                        <FileProvider>{content}</FileProvider>
                      </RefreshProvider>
                      <ToastContainer />
                    </CriticaInvisibleNumberProvider>
                  </POProvider>
                </GreetingProvider>
              </GetUsersProvider>
            </AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
