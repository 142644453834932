import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PoReportsProductsDetails from './POReportsProductsDetails';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextError = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

export default function ModalPoReportProductsDetails({
  t,
  openModal,
  setOpenModal,
  versionId,
  customerId,
}) {
  const [loading, setLoading] = useState(false);

  const initialValuesFormik = {
    name: '',
    observation: '',
    base_date: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${t('Campo obrigatório', { ns: 'maxion' })}`),
    observation: Yup.string(),
    base_date: Yup.string().required(
      `${t('Campo obrigatório', { ns: 'maxion' })}`
    ),
  });

  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      fullScreen
      // maxWidth="lg"
      // fullScreen
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {t('Detalhamento de produtos', { ns: 'maxion' })}
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            <CloseRoundedIcon
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <PoReportsProductsDetails
          t={t}
          versionId={versionId}
          customerId={customerId}
        />
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            marginBottom: 10,
            width: '100%',
          }}
        >
          <Button
            color="primary"
            autoFocus
            onClick={async () => {
              setOpenModal(false);
            }}
            type="button"
            variant="contained"
            style={{ width: 120 }}
          >
            {t('Voltar', { ns: 'maxion' })}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
