//Importações
import React from 'react';
import styled from '@emotion/styled';
//Material UI
import { Box as BoxMUI } from '@mui/material';

export const Box = styled(BoxMUI)`
  max-width: 1450px;
  margin: 0 auto;
`;

export default function Grid({ children }) {
  return <Box>{children}</Box>;
}
