import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Box,
} from '@mui/material';

const Chip = styled.div`
  background-color: #c6c6c6;
  padding: 3px 6px;
  border-radius: 4px;
`;

export function UnitSelect({ label, items, fieldItem, width, ...rest }) {
  return (
    <FormControl sx={{ width: width }} size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>

      <Select {...rest} label={label} disabled={!items || items.length === 0}>
        {items.length !== 0 &&
          items.map((item, index) => (
            <MenuItem key={index} value={index}>
              {item[fieldItem]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export function MultipleSelect({
  label,
  setProdutosSelected,
  items,
  fieldItem,
}) {
  const [names, setNames] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    setProdutosSelected(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 290 }} size="small">
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={names}
          onChange={handleChange}
          disabled={!items || items.length === 0}
          input={<OutlinedInput label={label} />}
        >
          {items &&
            items.length !== 0 &&
            items.map((item, index) => (
              <MenuItem key={index} value={item.sk}>
                {label === 'Produtos'
                  ? `${item[fieldItem]} - ${item.codigo_produto}`
                  : `${item[fieldItem]}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function SelectMonths({
  value,
  items,
  onChange,
  monthName,
  label,
  width,
  name = 'select',
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <FormControl sx={{ minWidth: width }} size="small">
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        name={name}
        multiple
        value={value}
        disabled={items ? items.length === 0 : true}
        onChange={onChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {selected.map((value) => {
              const split = value.split(' ');
              const abbreviation = split[0];
              const year = split[1];

              return (
                <Chip key={value}>
                  {t(abbreviation, { ns: 'maxion' })} {year}
                </Chip>
              );
            })}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {items &&
          items.map((value, index) => (
            <MenuItem
              key={index}
              value={`${value.abbreviation} ${value.year ? value.year : ''}`}
              style={getStyles(value.abbreviation, monthName, theme)}
            >
              {t(value.abbreviation, { ns: 'maxion' })}{' '}
              {value.year ? value.year : ''}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
