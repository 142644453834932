import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { POContext } from '../../../../../../contexts/POContext';
import useAuth from '../../../../../../hooks/useAuth';
import { checkPermission } from '../../../../../../utils/permissions';

import { Divider, Breadcrumbs } from '@mui/material';
import Filters from './components/Filters';
import MainContent from './components/MainContent';
import SaveCritic from './components/SaveCritic';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.gray_500};
      cursor: pointer;
      font-size: 13.5px;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

const BoxContent = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;

  h2 {
    font-size: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }

  .info-plano {
    margin: 40px 0;
    padding: 0 20px;

    .flex {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      width: 80%;
      justify-content: space-between;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
    }

    b {
      color: ${({ theme }) => theme.colors.gray_300};
    }
  }

  .observations {
    flex: 1 1 auto;
    white-space: normal;
    word-wrap: break-word;
    overflow: visible;
    min-width: 0;
  }

  .workDays-and-vehicles {
    position: relative;
    margin: 40px 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    p {
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
      margin-bottom: 10px;
    }
  }

  .customer {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 50px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.blue_200};
    }

    b {
      color: ${({ theme }) => theme.colors.gray_300};
    }
  }
`;

const POButton = styled.button`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.blue_200 : '#FFF'};
  padding: 9px 35px;
  color: ${({ isSelected, theme }) =>
    isSelected ? '#FFF' : theme.colors.gray_500};
  /* border: 1px solid ${({ theme }) => theme.colors.blue_200}; */
  border: none;
  border-radius: 4px;
  min-width: 160px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
  cursor: ${({ isSelected }) => (isSelected ? 'not-allowed' : 'pointer')};
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 15px;

  :hover {
    background-color: ${({ theme }) => theme.colors.blue_200};
    color: ${({ isSelected }) => (isSelected ? '#FFF' : '#FFF')};
  }
`;

function Index({ t }) {
  const { user } = useAuth();
  const {
    customer,
    metricaPO,
    setMetricaPO,
    setInvisibleNumberWithCheck,
    criticDetails,
    cleanCriticDetails,
  } = useContext(POContext);

  const userHasPermissionToManage = user && checkPermission(user, [], [9, 10]);

  useEffect(() => {
    if (userHasPermissionToManage) {
      cleanCriticDetails();
    }
  }, [cleanCriticDetails, userHasPermissionToManage]);

  function handleChangeMetricaPO(event) {
    const value = event.target.value;

    if (criticDetails.length > 0) {
      const text = t(
        'Há alterações não salvas. Deseja descartar as alterações e sair?',
        { ns: 'maxion' }
      );

      const confirm = window.confirm(text);

      if (confirm) {
        setMetricaPO(value);
      }

      return;
    }

    setMetricaPO(value);
  }

  return (
    <div style={{ width: '100%' }}>
      <Helmet
        title={t(
          `Plano operacional por ${metricaPO === 'value' ? 'preço' : 'volume'}`,
          { ns: 'maxion' }
        )}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '30px',
          flexWrap: 'wrap',
          marginBottom: '30px',
        }}
      >
        <POButton
          value="value"
          onClick={handleChangeMetricaPO}
          isSelected={metricaPO === 'value'}
        >
          {t('Preço', { ns: 'maxion' })}
        </POButton>

        <POButton
          value="volume"
          onClick={handleChangeMetricaPO}
          isSelected={metricaPO === 'volume'}
        >
          {t('Volume', { ns: 'maxion' })}
        </POButton>
      </div>

      <Infobar t={t}>
        <div>
          <div>
            <h2>
              {t(
                `Plano operacional por ${
                  metricaPO === 'value' ? 'preço' : 'volume'
                }`,
                { ns: 'maxion' }
              )}
            </h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

              <p>{t('Números Maxion', { ns: 'maxion' })} </p>

              <p>{t('Plano Operacional', { ns: 'maxion' })}</p>

              <button
                onClick={() => {
                  setInvisibleNumberWithCheck(0);
                }}
              >
                {t('Listagem de versões', { ns: 'maxion' })}
              </button>

              <button
                onClick={() => {
                  setInvisibleNumberWithCheck(1);
                }}
              >
                {t('Plano operacional por grupo de cliente', { ns: 'maxion' })}
              </button>

              <span>
                {t(
                  `Plano operacional por ${
                    metricaPO === 'value' ? 'preço' : 'volume'
                  }`,
                  { ns: 'maxion' }
                )}
              </span>
            </Breadcrumbs>
          </div>

          <Divider className="divider" />
        </div>
      </Infobar>

      <BoxContent>
        <h2>
          {t(
            `Plano operacional por ${
              metricaPO === 'value' ? 'preço' : 'volume'
            }`,
            { ns: 'maxion' }
          )}
        </h2>

        <div className="customer">
          <p>{t('Cliente', { ns: 'maxion' })}:</p>

          <b>{customer?.descricao_grupo_cliente}</b>
        </div>

        <Filters t={t} />
        <MainContent t={t} />
        <SaveCritic t={t} />
      </BoxContent>
    </div>
  );
}

export default withTranslation('maxion')(Index);
