import { v4 } from 'uuid';
import jwt from 'jsonwebtoken';

export function generateTokenTableau() {
  const connectedAppClientId = 'ee8a8e9f-acfe-4358-b7e6-4882884f61d4';
  const connectedAppSecretKey = 'QgCokMgdLlELpnz5qdSao/AJku6iwHDoiJIUPcM93I4=';
  const connectedAppSecretId = 'a9546729-e903-4c13-8236-17d9650dacba';

  const minutesExpiration = 5; // Original = 10 (Max permitted)

  // Defina as informações para o token JWT
  const payload = {
    iss: connectedAppClientId,
    exp: Math.floor(Date.now() / 1000) + 60 * minutesExpiration,
    jti: v4(),
    aud: 'tableau',
    sub: 'crz-tableau1@maxionsc.com',
    scp: ['tableau:views:embed'],
  };

  const token = jwt.sign(payload, connectedAppSecretKey, {
    algorithm: 'HS256',
    header: {
      kid: connectedAppSecretId,
      iss: connectedAppClientId,
    },
  });

  return token;
}
