import { Switch as SwitchMUI } from '@mui/material';
import styled from '@emotion/styled';

const SwitchClosedMonthMUI = styled(SwitchMUI)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.colors.blue_300,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.colors.blue_300,
  },

  '& .Mui-disabled': {
    color: theme.colors.blue_300,
  },
}));

export function Switch(props) {
  return <SwitchMUI {...props} />;
}

export function SwitchClosedMonth(props) {
  return <SwitchClosedMonthMUI {...props} />;
}
