const baseItems = {
  buttons: [
    {
      title: 'Pesados',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-DadosMacroeconomicos/Painel1',
      height: '800px',
    },
    {
      title: 'Leves',
      type: 'tableau',
      index: 1,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-DadosMacroeconomicos/Painel2',
      height: '800px',
    },
  ],
};

export default baseItems;
