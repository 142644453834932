import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import Infobar from '../../../components/Infobar/Infobar';

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  InputLabel,
  Paper,
  Stack,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

import axios from '../../../utils/axios';

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function UserForm({ t }) {
  /*
                DATAGRID
                */
  const columns = [
    { field: 'id', headerName: t('ID', { ns: 'maxion' }), width: 90 },
    {
      field: 'name',
      headerName: t('Nome', { ns: 'maxion' }),
      width: 150,
      editable: true,
    },
    {
      field: 'email',
      headerName: t('E-mail', { ns: 'maxion' }),
      width: 150,
      editable: true,
    },
    {
      field: 'username',
      headerName: t('Usuário', { ns: 'maxion' }),
      width: 110,
      editable: true,
    },
    {
      field: 'department',
      headerName: t('Departamento', { ns: 'maxion' }),
      width: 160,
    },
    {
      field: 'department_id',
      headerName: t('ID do departamento', { ns: 'maxion' }),
      hide: true,
    },
  ];

  /*
                  DATAGRID
                  */

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
    email: Yup.string()
      .email()
      .required(t('Campo obrigatório', { ns: 'maxion' })),
    username: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
    department_id: Yup.object().required(
      t('Campo obrigatório', { ns: 'maxion' })
    ),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let valuesSubmit = _.cloneDeep(values);
    valuesSubmit.department_id = values.department_id.value;

    setSubmitting(true);

    // Update
    if (typeof values.id != 'undefined') {
      await axios
        .patch(
          `${process.env.REACT_APP_PUBLIC_API}users/${values.id}`,
          valuesSubmit
        )
        .then((response) => {
          setStatus({ sent: true });
          setSubmitting(false);
        })
        .catch((error) => {
          console.log('Error updating user', error);
          setStatus({ sent: false });
          setErrors(error.response.data.detail);
          setSubmitting(false);
        });
      // console.log("update")
    } else {
      await axios
        .post(`${process.env.REACT_APP_PUBLIC_API}users`, valuesSubmit)
        .then((response) => {
          resetForm();
          setStatus({ sent: true });
          setSubmitting(false);
        })
        .catch((error) => {
          console.log('Error creating user', error);
          setStatus({ sent: false });
          setErrors(error.response.data.detail);
          setSubmitting(false);
        });
    }
  };

  const initialValuesBlank = {
    name: '',
    email: '',
    username: '',
    department_id: '',
    permissions: [],
    roles: [],
  };

  const [deptos, setDeptos] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [create, setCreate] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [initialValues, setInitialValues] = useState(initialValuesBlank);
  const [rowCount, setRowCount] = useState();
  const [page, setPage] = useState(0);
  const [removeItem, setRemoveItem] = useState(false);
  const [isRemovingItem, setIsRemovingItem] = useState(false);

  const pageSize = 20;

  const getUsers = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API}users?skip=${
          page * pageSize
        }&limit=${pageSize}`
      )
      .then((response) => {
        let items = [];
        _.forEach(response.data, (val) => {
          items.push({
            id: val.id,
            name: val.name,
            email: val.email,
            username: val.username,
            department: val.department.department,
            department_id: val.department.id,
          });
        });
        setUsers(items);
        setLoadingData(false);
      })
      .catch((error) => console.log('error', error));
  }, [page, pageSize]);

  const getUserById = useCallback(
    (user_id) => {
      axios
        .get(`${process.env.REACT_APP_PUBLIC_API}users/${user_id}`)
        .then((response) => {
          let usuario = response.data;
          let rolesUser = [];
          let permissionsUser = [];

          _.forEach(usuario.roles, (val) => {
            rolesUser.push({
              value: val.id,
              label: val.role,
            });
          });

          _.forEach(usuario.permissions, (val) => {
            permissionsUser.push({
              value: val.id,
              label: val.permission,
            });
          });

          setInitialValues({
            id: usuario.id,
            name: usuario.name,
            email: usuario.email,
            username: usuario.username,
            department_id: _.find(deptos, (item) => {
              return item.value === usuario.department_id;
            }),
            permissions: permissionsUser,
            roles: rolesUser,
          });

          setCreate(true);
          setLoadingData(false);
        })
        .catch((error) => console.log('Error user by ID', error));
    },
    [deptos]
  );

  const getPermissions = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}permissions`)
      .then((response) => {
        let options = [];
        _.forEach(response.data, (val) => {
          options.push({
            value: val.id,
            label: val.permission,
          });
        });

        setPermissions(options);
      })
      .catch((error) => console.log('Error permission', error));
  }, []);

  const getRoles = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}roles`)
      .then((response) => {
        let options = [];
        _.forEach(response.data, (val) => {
          options.push({
            value: val.id,
            label: val.role,
          });
        });

        setRoles(options);
      })
      .catch((error) => console.log('Error roles', error));
  }, []);

  useEffect(() => {
    // Get departments
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}departments`)
      .then((response) => {
        let deptos = [];
        _.forEach(response.data, (val) => {
          deptos.push({
            value: val.id,
            label: val.department,
          });
        });
        setDeptos(deptos);
      })
      .catch((error) => console.log('error', error));

    // Get users count
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}users/count`)
      .then((response) => {
        setRowCount(response.data);
      })
      .catch((error) => console.log('Error counting', error));

    // Get users
    getUsers();
    // Get permissions
    getPermissions();
    // Get roles
    getRoles();
  }, [getUsers, getPermissions, getRoles]);

  useEffect(() => {
    getUsers();
  }, [getUsers, page, pageSize]);

  const [rowCountState, setRowCountState] = React.useState(0);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  if (!create) {
    return (
      <Box sx={{ height: 500, width: '100%' }}>
        <Stack direction="row">
          <Button
            type="button"
            variant="contained"
            color="primary"
            mb={3}
            onClick={() => {
              setInitialValues(initialValuesBlank);
              setCreate(true);
            }}
          >
            {t('Novo usuário', { ns: 'maxion' })}
          </Button>
        </Stack>
        <DataGrid
          rows={users}
          columns={columns}
          onRowClick={(params, event) => {
            getUserById(params.row.id);
            setLoadingData(true);
          }}
          pagination
          loading={loadingData}
          paginationMode="server"
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          onPageChange={(newPage) => {
            setLoadingData(true);
            setPage(newPage);
          }}
          page={page}
          rowCount={rowCountState}
          keepNonExistentRowsSelected
        />
      </Box>
    );
  } else {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
          setFieldValue,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Paper>
                {values.id && !isRemovingItem && (
                  <Button
                    color="error"
                    variant="contained"
                    style={{ marginBottom: 20 }}
                    onClick={() => setRemoveItem(true)}
                  >
                    {t('Remover', { ns: 'maxion' })}
                  </Button>
                )}
                <Dialog
                  open={removeItem}
                  onClose={() => {
                    setRemoveItem(false);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t('Deseja remover o item?', { ns: 'maxion' })}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t(
                        'Clique em Confirmar para remover o item ou em Cancelar para voltar',
                        { ns: 'maxion' }
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setRemoveItem(false)}
                      color="primary"
                    >
                      {t('Cancelar', { ns: 'maxion' })}
                    </Button>
                    <Button
                      onClick={() => {
                        setIsRemovingItem(true);
                        setRemoveItem(false);

                        axios
                          .delete(
                            `${process.env.REACT_APP_PUBLIC_API}users/${values.id}`
                          )
                          .then((response) => {
                            setInitialValues(initialValuesBlank);
                            setCreate(false);
                          })
                          .catch((error) =>
                            console.log('Error deleting user', error)
                          );
                      }}
                      color="primary"
                      autoFocus
                    >
                      {t('Confirmar', { ns: 'maxion' })}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  {t('Dados enviados com sucesso', { ns: 'maxion' })}
                  <Button
                    onClick={() => {
                      setInitialValues(initialValuesBlank);
                      setCreate(false);
                    }}
                  >
                    {t('Voltar', { ns: 'maxion' })}
                  </Button>
                </Alert>
              )}

              {isSubmitting || isRemovingItem ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <FormGroup fullWidth style={{ marginBottom: 8 }}>
                    <InputLabel shrink id="select-departamento-label">
                      {t('Departamento', { ns: 'maxion' })}
                    </InputLabel>
                    <Select
                      value={values.department_id}
                      onChange={(option) => {
                        // console.log("option", option)
                        setFieldValue('department_id', option);
                      }}
                      menuPortalTarget={document.body}
                      options={deptos}
                      placeholder={t('Departamento', { ns: 'maxion' })}
                      loadingMessage={() => {
                        t('Carregando', { ns: 'maxion' });
                      }}
                      // isLoading={field.isLoading}
                      // className={errors[field.name] && status == 'submitted' ? 'is-invalid' : ''}
                      name={'dapartment_id'}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </FormGroup>
                  <TextField
                    name="name"
                    label={t('Nome', { ns: 'maxion' })}
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="username"
                    label={t('Usuário', { ns: 'maxion' })}
                    value={values.username}
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />

                  <TextField
                    name="email"
                    label={t('E-mail', { ns: 'maxion' })}
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                  />

                  <FormGroup fullWidth>
                    <InputLabel shrink id="select-papeis-label">
                      {t('Papéis', { ns: 'maxion' })}
                    </InputLabel>
                    <Select
                      value={values.roles}
                      menuPortalTarget={document.body}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      onChange={(option) => {
                        setFieldValue('roles', option);
                      }}
                      isMulti
                      options={roles}
                      placeholder={t('Papéis', { ns: 'maxion' })}
                      loadingMessage={() => {
                        t('Carregando', { ns: 'maxion' });
                      }}
                      // isLoading={field.isLoading}
                      // className={errors[field.name] && status == 'submitted' ? 'is-invalid' : ''}
                      name={'roles'}
                    />
                  </FormGroup>

                  {/* <FormGroup fullWidth style={{ marginTop: 10 }}>
                    <InputLabel shrink id="select-permissoes-label">{t('usuario.permissoes', { ns: 'maxion' })}</InputLabel>
                    <Select
                      value={
                        values.permissions
                      }
                      onChange={option => {
                        setFieldValue('permissions', option);
                      }}
                      isMulti
                      options={permissions}
                      placeholder={t('usuario.permissoes', { ns: 'maxion' })}
                      loadingMessage={() => { t('carregando', { ns: 'maxion' }) }}
                      // isLoading={field.isLoading}
                      // className={errors[field.name] && status == 'submitted' ? 'is-invalid' : ''}
                      name={"permissions"}
                      menuPortalTarget={document.body}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                    />
                  </FormGroup> */}

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        setInitialValues(initialValuesBlank);
                        setCreate(false);
                      }}
                      disabled={status && status.sent}
                    >
                      {t('Cancelar', { ns: 'maxion' })}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={(status && status.sent) || isSubmitting}
                    >
                      {t('Salvar', { ns: 'maxion' })}
                    </Button>
                  </Stack>
                  {/* <Paper style={{ marginTop: 30 }}>
                    <pre>
                      {JSON.stringify(values, null, 4)}
                    </pre>
                    <pre>
                      {JSON.stringify(errors, null, '\t')}
                    </pre>
                  </Paper> */}
                </form>
              )}
            </CardContent>
          </Card>
        )}
      </Formik>
    );
  }
}

function ImporterPage({ t, tReady }) {
  const [old, setOld] = useState(null);

  useEffect(() => {
    if (old === null && old !== 'Usuários') {
      async function setUsersNavigationLog(textRoute) {
        try {
          const route = `OpenMax / Administrativo / ${textRoute}`;

          await axios.post(
            `${process.env.REACT_APP_PUBLIC_API}users_navigation_log`,
            {
              route: route,
            }
          );
        } catch (error) {
          console.log(error);
        }
      }

      setUsersNavigationLog('Usuários');
      setOld('Usuários');
    }
  }, [old]);

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      {tReady && (
        <React.Fragment
          style={{
            height: '100%',
          }}
        >
          <iframe
            title={'iframe'}
            src="http://localhost:8080/dashboard/#/base/a43da322-76e6-4c0c-86bd-5c6d5f047764"
            width="100%"
            style={{
              border: 'none',
              height: '100%',
            }}
          ></iframe>
        </React.Fragment>
      )}
    </div>
  );
}

export default withTranslation(['admin', 'maxion', 'common', 'titles'])(
  ImporterPage
);
