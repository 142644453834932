//Importações
import React from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';
//Icons
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
//Components
import NavbarNavList from './NavbarNavList';

const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.cyan};
  cursor: pointer;
  font-size: 17px;

  svg {
    width: 28px;
    height: 28px;
    color: #cd923b;
  }
`;

function NavBarMenuSection({ pages, title, t }) {
  return (
    <>
      {pages.map((item, index) => (
        <HoverCard.Root key={index} openDelay={10} closeDelay={200}>
          <HoverCard.Trigger>
            <Card>
              <item.icon />
              {t(`${title}`, { ns: 'maxion' })}
              <KeyboardArrowDownOutlinedIcon />
            </Card>
          </HoverCard.Trigger>
          <HoverCard.Portal>
            <HoverCard.Content sideOffset={10}>
              <NavbarNavList items={item.children} t={t} />
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      ))}
    </>
  );
}

export default withTranslation('maxion')(NavBarMenuSection);
