import React from 'react';
import styled from '@emotion/styled';
import { Tooltip, IconButton, CircularProgress } from '@mui/material';
import {
  CheckCircle as FinishedIcon,
  Error as PendingIcon,
} from '@mui/icons-material';

const BoxOngoingIcon = styled.div`
  background-color: #ffa500;
  width: 1em;
  height: 1em;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

function OngoingIcon() {
  return (
    <BoxOngoingIcon>
      <CircularProgress
        variant="determinate"
        value={65}
        size={14}
        style={{ color: '#FFF' }}
        thickness={4.5}
      />
    </BoxOngoingIcon>
  );
}

export default function Semaphore({ row, currentField, t }) {
  function getLabelStatus(status) {
    return status === 'finished'
      ? t('Finalizado', { ns: 'maxion' })
      : status === 'ongoing'
      ? t('Em andamento', { ns: 'maxion' })
      : t('Pendente', { ns: 'maxion' });
  }

  function getIcon() {
    let icon = null;

    switch (currentField) {
      case 'calendar':
      case 'segmentation':
        if (row.calendar === 'finished' && row.segmentation === 'finished') {
          icon = <FinishedIcon style={{ color: '#76BE56' }} />;
        } else if (
          row.calendar === 'ongoing' ||
          row.segmentation === 'ongoing'
        ) {
          icon = <OngoingIcon />;
        } else {
          icon = <PendingIcon style={{ color: '#8B0000' }} />;
        }
        break;
      case 'new_product':
        icon =
          row[currentField] === 'finished' ? (
            <FinishedIcon style={{ color: '#76BE56' }} />
          ) : row[currentField] === 'ongoing' ? (
            <OngoingIcon />
          ) : (
            <PendingIcon style={{ color: '#828282' }} />
          );
        break;
      case 'aop':
      case 'operational_plan_value':
      case 'operational_plan_volume':
        icon =
          row[currentField] === 'finished' ? (
            <FinishedIcon style={{ color: '#76BE56' }} />
          ) : row[currentField] === 'ongoing' ? (
            <OngoingIcon />
          ) : (
            <PendingIcon style={{ color: '#8B0000' }} />
          );
        break;
      default:
        icon = <PendingIcon style={{ color: '#000' }} />;
        break;
    }
    return icon;
  }

  function getTitle() {
    let depends = '';
    let status = '';

    switch (currentField) {
      case 'calendar':
      case 'segmentation':
        status = getLabelStatus(
          row.calendar === 'finished' && row.segmentation === 'finished'
            ? 'finished'
            : row.calendar === 'ongoing' || row.segmentation === 'ongoing'
            ? 'ongoing'
            : 'pending'
        );
        break;
      case 'new_product':
        status = getLabelStatus(row[currentField]);
        break;
      case 'aop':
        status = getLabelStatus(row[currentField]);
        const aopFieldsRequired = ['calendar', 'segmentation'];
        const aopIsAllFinished = aopFieldsRequired.every(
          (field) => row[field] === 'finished'
        );
        const aopItemFinished = row[currentField] === 'finished';
        depends =
          aopIsAllFinished || aopItemFinished
            ? ''
            : t('depende do item "Dias úteis / Veiculos"', { ns: 'maxion' });
        break;

      case 'operational_plan_value':
      case 'operational_plan_volume':
        status = getLabelStatus(row[currentField]);
        const planFieldsRequired = ['aop'];
        const planIsAllFinished = planFieldsRequired.every(
          (field) => row[field] === 'finished'
        );
        const planItemFinished = row[currentField] === 'finished';
        depends =
          planIsAllFinished || planItemFinished
            ? ''
            : t('depende do item "Mercado"', { ns: 'maxion' });
        break;

      default:
        status = t('Algo deu errado. Tente novamente mais tarde.', {
          ns: 'maxion',
        });
        break;
    }

    return depends === '' ? status : `${status} (${depends})`;
  }

  return (
    <Tooltip title={getTitle()}>
      <IconButton>{getIcon(row[currentField])}</IconButton>
    </Tooltip>
  );
}
