import React, { useState, useEffect, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import axios from '../../../../../../utils/axios';
import { POContext } from '../../../../../../contexts/POContext';
import useAuth from '../../../../../../hooks/useAuth';
import { checkPermission } from '../../../../../../utils/permissions';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Divider, Breadcrumbs, Button, CircularProgress } from '@mui/material';
import SelectAsync from '../../../../../../components/UnitSelectAsync';
import Table from './components/Table';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.gray_500};
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

const BoxContent = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;

  h2 {
    font-size: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }

  .info-plano {
    margin: 40px 0;
    padding-left: 20px;

    .flex {
      display: flex;
      flex-wrap: wrap;
      gap: 100px;
      margin-bottom: 20px;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
    }

    b {
      color: ${({ theme }) => theme.colors.gray_300};
    }
  }

  .observations {
    flex: 1 1 auto;
    white-space: normal;
    word-wrap: break-word;
    overflow: visible;
    min-width: 0;
  }

  .add-customers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
  }
`;

const TextError = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.error};
  font-size: 13px;
  margin-top: 3px;
  margin-left: 3px;
`;

const formikInitialValues = {
  customers: [],
};

function AsyncText({ loading, children }) {
  return loading ? (
    <div style={{ padding: '3px' }}>
      <CircularProgress size={10} />
    </div>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
}

function POGrupoCliente({ t }) {
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [tableItems, setTableItems] = useState([]);
  const [loadingTableItems, setLoadingTableItems] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [version, setVersion] = useState({});
  const [isPlanActive, setIsPlanActive] = useState(false);
  const [loadingFetchVersion, setLoadingFetchVersion] = useState(true);

  const {
    versionId,
    setVersionId,
    setInvisibleNumber,
    setIsPlanActive: setContextIsPlanActive,
  } = useContext(POContext);
  const { user } = useAuth();

  const userHasPermissionToAddCustomers =
    user && checkPermission(user, [], [9, 10]);
  const isDisabledAddCustomers =
    !userHasPermissionToAddCustomers || !isPlanActive;

  async function fetchCustomers() {
    try {
      setLoadingCustomers(true);

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}criticas/grupo_cliente`,
        {
          search: '',
        }
      );

      setCustomers(data);
      setLoadingCustomers(false);
    } catch (error) {
      console.log(`Error fetching customers:  ${error}`);
      setLoadingCustomers(false);
    }
  }

  const fetchInfosVersion = useCallback(async () => {
    try {
      setLoadingFetchVersion(true);

      const { data: version } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_versions/${versionId}`
      );

      if (!version.final_version && version.deleted_at === null) {
        setIsPlanActive(true);
        setContextIsPlanActive(true);
        toast.info(t('Plano operacional ativo', { ns: 'maxion' }));
      } else {
        toast.info(t('Plano operacional inativo', { ns: 'maxion' }));
        setIsPlanActive(false);
        setContextIsPlanActive(false);
      }

      const splitBaseDate = version.base_date.split('-');
      const yearNumber = parseInt(splitBaseDate[0]);
      const monthNumber = parseInt(splitBaseDate[1]);
      const prevision = 12 - monthNumber;
      const base = `${monthNumber}+${prevision}`;

      const newObj = {
        ...version,
        base: base,
        year: yearNumber + 1,
      };

      setVersion(newObj);
      setLoadingFetchVersion(false);
    } catch (error) {
      console.log(error);
      setLoadingFetchVersion(true);
      toast.error(t('Erro ao buscar versão', { ns: 'maxion' }));
    }
  }, [t, versionId]);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchTableItems = useCallback(async () => {
    try {
      setLoadingTableItems(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_client_group_control?version_id=${versionId}`
      );

      setTableItems(data);
      setLoadingTableItems(false);
    } catch (error) {
      console.log(error);
      setLoadingTableItems(false);
    }
  }, [versionId]);

  useEffect(() => {
    if (versionId) {
      Promise.all([fetchTableItems(), fetchInfosVersion()]);
    }
  }, [fetchTableItems, fetchInfosVersion, versionId]);

  async function handleSubmitCustomers(values) {
    setLoadingSubmit(true);

    const skClient = values.customers.map((item) => item.sk);
    try {
      await Promise.all(
        skClient.map(async (sk, index) => {
          const body = {
            version_id: versionId,
            sk_grupo_cliente: sk,
            calendar: 'pending',
            segmentation: 'pending',
            new_product: 'pending',
            aop: 'pending',
            operational_plan_value: 'pending',
            operational_plan_volume: 'pending',
          };
          try {
            await axios.post(
              `${process.env.REACT_APP_PUBLIC_API}po_client_group_control`,
              body
            );

            console.log(values.customers);

            toast.success(
              `${values.customers[index].descricao_grupo_cliente} ${t(
                'adicionado com sucesso',
                { ns: 'maxion' }
              )}`
            );
          } catch (error) {
            console.log(error);

            let errorMessage;

            if (
              error?.response?.data?.detail ===
              t('Cliente já existe e está ativo nesta versão.')
            ) {
              errorMessage = `${
                values.customers[index].descricao_grupo_cliente
              } ${t('já existe e está ativo nesta versão')}`;
            } else {
              errorMessage = `${t('Erro ao adicionar', { ns: 'maxion' })} ${
                values.customers[index].descricao_grupo_cliente
              }`;
            }

            toast.error(errorMessage);
          }
        })
      );
      fetchTableItems();
    } catch (error) {
      console.error('Erro ao processar clientes:', error);
    } finally {
      setLoadingSubmit(false);
    }
  }

  const formikValidationSchema = Yup.object().shape({
    customers: Yup.array()
      .min(1, t('Selecione pelo menos um cliente', { ns: 'maxion' }))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: handleSubmitCustomers,
  });

  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit } =
    formik;

  return (
    <div style={{ width: '100%' }}>
      <Helmet
        title={t('Plano operacional por grupo cliente', { ns: 'maxion' })}
      />

      <Infobar t={t}>
        <div>
          <div>
            <h2>
              {t('Plano operacional por grupo de cliente', { ns: 'maxion' })}
            </h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

              <p>{t('Números Maxion', { ns: 'maxion' })} </p>

              <p>{t('Plano Operacional', { ns: 'maxion' })}</p>

              <button
                onClick={() => {
                  setInvisibleNumber(0);
                  setVersionId(null);
                }}
              >
                {t('Listagem de versões', { ns: 'maxion' })}
              </button>

              <p>
                {t('Plano operacional por grupo de cliente', { ns: 'maxion' })}
              </p>

              <AsyncText loading={loadingFetchVersion}>
                <span>{version.name}</span>
              </AsyncText>
            </Breadcrumbs>
          </div>

          <Divider className="divider" />
        </div>
      </Infobar>

      <BoxContent>
        <h2>{t('Plano operacional', { ns: 'maxion' })}</h2>

        <div className="info-plano">
          <div className="flex">
            <div className="column">
              <div className="item">
                <p>{t('Base', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b>{version.base}</b>
                </AsyncText>
              </div>

              <div className="item">
                <p>{t('Nome da versão', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b>{version.name}</b>
                </AsyncText>
              </div>
            </div>

            <div className="column">
              <div className="item">
                <p>{t('Status', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b
                    style={{
                      color: version.final_version
                        ? '#0caa35'
                        : version.deleted_at === null
                        ? '#4782da'
                        : '#ed6c02',
                    }}
                  >
                    {version.final_version
                      ? t('Versão final', { ns: 'maxion' }).toUpperCase()
                      : version.deleted_at === null
                      ? t('Ativo', { ns: 'maxion' }).toUpperCase()
                      : t('Inativo', { ns: 'maxion' }).toUpperCase()}
                  </b>
                </AsyncText>
              </div>

              <div className="item">
                <p>{t('Ano', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b>{version.year}</b>
                </AsyncText>
              </div>
            </div>
          </div>

          <div className="item">
            <p>{t('Observações', { ns: 'maxion' })}:</p>

            <AsyncText loading={loadingFetchVersion}>
              <b className="observations">{version.observations}</b>
            </AsyncText>
          </div>

          <div className="add-customers item">
            <p>{t('Adicionar clientes', { ns: 'maxion' })}</p>

            <div>
              <SelectAsync
                disabled={isDisabledAddCustomers}
                sx={{ minWidth: 350 }}
                multiple
                limitTags={0}
                size="small"
                name="customers"
                value={values.customers === '' ? null : values.customers}
                label={t('Clientes', { ns: 'maxion' })}
                loading={loadingCustomers}
                loadingText={t('Buscando clientes', { ns: 'maxion' })}
                options={customers}
                getOptionLabel={(option) => option.descricao_grupo_cliente}
                onBlur={handleBlur}
                onChange={(event, value) => {
                  setFieldValue('customers', value);
                }}
                isOptionEqualToValue={(option, value) => option.sk === value.sk}
                disableCloseOnSelect
              />

              {errors.customers && touched.customers && (
                <TextError>{errors.customers}</TextError>
              )}
            </div>

            <Button
              sx={{ height: '40px', width: '140px' }}
              variant="contained"
              disabled={loadingSubmit || isDisabledAddCustomers}
              type="submit"
              onClick={handleSubmit}
            >
              {!loadingSubmit ? (
                t('Adicionar', { ns: 'maxion' })
              ) : (
                <CircularProgress size={16} />
              )}
            </Button>
          </div>
        </div>

        <div style={{ margin: '60px 0' }}>
          <Divider />
        </div>

        <Table
          t={t}
          version={version}
          items={tableItems}
          isPlanActive={isPlanActive}
          loadingItems={loadingTableItems}
          fetchTableItems={fetchTableItems}
        />
      </BoxContent>
    </div>
  );
}

export default withTranslation('maxion')(POGrupoCliente);
