import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';
import useAuth from '../../../../../../../hooks/useAuth';
import { POContext } from '../../../../../../../contexts/POContext';
import { checkPermission } from '../../../../../../../utils/permissions';
import { useMaskito } from '@maskito/react';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { Tooltip, Switch } from '@mui/material';

const Overflow = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;

const BoxTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-width: 1050px;

  .title {
    background-color: #c2ddf0;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue_300};
    height: 40px;
    line-height: 40px;
    width: 100%;
    font-size: 15px;
  }

  .column {
    width: 10%;

    .item {
      padding-left: 10px;
      justify-content: left;
    }
  }

  .input {
    width: 100%;
    height: 35px;
    text-align: center;
    border: none;
    font-size: 14px;
    background-color: #c2ddf0;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
    font-size: 14px;
  }

  .inside-table {
    width: 100%;
    flex-direction: column;

    .total-veic {
      min-width: 90px !important;
    }

    .months {
      display: flex;
      width: 100%;

      .item {
        background-color: #c2ddf0;
        color: ${({ theme }) => theme.colors.blue_300};
        height: 40px;
        line-height: 40px;
        text-align: center;
        flex: 1;
      }
    }

    .values {
      display: flex;
      flex-direction: column;
      width: 100%;

      .row {
        display: flex;
        width: 100%;
      }
    }
  }
`;

const SwitchVeicOrJpd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
`;

const months = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
];

const numberOptions = {
  precision: 2,
  decimalSeparator: ',',
  thousandSeparator: '.',
};

const maskitoNumberOptions = maskitoNumberOptionsGenerator(numberOptions);

function formatNumber(number) {
  if (number === null || number === undefined || isNaN(number)) {
    return '*';
  }

  const maskitoValue = new Intl.NumberFormat(
    'pt-BR',
    maskitoNumberOptions
  ).format(number);

  if (Math.abs(number) >= 1000000) {
    let formattedNumber;
    let tooltipTitle;

    if (number >= 1000000000 || number <= -1000000000) {
      formattedNumber =
        (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
      tooltipTitle = maskitoValue;
    } else {
      formattedNumber = (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      tooltipTitle = maskitoValue;
    }

    return (
      <Tooltip title={tooltipTitle}>
        <span style={{ cursor: 'default' }}>{formattedNumber}</span>
      </Tooltip>
    );
  }

  return maskitoValue;
}

function InputNumberMask({ ...rest }) {
  const inputRef = useMaskito({ options: maskitoNumberOptions });

  return <input {...rest} ref={inputRef} />;
}

export default function TableCalculationDistribuition({
  t,
  segments,
  data,
  setData,
  isPlanActive,
  verifyQuantityDistribution,
  calculateJpd,
}) {
  const [inputOfField, setInputOfField] = useState(null);
  const [checked, setChecked] = useState(false);

  const { customer } = useContext(POContext);
  const { user } = useAuth();

  const finishedAop = customer && customer.aop === 'finished';
  const userHasPermissionToManage = user && checkPermission(user, [], [9]);
  const isDisabledManage =
    !userHasPermissionToManage || !isPlanActive || finishedAop;

  const isVeicOrJpd = checked ? 'jpd' : 'quantity_distribution';

  function verifyInputOfField(segment, month) {
    return inputOfField?.segment === segment && inputOfField?.month === month;
  }

  function handleClick(segment, month) {
    setInputOfField({ segment, month });
  }

  function handleBlur(segment, month, value) {
    setInputOfField(null);
    if (value === '') return;

    const valueWithStandardHyphen = value.replace(/−/g, '-');
    const valueWithoutThousandSeparator = valueWithStandardHyphen.replace(
      /\./g,
      ''
    );
    const valueWithDot = valueWithoutThousandSeparator.replace(',', '.');
    const numberValue = parseFloat(valueWithDot);

    const newData = _.cloneDeep(data);
    const segmentData = newData[segment];
    segmentData[isVeicOrJpd][month] = numberValue;

    segmentData.jpd = calculateJpd(segmentData);
    segmentData.total_quantity_distribution =
      segmentData.quantity_distribution.reduce(
        (total, value) => total + (value || 0),
        0
      );

    verifyQuantityDistribution(newData);
    setData(newData);
  }

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <SwitchVeicOrJpd>
        <p>{t('Veículos', { ns: 'maxion' })}</p>
        <Switch
          checked={checked}
          onChange={() => setChecked(!checked)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <p>{t('JPD', { ns: 'maxion' })}</p>
      </SwitchVeicOrJpd>

      <Overflow>
        <BoxTable>
          <div className="title">
            {t('Distribuição', { ns: 'maxion' }).toUpperCase()}
          </div>

          <div style={{ display: 'flex', width: '100%' }}>
            <div className="column">
              <div className="title">{t('Segmento', { ns: 'maxion' })}</div>
              {segments.map((segment, index) => (
                <div key={index} className="item">
                  {t(segment.label, { ns: 'maxion' })}
                </div>
              ))}
            </div>

            <div className="inside-table">
              <div className="months">
                <div className="item total-veic">
                  {t('Total', { ns: 'maxion' })}
                </div>

                {months.map((month, index) => (
                  <div key={index} className="item">
                    {t(month, { ns: 'maxion' })}
                  </div>
                ))}
              </div>

              <div className="values">
                {segments.map((segment, index) => (
                  <div key={index} className="row">
                    <div className="item total-veic">
                      {formatNumber(
                        data?.[segment.key]?.['total_quantity_distribution']
                      )}
                    </div>

                    {months.map((month, indexMonth) => {
                      const plan = data?.[segment.key]?.quantity_plan;
                      const value =
                        data?.[segment.key]?.[isVeicOrJpd]?.[indexMonth];
                      const isCanEdit =
                        Boolean(plan) &&
                        !isDisabledManage &&
                        isVeicOrJpd !== 'jpd' &&
                        formatNumber(value) !== '*';

                      return (
                        <div
                          key={indexMonth}
                          style={{
                            width: '100%',
                            background: '#C2DDF0',
                          }}
                        >
                          {verifyInputOfField(segment.key, indexMonth) ? (
                            <InputNumberMask
                              autoFocus
                              className="input"
                              defaultValue={formatNumber(value)}
                              onBlur={(e) => {
                                handleBlur(
                                  segment.key,
                                  indexMonth,
                                  e.target.value
                                );
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleBlur(
                                    segment.key,
                                    indexMonth,
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          ) : (
                            <div
                              className="item"
                              style={{
                                cursor: isCanEdit ? 'pointer' : 'auto',
                              }}
                              onClick={() => {
                                if (isCanEdit) {
                                  handleClick(segment.key, indexMonth);
                                }
                              }}
                            >
                              {formatNumber(value)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </BoxTable>
      </Overflow>
    </div>
  );
}
