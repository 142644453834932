//Importações
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
//Components
import Infobar from '../../../../components/Infobar/Infobar';
import ButtonsActions from './ButtonsActions';
import baseItems from './baseItems.js';
import Content from './Content';
import { withTranslation } from 'react-i18next';

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

function MapeamentoMercado({ t }) {
  const [invisibleNumber, setInvisibleNumber] = useState(0);

  return (
    <Box>
      <Helmet title={t('Mapeamento Mercado', { ns: 'maxion' })} />

      {baseItems.buttons.length > 1 && (
        <ButtonsActions
          t={t}
          buttonInfos={baseItems.buttons}
          invisibleNumber={invisibleNumber}
          setInvisibleNumber={setInvisibleNumber}
        />
      )}

      <Infobar
        t={t}
        title={t('Mapeamento Mercado', { ns: 'maxion' })}
        links={[
          {
            name: t('Maxion X Mercado', { ns: 'maxion' }),
            href: '/maxion-marketplace/market-mapping',
          },
        ]}
        text={t('Mapeamento Mercado', { ns: 'maxion' })}
      />

      <Content
        baseInfos={baseItems.buttons}
        invisibleNumber={invisibleNumber}
      />
    </Box>
  );
}

export default withTranslation('maxion')(MapeamentoMercado);
