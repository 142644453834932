import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { POContext } from '../../../../../../../contexts/POContext';
import Loading from '../../../../../../../components/Loading';
import ErrorIcon from '@mui/icons-material/Error';
import { Tooltip, IconButton } from '@mui/material';
import { useNavBlock } from '../../../../../../../hooks/useNavBlock';
import HoverCard from './HoverCard';

const Box = styled.div`
  margin-top: 50px;

  h2 {
    margin-bottom: 20px;
  }
`;

const Overflow = styled.div`
  position: relative;
  overflow-x: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 1550px;

  thead {
    background-color: #c8dcee;
  }

  th {
    padding: 10px;
    color: #0783c2;
    text-align: center;
    font-size: 14px;
  }

  td {
    padding: 10px;
    color: ${({ theme }) => theme.colors.gray_500};
    font-size: 14px;
    text-align: center;
    min-height: 49px;
  }

  .row-light-blue {
    background-color: #f4f8fc;
  }

  .align-left {
    display: flex;
    align-items: center;
    text-align: left;

    span {
      display: inline-block;
      min-width: 230px;
    }
  }

  .changed {
    color: #4c8bc9 !important;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  button {
    background-color: transparent;
    border: 1px solid #3182ce;
    border-radius: 6px;
    color: #3182ce;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover:not(:disabled) {
      background-color: #3182ce;
      color: #fff;
    }

    &:disabled {
      pointer-events: none;
    }

    &.active {
      background-color: #3182ce;
      color: #fff;
      pointer-events: none;
    }
  }
`;

const ITEMS_PER_PAGE = 10;
const MAX_PAGE_BUTTONS = 8;

const colsVolume = [
  { label: 'Produto', width: '20%' },
  { label: 'Total Reajustado', width: '8%' },
  { label: 'JAN', width: '6%' },
  { label: 'FEV', width: '6%' },
  { label: 'MAR', width: '6%' },
  { label: 'ABR', width: '6%' },
  { label: 'MAI', width: '6%' },
  { label: 'JUN', width: '6%' },
  { label: 'JUL', width: '6%' },
  { label: 'AGO', width: '6%' },
  { label: 'SET', width: '6%' },
  { label: 'OUT', width: '6%' },
  { label: 'NOV', width: '6%' },
  { label: 'DEZ', width: '6%' },
];

const colsPreco = [
  { label: 'Produto', width: '16%' },
  { label: 'JAN', width: '7%' },
  { label: 'FEV', width: '7%' },
  { label: 'MAR', width: '7%' },
  { label: 'ABR', width: '7%' },
  { label: 'MAI', width: '7%' },
  { label: 'JUN', width: '7%' },
  { label: 'JUL', width: '7%' },
  { label: 'AGO', width: '7%' },
  { label: 'SET', width: '7%' },
  { label: 'OUT', width: '7%' },
  { label: 'NOV', width: '7%' },
  { label: 'DEZ', width: '7%' },
];

const numberOptions = {
  maximumFractionDigits: 2,
  style: 'decimal',
};

function formatNumber(number) {
  if (number === null || number === undefined || isNaN(number)) {
    return '*';
  }

  const maskitoValue = new Intl.NumberFormat('pt-BR', numberOptions).format(
    number
  );

  if (Math.abs(number) >= 1000000) {
    let formattedNumber;
    let tooltipTitle;

    if (number >= 1000000000 || number <= -1000000000) {
      formattedNumber =
        (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
      tooltipTitle = maskitoValue;
    } else {
      formattedNumber = (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      tooltipTitle = maskitoValue;
    }

    return (
      <Tooltip title={tooltipTitle}>
        <span style={{ cursor: 'default' }}>{formattedNumber}</span>
      </Tooltip>
    );
  }

  return maskitoValue;
}

function formatString(str, comprimentoMaximo) {
  if (str.length <= comprimentoMaximo) {
    return str;
  } else {
    const subString = str.substring(0, comprimentoMaximo) + '...';

    return (
      <Tooltip title={str}>
        <span style={{ cursor: 'default' }}>{subString}</span>
      </Tooltip>
    );
  }
}

export default function ReadjustmentApplied({ data, loadingData, t }) {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  const { metricaPO, criticDetails } = useContext(POContext);
  const cols = metricaPO === 'volume' ? colsVolume : colsPreco;

  useNavBlock(criticDetails.length > 0);

  function handlePageClick(event) {
    const nextPage = parseInt(event.target.textContent);
    setCurrentPage(nextPage);
  }

  const validCurrentPage = Math.min(currentPage, totalPages);

  const pageData = data.slice(
    (validCurrentPage - 1) * ITEMS_PER_PAGE,
    validCurrentPage * ITEMS_PER_PAGE
  );

  const renderPageButtons = () => {
    let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_BUTTONS / 2));
    let endPage = Math.min(totalPages, startPage + MAX_PAGE_BUTTONS - 1);

    const pageButtons = [];

    if (startPage > 1) {
      pageButtons.push(
        <button onClick={() => setCurrentPage(currentPage - MAX_PAGE_BUTTONS)}>
          ...
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={handlePageClick}
          disabled={i === currentPage}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      pageButtons.push(
        <button
          key={pageButtons.length + 1}
          onClick={() => setCurrentPage(currentPage + MAX_PAGE_BUTTONS)}
        >
          ...
        </button>
      );
    }

    return pageButtons.length > 1 ? pageButtons : null;
  };

  function calculateDiference(product) {
    const totalReajuste = product.reajusteTotal;
    const totalMercado = product.volume_total_market;

    if (
      totalMercado == null ||
      totalMercado === undefined ||
      totalMercado === 0
    ) {
      return null;
    }

    const difference = Math.abs(totalReajuste - totalMercado);
    const percentageDifference = (difference / totalMercado) * 100;
    const percentageAbs = Math.abs(percentageDifference);

    if (percentageAbs > 10) {
      const message = `Total Reajustado: ${totalReajuste}, Total Mercado: ${totalMercado}, Diferença: ${difference}`;

      return (
        <Tooltip title={message}>
          <IconButton size="small">
            <ErrorIcon color="warning" fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    }
  }

  return (
    <Box>
      <h2>{t('Reajuste aplicado', { ns: 'maxion' })}</h2>

      <Overflow>
        <Table>
          <thead>
            <tr>
              {cols.map((column, index) => (
                <th
                  key={index}
                  scope="col"
                  className={index === 0 ? 'align-left' : ''}
                  style={{ width: column.width }}
                >
                  {t(`${column.label}`, { ns: 'maxion' })}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {pageData.map((product, indexProduct) => (
              <tr
                key={indexProduct}
                className={indexProduct % 2 !== 0 ? 'row-light-blue' : ''}
              >
                <td className="align-left">
                  <HoverCard
                    client={`${product.nome_cliente} - ${product.codigo_cliente}`}
                    product={product.descricao_produto}
                    t={t}
                  />
                  {metricaPO === 'volume' && calculateDiference(product)}
                </td>

                {metricaPO === 'volume' && (
                  <td className="changed">
                    {formatNumber(product?.reajusteTotal)}
                  </td>
                )}

                {Array.from({ length: 12 }).map((_, indexValue) => (
                  <td
                    key={indexValue}
                    className={
                      product?.reajusteIndexMonths?.includes(indexValue)
                        ? 'changed'
                        : ''
                    }
                  >
                    {formatNumber(product?.reajusteValues?.[indexValue])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        {loadingData && <Loading />}
      </Overflow>
      <Pagination>{renderPageButtons()}</Pagination>
    </Box>
  );
}
