import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { checkPermission } from '../../utils/permissions';
import useAuth from '../../hooks/useAuth';

import ButtonNewFold from '../ButtonNewFold';

import {
  Breadcrumbs as BreadcrumbsMUI,
  Divider as DividerMUI,
} from '@mui/material';

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.blue_300};
  font-size: 24.5px;
`;

const Breadcrumbs = styled(BreadcrumbsMUI)`
  display: flex;
  gap: 5px;
  margin-top: 13px;
  font-size: 13.5px;

  a {
    color: ${({ theme }) => theme.colors.gray_500};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.blue_300};
  }
`;

const Divider = styled(DividerMUI)`
  margin: 25px 0;
`;

export default function Infobar({
  t,
  title,
  text,
  links,
  isButtonFold = false,
  getFolders,
}) {
  const { user } = useAuth();

  return (
    <>
      <Box>
        <div>
          <Title>{t(title, { ns: 'maxion' })}</Title>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>
            {links?.map((link, index) => (
              <NavLink key={index} to={link.href}>
                {t(link.name, { ns: 'maxion' })}
              </NavLink>
            ))}
            <p>{t(text, { ns: 'maxion' })}</p>
          </Breadcrumbs>
        </div>

        {isButtonFold && user && checkPermission(user, [17]) && (
          <ButtonNewFold getFolders={getFolders} />
        )}
      </Box>
      <Divider />
    </>
  );
}
