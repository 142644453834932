import { TooltipMUI as Tooltip } from '../components/Tooltip';

export function formatNumber(number) {
  if (isNaN(number)) return '*';

  const roundedNumber = Math.round(number);
  const formattedNumber = new Intl.NumberFormat('pt-BR').format(roundedNumber);

  const isGiantNumber = formattedNumber.split('.');

  if (isGiantNumber.length >= 3) {
    if (number >= 1000000000 || number <= -1000000000) {
      return (
        <Tooltip title={formattedNumber}>
          <span style={{ cursor: 'default' }}>
            {(number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'}
          </span>
        </Tooltip>
      );
    }
    if (number >= 1000000 || number <= -1000000) {
      return (
        <Tooltip title={formattedNumber}>
          <span style={{ cursor: 'default' }}>
            {(number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'}
          </span>
        </Tooltip>
      );
    }
  }

  return formattedNumber;
}

export function formatNumberFloat(number) {
  const formattedNumber = new Intl.NumberFormat('pt-BR').format(number);
  const split = formattedNumber.split(',');

  if (number >= 1000000000 || number <= -1000000000) {
    return (
      <Tooltip title={split[0]}>
        <span style={{ cursor: 'default' }}>
          {(number / 1000000000).toFixed(2).replace(/\.0$/, '') + 'B'}
        </span>
      </Tooltip>
    );
  } else if (number >= 1000000 || number <= -1000000) {
    return (
      <Tooltip title={split[0]}>
        <span style={{ cursor: 'default' }}>
          {(number / 1000000).toFixed(2).replace(/\.0$/, '') + 'M'}
        </span>
      </Tooltip>
    );
  } else {
    return formattedNumber;
  }
}

export function formatNumbersGraph(number, isTooltip = false) {
  const roundedNumber = Math.round(number);
  const formattedNumber = new Intl.NumberFormat('pt-BR').format(roundedNumber);

  if (isTooltip) return formattedNumber;

  const isGiantNumber = formattedNumber.split('.');

  if (isGiantNumber.length >= 2) {
    if (number >= 1000000000)
      return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    if (number >= 1000000)
      return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    if (number >= 100000)
      return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }

  return formattedNumber;
}

export function formatString(str, comprimentoMaximo) {
  if (str.length <= comprimentoMaximo) {
    return str;
  } else {
    return str.substring(0, comprimentoMaximo) + '...';
  }
}

export function formatStringWithTooltip(
  str,
  comprimentoMaximo,
  isTooltip = false
) {
  if (!isTooltip) {
    if (str.length <= comprimentoMaximo) {
      return str;
    } else {
      return str.substring(0, comprimentoMaximo) + '...';
    }
  }

  return str;
}

export function formatNumberLocale(number, decimalPlace) {
  // Check if the number is not an integer (has decimal places)
  if (!Number.isInteger(number)) {
    // Format the number with decimal places
    return number.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlace,
      maximumFractionDigits: decimalPlace,
    });
  } else {
    // Return the number as is or format as needed
    return number.toLocaleString();
  }
}
