const baseItems = {
  page: 'Mercado',
  buttons: [
    {
      title: 'IHS',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-IHS/Painel10-ICE',
      height: '3000px',
    },
    {
      title: 'LMC',
      type: 'tableau',
      index: 1,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-LMC/Painel1',
      height: '1500px',
    },
    {
      title: 'Sindipeças',
      type: 'tableau',
      index: 2,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-SINDIPECAS/Painel1',
      height: '1500px',
    },
    {
      title: 'Comparativo',
      type: 'tableau',
      index: 3,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-ForecastsICE-Comparativos/Painel1',
      height: '3000px',
    },
  ],
};

export default baseItems;
