import styled from '@emotion/styled';
import { formatNumber } from '../../../../../../../utils/formats';
import { withTranslation } from 'react-i18next';
const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const TableDiv = styled.div`
  .table-container {
    margin: 20px;
    height: 100%;
    overflow-y: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  table tr:last-child .border-bottom {
    border-bottom: 2px solid #0783c2;
  }

  th {
    color: #0783c2;
    background-color: #c2ddf0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    font-size: 14px;
  }

  thead th {
    background-color: #c2ddf0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .row-title th {
    background-color: #f4f7fc;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody tr:nth-child(even) {
    background-color: #f4f8fc;
  }
`;

export default function OverviewReceita({ t, overviewData }) {
  const tableKeys = ['segment', 'jpd', 'application', 'segment_per_vehicle'];

  return (
    <Box>
      {tableKeys.map((table, tableIndex) => {
        const hasData =
          overviewData &&
          overviewData[table] &&
          overviewData[table].rows.length > 0;

        return (
          <TableDiv key={tableIndex}>
            <div className="table-container">
              {hasData ? (
                <table className="main">
                  <thead>
                    <tr className="header">
                      {overviewData[table].columns.map((column, colIndex) => (
                        <th key={colIndex}>{t(column, { ns: 'maxion' })}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {overviewData[table].rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row).map((cell, cellIndex) => (
                          <td key={cellIndex}>
                            {typeof cell === 'number'
                              ? formatNumber(cell)
                              : t(cell, { ns: 'maxion' })}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table>
                  <thead>
                    <tr className="header">
                      {overviewData[table].columns.map((column, colIndex) => (
                        <th key={colIndex}>{t(column, { ns: 'maxion' })}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={overviewData[table].columns.length}>
                        {t('Sem dados', { ns: 'maxion' })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </TableDiv>
        );
      })}
    </Box>
  );
}
