import ListagemVersoes from './POListagemVersoes';
import GrupoCliente from './POGrupoCliente';
import AnaliseDeMercado from './AnaliseDeMercado';
import POPrecoVolume from './POPrecoVolume';

const baseItems = [
  {
    title: 'Listagem de versões',
    index: 0,
    component: <ListagemVersoes />,
  },
  {
    title: 'Plano operacional por grupo de cliente',
    index: 1,
    component: <GrupoCliente />,
  },
  {
    title: 'Análise de Mercado',
    index: 2,
    component: <AnaliseDeMercado />,
  },
  {
    title: `Plano operacional por métrica`,
    index: 3,
    component: <POPrecoVolume />,
  },
];

export default baseItems;
