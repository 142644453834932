import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from '@mui/material';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
`;

function LanguagesDropdown({ t }) {
  const { i18n } = useTranslation();
  const [languageOptions, setLanguageOptions] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const selectedLanguage = languageOptions[i18n.language];

  useEffect(() => {
    const languageOptions = {
      'pt-BR': {
        icon: '/static/img/flags/br.webp',
        name: i18n.language === 'en' ? 'Portuguese' : 'Português',
      },
      en: {
        icon: '/static/img/flags/us.webp',
        name: i18n.language === 'en' ? 'English' : 'Inglês',
      },
    };
    setLanguageOptions(languageOptions);
  }, [i18n.language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    handleClose();
  };

  if (!selectedLanguage) {
    return (
      <Loading>
        <CircularProgress size={21} />
      </Loading>
    );
  }

  return (
    <React.Fragment>
      <Tooltip title={t('Idioma', { ns: 'maxion' })}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {selectedLanguage && (
            <Flag src={selectedLanguage.icon} alt={selectedLanguage.name} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            ml: 1,
            mt: 2.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default withTranslation('maxion')(LanguagesDropdown);
