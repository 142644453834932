import styled from '@emotion/styled';
import { Button as ButtonMUI } from '../../../../components/Buttons';
import { Accordion as AccordionMUI } from '../../../../components/Accordion';
import { TextField as TextFieldMUI } from '../../../../components/TextField';

export const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.gray_500};
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

export const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  gap: 30px;

  form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  span {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }
`;

export const ClosedDate = styled.b`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray_500};
  float: right;
`;

export const ButtonFilter = styled(ButtonMUI)`
  background-color: #008cca;
  width: 160px;
  height: 35px;
  font-size: 15px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 15px 15px 0 15px;
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
  margin: 30px 0 10px 0;

  h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
`;

export const DivTables = styled.div`
  min-width: 1000px;
  overflow: auto;
`;

export const Accordion = styled(AccordionMUI)`
  position: static;

  .MuiAccordionSummary-root {
    padding: 0 8px 0 0;
  }

  header {
    margin: 0 20px 0 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #ccc;

    h2 {
      font-size: 28px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.blue_200};
    }

    svg {
      width: 35px;
      height: 35px;
      color: #399dd2;
      float: right;
    }
  }
`;

export const Critica = styled(Card)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 35px 30px;

  h3 {
    color: ${({ theme }) => theme.colors.gray_500};
    font-size: 25px;
    font-weight: 400;
  }

  span {
    display: flex;
    align-items: center;
    gap: 50px;

    button {
      padding: 10px 50px;
      font-size: 25px;
      font-weight: 400;
      color: #fff;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      outline: none;
    }
  }
`;

export const TextError = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

export const Preco = styled.button`
  background-color: #1c8d01;
`;

export const Volume = styled.button`
  background-color: #ff8b00;
`;

export const VolumeDetalhada = styled.button`
  background-color: #3e6aab;
`;
