const baseItems = {
  buttons: [
    {
      title: 'Produção & Venda',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-ANFAVEA/Painel1',
      height: '1169px',
    },
    {
      title: 'Venda por segmento',
      type: 'tableau',
      index: 1,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-ANFAVEA/Painel2',
      height: '1169px',
    },
    {
      title: 'Pickup',
      type: 'tableau',
      index: 2,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-FENABRAVE/Painel2',
      height: '1000px',
    },
  ],
};

export default baseItems;
