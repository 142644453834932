import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export function NoMaxWidthTooltipMUI(props) {
  return <NoMaxWidthTooltip {...props} arrow placement="top" />;
}

export function TooltipMUI(props) {
  return <Tooltip {...props} arrow placement="top" />;
}
