import React, { useState, useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { GetUsersContext } from '../contexts/GetUsersContext';

import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  RadioGroup,
  Radio,
  FormControlLabel,
  TableContainer,
  Checkbox,
} from '@mui/material';

const Check = styled.div`
  margin-bottom: 10px;
`;

function CardPermissions({ values, setFieldValue, t, isMargin = false }) {
  const { users, loadingUsers } = useContext(GetUsersContext);
  const list = values.permissoes;
  const [checked, setChecked] = useState(false);

  function everyPermission() {
    const everyHasPermission = users.every((user) => {
      const some = list.some((item) => item.user_id === user.id);

      return some;
    });

    if (everyHasPermission) {
      setChecked(true);
    }
  }

  useEffect(() => {
    if (!loadingUsers) {
      everyPermission();
    }
  }, [loadingUsers]);

  function handleChangeChecked(event) {
    if (event.target.checked === true) {
      const newList = users.map((user) => {
        return {
          user_id: user.id,
          permission_id: '1',
        };
      });

      setFieldValue('permissoes', newList);
    } else {
      setFieldValue('permissoes', []);
    }

    setChecked(event.target.checked);
  }

  function handleChange(e, userId) {
    const index = list.findIndex((item) => item.user_id === userId);

    if (index >= 0 && e.target.value === '') {
      list.splice(index, 1);
      setChecked(false);
    }

    if (index >= 0 && e.target.value !== '') {
      list.push(...list, {
        user_id: userId,
        permission_id: e.target.value,
      });
    }

    if (index < 0 && e.target.value !== '') {
      list.push({
        permission_id: e.target.value,
        user_id: userId,
      });
    }

    everyPermission();

    setFieldValue('permissoes', list);
  }

  function selectValueIndex(userId) {
    const index = list.findIndex((item) => item.user_id === userId);

    if (index >= 0) {
      const validation =
        list.length > 0 && typeof list[index].permission_id !== undefined;

      return validation ? list[index].permission_id : '';
    }

    return '';
  }

  return (
    <>
      {!loadingUsers ? (
        <Card variant="outlined" style={{ marginTop: isMargin ? 45 : 0 }}>
          <CardContent>
            <Check>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    size="small"
                    onChange={handleChangeChecked}
                  />
                }
                label={t('Permitir todos usuários', { ns: 'maxion' })}
              />
            </Check>

            <TableContainer style={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" align="center">
                      {t('Usuários', { ns: 'maxion' })}
                    </TableCell>
                    <TableCell component="th" align="center" colSpan={3}>
                      {t('Permissões', { ns: 'maxion' })}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users.map((user, ind) => (
                    <TableRow key={`row-user-${ind}`}>
                      <TableCell align="left">{user.name}</TableCell>
                      <TableCell align="center" colSpan={3}>
                        <RadioGroup
                          arial-label="permissoes"
                          value={selectValueIndex(user.id)}
                          onChange={(event) => handleChange(event, user.id)}
                          row
                          style={{ display: 'block' }}
                        >
                          <FormControlLabel
                            value=""
                            control={<Radio />}
                            label={t('Sem permissão', { ns: 'maxion' })}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t('Leitura', { ns: 'maxion' })}
                          />
                        </RadioGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      ) : (
        <div>{t('Carregando', { ns: 'maxion' })}</div>
      )}
    </>
  );
}

export default withTranslation('maxion')(CardPermissions);
