//Importações
import React, { useContext } from 'react';
import useAuth from '../../hooks/useAuth';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
//Context
import { GreetingContext } from '../../contexts/GreetingContext';
//Components
import baseLayoutItems from '../../components/Navbar/baseLayoutItems';
import Header from './Header.js';
import Footer from './Footer.js';
import Menu from './Menu.js';
import Grid from './Grid.js';
//Imagens
import backgroundImg from '../../assets/image3.png';
//Material UI
import { Box, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';

const Main = styled.main`
  background-image: url(${backgroundImg});
  background-color: #04182b;
  padding: 70px 30px;
  min-height: calc(100vh - 70px);

  section {
    display: flex;
  }
`;
const BoxLarger = styled(Box)`
  background-color: transparent;
  height: 220px;
  max-width: 345px;
  min-width: 345px;
  padding: 45px;
  border: 3px solid #0084c5;
  border-radius: 20px;
  font-size: 47px;
  color: #0084c5;
  margin-right: 30px;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  padding-left: 200px;
  width: 100%;
  height: 100%;
`;

function Home({ t }) {
  const { greeting } = useContext(GreetingContext);
  const { user } = useAuth();

  return (
    <>
      <Header />

      <Main>
        <Grid>
          <section>
            <BoxLarger>{t('Informação gera Ação', { ns: 'maxion' })}</BoxLarger>

            <div>
              {user ? (
                <>
                  <Typography
                    variant="h3"
                    mb="20px"
                    color="#e08308"
                    fontWeight={400}
                    fontSize="31px"
                  >
                    {t(`${greeting}`, { ns: 'maxion' })}, {user.name}.
                  </Typography>

                  <Menu items={baseLayoutItems} />
                </>
              ) : (
                <Loading>
                  <CircularProgress size={60} />
                </Loading>
              )}
            </div>
          </section>
          <Footer t={t} />
        </Grid>
      </Main>
    </>
  );
}

export default withTranslation('maxion')(Home);
