import React, { useEffect, createContext, useState } from 'react';
import useAuth from '../hooks/useAuth';
import axios from '../utils/axios';
import _ from 'lodash';

export const GetUsersContext = createContext({});

export function GetUsersProvider({ children }) {
  const [users, setUsers] = useState([]);

  const [loadingUsers, setLoadingUsers] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (user !== null) {
      axios
        .get(`${process.env.REACT_APP_PUBLIC_API}users?skip=0&limit=200`)
        .then(({ data }) => {
          const filterUsers = _.filter(data, (item) => {
            return item.id !== user.id;
          });
          setUsers(filterUsers !== undefined ? filterUsers : []);
          setLoadingUsers(false);
        })
        .catch((error) => console.log('error users', error));
    }
  }, [user]);

  return (
    <GetUsersContext.Provider value={{ user, users, loadingUsers }}>
      {children}
    </GetUsersContext.Provider>
  );
}
