import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { POContext } from '../../contexts/POContext';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { Tooltip, IconButton as MuiIconButton } from '@mui/material';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 26px;
    height: 26px;
    color: #fff;
  }
`;

function HeaderUserDropdown({ t }) {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { criticDetails, isEditingAop } = useContext(POContext);

  const handleSignOut = async () => {
    if (criticDetails.length > 0 || isEditingAop) {
      const text = t(
        'Há alterações não salvas. Deseja descartar as alterações e sair?',
        { ns: 'maxion' }
      );

      const confirm = window.confirm(text);

      if (!confirm) {
        return;
      }
    }

    await signOut();
    navigate('/auth/sign-in');
  };

  return (
    <React.Fragment>
      <Tooltip title={t('Sair', { ns: 'maxion' })}>
        <IconButton aria-haspopup="true" onClick={handleSignOut} size="large">
          <PowerSettingsNewIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default withTranslation('maxion')(HeaderUserDropdown);
