import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
import * as Yup from 'yup';
import axios from '../../../../../../../utils/axios';
import useAuth from '../../../../../../../hooks/useAuth';
import { POContext } from '../../../../../../../contexts/POContext';
import { checkPermission } from '../../../../../../../utils/permissions';
import { SelectMonths } from '../../../../../../../components/Select';
import { months } from '../../../../../Critica/monthsValueArray';
import ReadjustmentApplied from './ReadjustmentApplied';
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  CircularProgress,
} from '@mui/material';

const Box = styled.div`
  margin-top: 20px;

  > h2 {
    font-size: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
    margin-bottom: 40px;
  }

  .content-appy-forecast {
    padding-left: 50px;

    .select-months {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;

      p {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.blue_200};
        min-width: 120px;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 50px;
    }
  }
`;

const BoxInputPercent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue_200};
  text-align: left;

  .title-month {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
    min-width: 120px;
  }
`;

function normalizeToNumber(value) {
  return parseFloat(value.toString().replace(',', '.'));
}

function InputMonth({
  inputsMonthsSelected,
  setInputsMonthsSelected,
  month,
  indexMonth,
  t,
}) {
  const initialValues = {
    reajuste: month.reajuste,
  };

  const regexDecimal = /^-?\d+(,\d{1,2})?$/;

  const validationSchema = Yup.object().shape({
    reajuste: Yup.string()
      .matches(regexDecimal, 'Formato inválido')
      .required(t('Campo obrigatório', { ns: 'maxion' })),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
  });

  const { values, errors, touched, handleChange, handleBlur } = formik;

  function handleCustomChange(event) {
    handleChange(event);
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    const isValid = regexDecimal.test(fieldValue);

    const newArray = [...inputsMonthsSelected];
    newArray[indexMonth][fieldName] = fieldValue;
    newArray[indexMonth].error = !isValid;
    setInputsMonthsSelected(newArray);
  }

  return (
    <BoxInputPercent>
      <div className="title-month">
        {t(`${month.month}`, { ns: 'maxion' })} {month.year}
      </div>

      <TextField
        sx={{ maxWidth: '150px' }}
        size="small"
        name="reajuste"
        label={t('Reajuste', { ns: 'maxion' })}
        onChange={handleCustomChange}
        value={values.reajuste}
        error={Boolean(touched.reajuste && errors.reajuste)}
        helperText={touched.reajuste && errors.reajuste}
        onBlur={handleBlur}
      />
    </BoxInputPercent>
  );
}

export default function MakeForecastVolume({ t, selectedProducts }) {
  const [monthName, setMonthName] = useState([]);
  const [monthsSelect, setMonthsSelect] = useState(null);
  const [inputsMonthsSelected, setInputsMonthsSelected] = useState([]);
  const [error, setError] = useState(false);
  const [isAbsoluteOrPercentage, setIsAbsoluteOrPercentage] =
    useState('percentage');
  const [newValuesProducts, setNewValuesProducts] = useState(null);
  const [loadingReajustes, setLoadingReajuste] = useState(false);

  const { user } = useAuth();
  const {
    version,
    customer,
    isPlanActive,
    criticDetails,
    setCriticDetails,
    setIsRefreshCritic,
  } = useContext(POContext);

  const finishedFields =
    customer &&
    (customer.calendar === 'finished' ||
      customer.segmentation === 'finished' ||
      customer.aop === 'finished');

  const userHasPermissionToManage = user && checkPermission(user, [], [9, 10]);

  const isDisabledManage =
    !userHasPermissionToManage ||
    !isPlanActive ||
    !finishedFields ||
    customer.operational_plan_volume === 'finished';

  // Meses do select
  useEffect(() => {
    if (version) {
      const baseMonths = [...months];

      const arrayMonths = baseMonths.map((month, index) => {
        const key = `m${index.toString().padStart(2, '0')}`;

        return {
          ...month,
          key,
          year: Number(version.year),
        };
      });

      setMonthsSelect(arrayMonths);
    }
  }, [version]);

  useEffect(() => {
    const isError = inputsMonthsSelected.some((item) => {
      return item.error;
    });

    setError(isError);
  }, [inputsMonthsSelected]);

  //Trocando valores do Select "meses a serem criticados"
  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;

    setMonthName(typeof value === 'string' ? value.split(',') : value);

    const newArray = [];

    value.forEach((item) => {
      const itemAbrevviation = item.split(' ')[0];

      const find = inputsMonthsSelected.find(
        (value) => value.abbreviation === itemAbrevviation
      );

      if (find) {
        newArray.push(find);
      } else {
        const month = monthsSelect.find(
          (value) => value.abbreviation === itemAbrevviation
        );

        newArray.push({
          ...month,
          reajuste: 0,
          error: false,
        });
      }
    });

    setInputsMonthsSelected(newArray);
  };

  function writeApiLogs(products, months, type) {
    const keyType = type === 'percentage' ? 'pct' : 'absolut';

    const newLogs = products.reduce((acc, product) => {
      const objProduct = {};

      for (const month of months) {
        const keyMonth = `${month.key}_${keyType}`;

        objProduct[keyMonth] = normalizeToNumber(month.reajuste);
      }

      objProduct.operational_plan_agg_id = product.operational_plan_agg_id;
      objProduct.created = new Date().toISOString();

      acc.push(objProduct);

      return acc;
    }, []);

    setCriticDetails([...criticDetails, ...newLogs]);
  }

  async function handleApplyReadjustmentValues() {
    try {
      setLoadingReajuste(true);
      const newArrayProducts = _.cloneDeep(selectedProducts);
      const inputMonths = [...inputsMonthsSelected];

      newArrayProducts.forEach((product) => {
        inputMonths.forEach((month) => {
          const indexMonth = month.id - 1;
          const value = normalizeToNumber(month.reajuste);
          let result = null;

          if (isAbsoluteOrPercentage === 'percentage') {
            const percent = 1 + value / 100;
            result = product.baseValues[indexMonth] * percent;
          } else {
            result = value;
          }

          product.reajusteValues[indexMonth] = Math.round(result);

          const newTotalReajuste = product.reajusteValues.reduce(
            (acc, valueProduct) => {
              const value = valueProduct || 0;
              return acc + value;
            },
            0
          );

          product.reajusteIndexMonths.push(indexMonth);
          product.reajusteTotal = Math.round(newTotalReajuste);
        });
      });

      const apiDetails = newArrayProducts.reduce((acc, product) => {
        const objProduct = {};

        for (const month of inputMonths) {
          const indexMonth = Number(month.id) - 1;

          objProduct[month.key] = product.reajusteValues[indexMonth];
        }

        objProduct.operational_plan_agg_id = product.operational_plan_agg_id;

        acc.push(objProduct);

        return acc;
      }, []);

      const body = {
        type: 'volume',
        details: apiDetails,
      };

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/critic`,
        body
      );

      writeApiLogs(
        newArrayProducts,
        inputsMonthsSelected,
        isAbsoluteOrPercentage
      );

      setIsRefreshCritic(true);
      setNewValuesProducts(newArrayProducts);
      toast.success(t('Crítica aplicada com sucesso', { ns: 'maxion' }));
    } catch (error) {
      console.log(error);
      toast.error(t('Erro ao aplicar crítica', { ns: 'maxion' }));
    } finally {
      setLoadingReajuste(false);
    }
  }

  function handleClearMonths() {
    setMonthName([]);
    setInputsMonthsSelected([]);
  }

  return (
    <Box>
      <h2>{t('Realizar previsão de volume', { ns: 'maxion' })}</h2>

      <div className="content-appy-forecast">
        <div className="select-months">
          <p>{t('Meses', { ns: 'maxion' })}</p>

          <SelectMonths
            width={400}
            label={t('Selecione os meses', { ns: 'maxion' })}
            value={monthName}
            items={monthsSelect}
            monthName={monthName}
            onChange={handleChangeSelect}
          />

          <RadioGroup
            sx={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}
            aria-labelledby="controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={isAbsoluteOrPercentage}
            onChange={(e) => setIsAbsoluteOrPercentage(e.target.value)}
          >
            <FormControlLabel
              value="percentage"
              control={<Radio />}
              label={t('Percentual', { ns: 'maxion' })}
            />

            <FormControlLabel
              value="absolute"
              control={<Radio />}
              label={t('Absoluto', { ns: 'maxion' })}
            />
          </RadioGroup>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            margin: '20px 0',
          }}
        >
          {inputsMonthsSelected
            .sort((a, b) => a.id - b.id)
            .map((item, index) => (
              <div key={index}>
                <InputMonth
                  inputsMonthsSelected={inputsMonthsSelected}
                  setInputsMonthsSelected={setInputsMonthsSelected}
                  month={item}
                  indexMonth={index}
                  t={t}
                />
              </div>
            ))}
        </div>

        <div className="buttons">
          <Button
            sx={{ width: '170px', height: '40px' }}
            variant="contained"
            color="inherit"
            onClick={handleClearMonths}
            disabled={
              error ||
              inputsMonthsSelected.length === 0 ||
              monthName.length === 0 ||
              isDisabledManage ||
              loadingReajustes
            }
          >
            {t('Limpar seleção', { ns: 'maxion' })}
          </Button>

          <Button
            sx={{ width: '170px', height: '40px' }}
            onClick={handleApplyReadjustmentValues}
            variant="contained"
            disabled={
              error ||
              selectedProducts.length === 0 ||
              inputsMonthsSelected.length === 0 ||
              isDisabledManage ||
              loadingReajustes
            }
          >
            {loadingReajustes ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              t('Aplicar', { ns: 'maxion' })
            )}
          </Button>
        </div>
      </div>

      {newValuesProducts !== null && (
        <ReadjustmentApplied
          data={newValuesProducts}
          loadingData={loadingReajustes}
          t={t}
        />
      )}
    </Box>
  );
}
