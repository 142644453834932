import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import Loading from '../../../../../../../components/Loading';
import format from 'date-fns/format';

import { checkPermission } from '../../../../../../../utils/permissions';
import useAuth from '../../../../../../../hooks/useAuth';

import HamburguerProduct from './HamburguerProduct';
import { POContext } from '../../../../../../../contexts/POContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatStringWithTooltip } from '../../../../../../../utils/formats';
import { Tooltip } from '@mui/material';

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 30px;
  min-height: 500px;
  max-height: 100%;
  min-width: 1000px;
  max-width: 2000px;
  margin-bottom: 0px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #c2ddf0;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    font-size: 13px;
  }

  th {
    cursor: pointer;
    color: #0783c2;
    background-color: #c2ddf0;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f4f8fc;
  }

  .sortable th {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow {
    margin-left: 5px;
    right: 10px;
    transition: transform 0.3s ease;
    opacity: 0.3;
  }

  .arrow.asc {
    transform: rotate(0deg);
    opacity: 1;
  }

  .arrow.desc {
    transform: rotate(180deg);
    opacity: 1;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;

  button {
    background-color: transparent;
    border: 1px solid #3182ce;
    border-radius: 6px;
    color: #3182ce;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover:not(:disabled) {
      background-color: #3182ce;
      color: #fff;
    }

    &:disabled {
      pointer-events: none;
    }

    &.active {
      background-color: #3182ce;
      color: #fff;
      pointer-events: none;
    }
  }
`;

function sortArray(data, field, direction) {
  return data.sort((a, b) => {
    const aValue = a[field] ? String(a[field]) : '';
    const bValue = b[field] ? String(b[field]) : '';

    if (direction === 'asc') {
      return aValue.localeCompare(bValue);
    } else if (direction === 'desc') {
      return bValue.localeCompare(aValue);
    }
  });
}

function Header({ t, canUserInteract, items, setItems }) {
  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Organização', key: 'descricao_organizacao' },
    { label: 'Cliente', key: 'nome_cliente' },
    { label: 'Segmento', key: 'descricao_segmento' },
    { label: 'Aplicação', key: 'descricao_aplicacao' },
    { label: 'Hierarquia', key: 'descricao_hierarquia_produto' },
    { label: 'Código no cliente', key: 'codigo_produto_cliente' },
    { label: 'Nome do produto', key: 'name' },
    { label: 'Preço', key: 'unitary_value' },
    { label: 'Código SAP', key: 'sap_id' },
    { label: 'Data de criação', key: 'created_at' },
  ];

  if (canUserInteract) {
    headers.push({ label: 'Ações', key: 'actions' });
  }

  const [sortConfig, setSortConfig] = useState({
    field: '',
    direction: 'asc',
  });

  function handleClick(field) {
    const newDirection =
      sortConfig.field === field && sortConfig.direction === 'asc'
        ? 'desc'
        : 'asc';
    setSortConfig({
      field,
      direction: newDirection,
    });
    const sortedItems = sortArray([...items], field, newDirection);
    setItems(sortedItems);
  }

  return (
    <thead>
      <tr>
        {headers.map((item, itemIndex) => (
          <th
            key={itemIndex}
            onClick={() => handleClick(item.key)}
            className="sortable"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              {t(item.label, { ns: 'maxion' }).toUpperCase()}
              {item.key !== 'actions' && (
                <KeyboardArrowDownIcon
                  className={`arrow ${
                    sortConfig.field === item.key ? sortConfig.direction : ''
                  }`}
                />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}

function Row({
  fetchSemaphore,
  canUserInteract,
  t,
  row,
  fetchTableItems,
  setFormikInitialValues,
  isEditingProduct,
  setRowId,
  setIsEditingProduct,
}) {
  return (
    <tr>
      <td>{row.id && row.id > 0 ? row.id : '*'}</td>

      {/* Organização */}
      <td>
        <Tooltip
          title={
            row.descricao_organizacao && row.descricao_organizacao.length > 0
              ? formatStringWithTooltip(
                  String(
                    row.codigo_organizacao + ' - ' + row.descricao_organizacao
                  ),
                  18,
                  true
                )
              : 'N/A'
          }
        >
          <span>
            {row.descricao_organizacao && row.descricao_organizacao.length > 0
              ? formatStringWithTooltip(
                  String(
                    row.codigo_organizacao + ' - ' + row.descricao_organizacao
                  ),
                  18
                )
              : 'N/A'}
          </span>
        </Tooltip>
      </td>

      {/* Nome cliente */}
      <td>
        <Tooltip
          title={
            row.nome_cliente && row.nome_cliente.length > 0
              ? formatStringWithTooltip(
                  String(row.codigo_cliente + ' - ' + row.nome_cliente),
                  18,
                  true
                )
              : 'N/A'
          }
        >
          <span>
            {row.nome_cliente && row.nome_cliente.length > 0
              ? formatStringWithTooltip(
                  String(row.codigo_cliente + ' - ' + row.nome_cliente),
                  26
                )
              : 'N/A'}
          </span>
        </Tooltip>
      </td>

      {/* Segmento */}
      <td>
        <Tooltip
          title={
            row.descricao_segmento && row.descricao_segmento.length > 0
              ? formatStringWithTooltip(
                  String(row.codigo_segmento + ' - ' + row.descricao_segmento),
                  18,
                  true
                )
              : 'N/A'
          }
        >
          <span>
            {row.descricao_segmento && row.descricao_segmento.length > 0
              ? formatStringWithTooltip(
                  String(row.codigo_segmento + ' - ' + row.descricao_segmento),
                  18
                )
              : 'N/A'}
          </span>
        </Tooltip>
      </td>

      {/* Aplicação */}
      <td>
        <Tooltip
          title={
            row.descricao_aplicacao && row.descricao_aplicacao.length > 0
              ? formatStringWithTooltip(row.descricao_aplicacao, 18, true)
              : 'N/A'
          }
        >
          <span>
            {row.descricao_aplicacao && row.descricao_aplicacao.length > 0
              ? formatStringWithTooltip(row.descricao_aplicacao, 18)
              : 'N/A'}
          </span>
        </Tooltip>
      </td>

      {/* Hierarquia */}
      <td>
        <Tooltip
          title={
            row.descricao_hierarquia_produto &&
            row.descricao_hierarquia_produto.length > 0
              ? formatStringWithTooltip(
                  String(
                    row.codigo_hierarquia_produto +
                      ' - ' +
                      row.descricao_hierarquia_produto
                  ),
                  18,
                  true
                )
              : 'N/A'
          }
        >
          <span>
            {row.descricao_hierarquia_produto &&
            row.descricao_hierarquia_produto.length > 0
              ? formatStringWithTooltip(
                  String(
                    row.codigo_hierarquia_produto +
                      ' - ' +
                      row.descricao_hierarquia_produto
                  ),
                  18
                )
              : 'N/A'}
          </span>
        </Tooltip>
      </td>

      {/* Código no cliente */}
      <td>
        <Tooltip
          title={
            row.codigo_produto_cliente && row.codigo_produto_cliente.length > 0
              ? formatStringWithTooltip(row.codigo_produto_cliente, 18, true)
              : 'N/A'
          }
        >
          <span>
            {row.codigo_produto_cliente && row.codigo_produto_cliente.length > 0
              ? formatStringWithTooltip(row.codigo_produto_cliente, 18)
              : 'N/A'}
          </span>
        </Tooltip>
      </td>

      {/* Nome do produto */}
      <td>
        <Tooltip
          title={
            row.name && row.name.length > 0
              ? formatStringWithTooltip(row.name, 18, true)
              : 'N/A'
          }
        >
          <span>
            {row.name && row.name.length > 0
              ? formatStringWithTooltip(row.name, 18)
              : 'N/A'}
          </span>
        </Tooltip>
      </td>

      {/* Preço */}
      <td>
        {row.unitary_value && row.unitary_value > 0
          ? row.unitary_value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : 'N/A'}
      </td>

      {/* Código SAP */}
      <td>
        <Tooltip
          title={
            row.sap_id && row.sap_id.length > 0
              ? formatStringWithTooltip(row.sap_id, 18, true)
              : 'N/A'
          }
        >
          <span>
            {row.sap_id && row.sap_id.length > 0
              ? formatStringWithTooltip(row.sap_id, 18)
              : 'N/A'}
          </span>
        </Tooltip>
      </td>

      {/* Ações */}
      <td>
        {row.created_at && row.created_at.length > 0
          ? format(new Date(row.created_at), 'dd/MM/yyyy')
          : 'N/A'}
      </td>

      {canUserInteract === true ? (
        <td>
          <HamburguerProduct
            fetchSemaphore={fetchSemaphore}
            fetchTableItems={fetchTableItems}
            t={t}
            row={row}
            setRowId={setRowId}
            setFormikInitialValues={setFormikInitialValues}
            isEditingProduct={isEditingProduct}
            setIsEditingProduct={setIsEditingProduct}
          />
        </td>
      ) : null}
    </tr>
  );
}

export default function TableProductList({
  fetchSemaphore,
  fetchTableItems,
  t,
  items,
  setItems,
  loadingItems,
  version,
  setFormikInitialValues,
  isEditingProduct,
  setIsEditingProduct,
  setRowId,
}) {
  const ITEMS_PER_PAGE = 6;
  const MAX_PAGE_BUTTONS = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / ITEMS_PER_PAGE);

  const pageData = items.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  function handlePageClick(event) {
    const nextPage = parseInt(event.target.textContent);
    setCurrentPage(nextPage);
  }

  function renderPageButtons() {
    let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_BUTTONS / 2));
    let endPage = Math.min(totalPages, startPage + MAX_PAGE_BUTTONS - 1);

    const pageButtons = [];

    if (startPage > 1) {
      pageButtons.push(
        <button onClick={() => setCurrentPage(currentPage - MAX_PAGE_BUTTONS)}>
          ...
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={handlePageClick}
          disabled={i === currentPage}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      pageButtons.push(
        <button
          key={pageButtons.length + 1}
          onClick={() => setCurrentPage(currentPage + MAX_PAGE_BUTTONS)}
        >
          ...
        </button>
      );
    }

    return pageButtons.length > 1 ? pageButtons : null;
  }

  const { user } = useAuth();
  const { isPlanActive, customer } = useContext(POContext);

  const userHasPermissionToInteract =
    user && checkPermission(user, [], [9, 10]);
  const isProductEditable = customer.aop !== 'finished' ? true : false;
  const isVersionEditable = isPlanActive;

  const canUserInteract =
    userHasPermissionToInteract && isProductEditable && isVersionEditable;

  return (
    <TableContainer>
      <StyledTable>
        <Header
          t={t}
          canUserInteract={canUserInteract}
          items={items}
          setItems={setItems}
        />
        <tbody>
          {loadingItems === false && items.length > 0 && (
            <>
              {pageData.map((row, index) => (
                <Row
                  key={index}
                  canUserInteract={canUserInteract}
                  version={version}
                  fetchSemaphore={fetchSemaphore}
                  fetchTableItems={fetchTableItems}
                  t={t}
                  setRowId={setRowId}
                  row={row}
                  indexRow={index}
                  setFormikInitialValues={setFormikInitialValues}
                  isEditingProduct={isEditingProduct}
                  setIsEditingProduct={setIsEditingProduct}
                />
              ))}
            </>
          )}
        </tbody>
      </StyledTable>
      {loadingItems === false && items.length === 0 && (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#4b4d4f',
            fontWeight: 600,
            fontSize: '17px',
            minHeight: '340px',
          }}
        >
          <p>
            {t('Não existem novos produtos cadastrados para o ano', {
              ns: 'maxion',
            })}
          </p>
        </div>
      )}
      {loadingItems && <Loading />}
      <Pagination>{renderPageButtons()}</Pagination>
    </TableContainer>
  );
}
