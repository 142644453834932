import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
//Material UI
import { Typography, Box as BoxMUI } from '@mui/material';
import { NavLink } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const Box = styled(BoxMUI)`
  position: relative;
  background-color: rgba(5, 82, 122, 0.4);
  /* background : ;  */
  width: 420px;
  min-height: 280px;
  padding: 20px 40px 20px 20px;
  margin-right: 40px;
  border-radius: 10px;
  list-style: none;
`;

export const ListLinks = styled.div`
  margin-top: 25px;

  li {
    margin-top: 15px;

    a {
      color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
      font-size: 17px;

      :hover {
        color: ${({ theme }) => theme.colors.blue_300};
      }
    }
  }
`;

function FooterLinks({ title, links, t }) {
  const [newLinks, setNewLinks] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    if (user !== null) {
      const linksOriginais = _.cloneDeep(links);

      if (_.intersection([1], user.rolesList).length > 0) {
        setNewLinks(linksOriginais);
        return;
      }

      let itemsFilter = _.filter(linksOriginais, (item) => {
        return (
          _.intersection(item.permissions, user.permissionsList).length > 0 ||
          _.intersection(item.roles, user.rolesList).length > 0
        );
      });

      setNewLinks(itemsFilter);
    }
  }, [links, user]);

  return (
    <Box>
      <Typography
        variant="h3"
        color="#e76c2b"
        fontWeight={500}
        fontSize="16px"
        mb="20px"
      >
        {title}
      </Typography>

      <ListLinks>
        {newLinks.map((link, index) => (
          <li key={index}>
            <NavLink to={link.route}>
              &gt; {t(`${link.title}`, { ns: 'maxion' })}
            </NavLink>
          </li>
        ))}
      </ListLinks>
    </Box>
  );
}

export default withTranslation('maxion')(FooterLinks);
