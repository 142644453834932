const baseItems = {
  page: 'Previsão de faturamento',
  buttons: [
    {
      title: 'Dashboard A',
      type: 'tableau',
      index: 0,
      src: 'https://public.tableau.com/views/CovidDashboard2020-2022_16588827795700/Dashboard1?:embed=y&:showVizHome=no',
    },
  ],
};

export default baseItems;
