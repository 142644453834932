import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const GreetingContext = createContext({});

export function GreetingProvider({ children }) {
  const { t } = useTranslation();
  const [greeting, setGreeting] = useState(t('Boa tarde', { ns: 'maxion' }));

  useEffect(() => {
    const date = new Date().toLocaleTimeString('UTF');

    function getHour() {
      const split = date.split(':');
      const hour = split[0];

      if (hour < 12 && hour >= 5) setGreeting(t('Bom dia', { ns: 'maxion' }));
      if (hour >= 12 && hour < 18)
        setGreeting(t('Boa tarde', { ns: 'maxion' }));
      if (hour >= 18 || hour < 5) setGreeting(t('Boa noite', { ns: 'maxion' }));
    }

    getHour();
  }, []);

  return (
    <GreetingContext.Provider value={{ greeting }}>
      {children}
    </GreetingContext.Provider>
  );
}
