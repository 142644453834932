export const months = [
  {
    id: 1,
    month: 'Janeiro',
    abbreviation: 'JAN',
  },
  {
    id: 2,
    month: 'Fevereiro',
    abbreviation: 'FEV',
  },
  {
    id: 3,
    month: 'Março',
    abbreviation: 'MAR',
  },
  {
    id: 4,
    month: 'Abril',
    abbreviation: 'ABR',
  },
  {
    id: 5,
    month: 'Maio',
    abbreviation: 'MAI',
  },
  {
    id: 6,
    month: 'Junho',
    abbreviation: 'JUN',
  },
  {
    id: 7,
    month: 'Julho',
    abbreviation: 'JUL',
  },
  {
    id: 8,
    month: 'Agosto',
    abbreviation: 'AGO',
  },
  {
    id: 9,
    month: 'Setembro',
    abbreviation: 'SET',
  },
  {
    id: 10,
    month: 'Outubro',
    abbreviation: 'OUT',
  },
  {
    id: 11,
    month: 'Novembro',
    abbreviation: 'NOV',
  },
  {
    id: 12,
    month: 'Dezembro',
    abbreviation: 'DEZ',
  },
];
