import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Infobar from '../../../components/Infobar/Infobar';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';

const Iframe = styled.iframe`
  display: ${({ isDisplay }) => (isDisplay ? 'none' : 'block')};
  width: 100%;
  margin: 0 auto;
  min-height: 110vh;
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
`;

function Tableau({ t }) {
  const { state } = useLocation();
  const { childName, name, url, foldId, childId } = state;

  const newUlr = url + '?:embed=y&:showVizHome=no';

  return (
    <>
      <Helmet title={name} />

      <Infobar
        t={t}
        title={name}
        links={[
          {
            name: t('Estratégiais e Materiais', { ns: 'maxion' }),
            href: '/marketing-content/explore',
          },
          {
            name: t('Explorar', { ns: 'maxion' }),
            href: '/marketing-content/explore',
          },
          {
            name: t(childName, { ns: 'maxion' }),
            href: `/marketing-content/details/${foldId}/${childId}`,
          },
        ]}
        text={name}
      />

      <Iframe title="Tableau" src={newUlr} scrolling="yes" frameBorder="no" />
    </>
  );
}

export default withTranslation('maxion')(Tableau);
