import React from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import styled from '@emotion/styled';

import { formatString } from '../../../../../../../utils/formats';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: rgba(97, 97, 97, 0.92);
  padding: 4px 8px;
  border-radius: 4px;
  max-width: 350px;
  position: relative !important;
  left: 10px;

  p {
    font-size: 0.6383928571428572rem;
    color: #fff;
  }
`;

const Arrow = styled(HoverCard.Arrow)`
  fill: rgba(97, 97, 97, 0.92);
`;

export default function HoverCardProduct({ client, product, t }) {
  return (
    <HoverCard.Root openDelay={100} closeDelay={100}>
      <HoverCard.Trigger>{formatString(product, 33)}</HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content sideOffset={5} side="top">
          <Card>
            <p>
              <b>{t('Cliente', { ns: 'maxion' })}:</b> {client}
            </p>

            <p>
              <b>{t('Produto', { ns: 'maxion' })}:</b> {product}
            </p>
          </Card>

          <Arrow />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
