import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { generateTokenTableau } from '../../../../utils/jwtTableau';
import { Breadcrumbs, Divider } from '@mui/material';
import axios from '../../../../utils/axios';
import { withTranslation } from 'react-i18next';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

const Box = styled.div`
  display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
`;

function MontarIframe(iframe, token, t, invisibleNumber) {
  return (
    <div style={{ width: '100%' }}>
      <Infobar t={t}>
        <div>
          <div>
            <h2>{t('Plano Operacional', { ns: 'maxion' })}</h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

              <p>{t('Números Maxion', { ns: 'maxion' })} </p>

              <p>{t('Plano Operacional', { ns: 'maxion' })}</p>

              <span>{t(String(iframe.title), { ns: 'maxion' })}</span>
            </Breadcrumbs>
          </div>
        </div>

        <Divider className="divider" />
      </Infobar>

      <tableau-viz
        id="tableau-viz"
        src={iframe.src}
        width="100%"
        height={iframe.height}
        toolbar="hidden"
      />
    </div>
  );
}

function Content({ t, baseInfos, invisibleNumber }) {
  const [content, setContent] = useState([]);
  const [old, setOld] = useState(null);

  useEffect(() => {
    if (invisibleNumber !== undefined) {
      const validation = content.some(
        ({ page }) => page.index === invisibleNumber
      );

      if (old === null || old !== baseInfos[invisibleNumber].title) {
        setUsersNavigationLog(baseInfos[invisibleNumber].title);
        setOld(baseInfos[invisibleNumber].title);
      }

      if (validation === false) {
        const index = content.findIndex(({ page }) => page.type === 'slide');
        if (index > 0) content.splice(index, 1);

        let token = null;
        if (baseInfos[invisibleNumber].type === 'tableau') {
          token = generateTokenTableau();
        }

        setContent([
          ...content,
          {
            page: {
              index: invisibleNumber,
              item:
                baseInfos[invisibleNumber].type !== 'tableau'
                  ? React.cloneElement(baseInfos[invisibleNumber].element, {
                      invisibleNumber,
                    })
                  : MontarIframe(
                      baseInfos[invisibleNumber],
                      token,
                      t,
                      invisibleNumber
                    ),
              type: baseInfos[invisibleNumber].type,
            },
          },
        ]);
      }
    }
  }, [invisibleNumber, baseInfos, old]);

  async function setUsersNavigationLog(textRoute) {
    try {
      const route = `OpenMax / Números Maxion / Plano Operacional / ${textRoute}`;

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}users_navigation_log`,
        {
          route: route,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {content.map((item, index) => {
        return (
          <Box
            key={index}
            isDisplay={item.page.index === invisibleNumber ? true : false}
          >
            {React.cloneElement(item.page.item, { invisibleNumber })}
          </Box>
        );
      })}
    </div>
  );
}

export default withTranslation('maxion')(Content);
