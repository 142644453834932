import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import Loading from '../../../../../../../components/Loading';
import axios from '../../../../../../../utils/axios';
import { Select } from '@mui/material';
import { FormControl } from '@mui/material';
import { MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextError = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

export default function ModalEditVersion({
  fetchVersions,
  openModalEditVersion,
  setOpenModalEditVersion,
  row,
  t,
}) {
  const [loading, setLoading] = useState(false);
  const [baseDateArray, setBaseDateArray] = useState([]);

  const initialValuesFormik = {
    name: row && row.name && row.name.length > 0 ? row.name : '',
    observations:
      row && row.observations && row.observations.length > 0
        ? row.observations
        : '',
    base: row && row.base.length > 0 ? row.base : '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${t('Campo obrigatório', { ns: 'maxion' })}`),
    observations: Yup.string(),
  });

  const fetchClosingDate = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}parameters/name/MES_ULTIMO_FECHAMENTO`
      );
      setBaseDateArray(handleBaseDate(data.value));
      const response = handleBaseDate(data.value);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchClosingDate();
  }, []);

  function handleBaseDate(baseDate) {
    const monthRaw = parseInt(baseDate.substring(5, 7));

    const dateString = baseDate;
    const year = parseInt(dateString.substring(0, 4));

    const previousMonthArray = [{}];

    for (let i = 1; i <= monthRaw; i++) {
      if (i > 0 && !undefined) {
        const monthCalendar = i.toString().padStart(2, '0');
        const monthBase = i;
        const prevision = 12 - monthCalendar;

        const label = `${monthBase}+${prevision}`;
        const base_date = `${year}-${monthCalendar}-01`;

        previousMonthArray.push({ label, base_date });
      }
    }

    return previousMonthArray;
  }

  async function handleEdit(values) {
    setLoading(true);
    try {
      const { name, observations, base } = values;

      const body = {
        id: row.id,
        name: name,
        observations: observations,
        base: base,
      };

      await axios.put(`${process.env.REACT_APP_PUBLIC_API}po_versions`, body);

      setOpenModalEditVersion(false);
      setLoading(false);
      toast.success(t('Versão alterada com sucesso', { ns: 'maxion' }));
      fetchVersions();
    } catch (error) {
      toast.error(t('Erro ao alterar versão', { ns: 'maxion' }));
      console.log(`Error submiting data: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setOpenModalEditVersion(false);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValuesFormik}
      validationSchema={validationSchema}
      onSubmit={handleEdit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
      }) => (
        <Dialog
          open={openModalEditVersion}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{t('Editar versão', { ns: 'maxion' })}</DialogTitle>
          <DialogContent>
            <Form onSubmit={handleEdit}>
              <TextField
                margin="dense"
                id="name"
                label={t('Nome da versão', { ns: 'maxion' })}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                fullWidth
                variant="outlined"
                error={Boolean(touched.name && errors.name)}
              />
              {errors.name && touched.name && errors.name && (
                <TextError>{errors.name}</TextError>
              )}

              <TextField
                sx={{ marginTop: '20px' }}
                margin="dense"
                id="observations"
                label={t('Observações', { ns: 'maxion' })}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={values.observations}
                variant="outlined"
                error={Boolean(touched.observations && errors.observations)}
              />
              {errors.observations &&
                touched.observations &&
                errors.observations && (
                  <TextError>{errors.observations}</TextError>
                )}

              <TextField
                disabled
                margin="dense"
                id="year"
                label={t('Ano', { ns: 'maxion' })}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={row && row.year}
                fullWidth
                variant="outlined"
              />

              <TextField
                disabled
                margin="dense"
                id="base"
                label={t('Base', { ns: 'maxion' })}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={row && row.base}
                fullWidth
                variant="outlined"
              />
            </Form>
          </DialogContent>

          {loading && <Loading />}
          <DialogActions>
            <Button
              disabled={loading}
              onClick={() => {
                resetForm();
                handleClose();
              }}
            >
              {t('Cancelar', { ns: 'maxion' })}
            </Button>

            <Button type="submit" disabled={loading} onClick={handleSubmit}>
              {loading
                ? t('Enviando', { ns: 'maxion' })
                : t('Enviar', { ns: 'maxion' })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
