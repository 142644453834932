//Importações
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
//Components
import Infobar from '../../../../components/Infobar/Infobar';
import ButtonsActions from './ButtonsActions';
import baseItems from './baseItems.js';
import Content from './Content';
import { withTranslation } from 'react-i18next';
import { ReportProvider } from './ReportGeneral/components/ReportProvider.js';

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

function PlanoOperacional({ t }) {
  const [invisibleNumber, setInvisibleNumber] = useState(0);

  return (
    <ReportProvider t={t}>
      <Box>
        <Helmet title={t('Plano operacional', { ns: 'maxion' })} />

        {baseItems.buttons.length > 1 && (
          <ButtonsActions
            t={t}
            buttonInfos={baseItems.buttons}
            invisibleNumber={invisibleNumber}
            setInvisibleNumber={setInvisibleNumber}
          />
        )}

        <Content
          t={t}
          baseInfos={baseItems.buttons}
          invisibleNumber={invisibleNumber}
        />
      </Box>
    </ReportProvider>
  );
}

export default withTranslation('maxion')(PlanoOperacional);
