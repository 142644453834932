import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import Loading from '../../../../../../../components/Loading';
import axios from '../../../../../../../utils/axios';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextError = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

export default function ModalNewVersion({
  fetchVersions,
  openModalNewVersion,
  setOpenModalNewVersion,
  t,
}) {
  const [loading, setLoading] = useState(false);
  const [baseDateArray, setBaseDateArray] = useState([]);

  const initialValuesFormik = {
    name: '',
    observation: '',
    base_date: '',
  };

  const fetchClosingDate = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}parameters/name/MES_ULTIMO_FECHAMENTO`
      );
      setBaseDateArray(handleBaseDate(data.value));
    } catch (error) {
      console.log(error);
    }
  };

  function handleBaseDate(baseDate) {
    const monthRaw = parseInt(baseDate.substring(5, 7));

    const dateString = baseDate;
    const year = parseInt(dateString.substring(0, 4));

    const previousMonthArray = [{}];

    for (let i = 1; i <= monthRaw; i++) {
      if (i > 0 && !undefined) {
        const monthCalendar = i.toString().padStart(2, '0');
        const monthBase = i;
        const prevision = 12 - monthCalendar;

        const label = `${monthBase}+${prevision}`;
        const base_date = `${year}-${monthCalendar}-01`;

        previousMonthArray.push({ label, base_date });
      }
    }

    return previousMonthArray;
  }

  useEffect(() => {
    fetchClosingDate();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${t('Campo obrigatório', { ns: 'maxion' })}`),
    observation: Yup.string(),
    base_date: Yup.string().required(
      `${t('Campo obrigatório', { ns: 'maxion' })}`
    ),
  });

  async function handleCreate(values) {
    setLoading(true);
    try {
      const { name, observation, base_date } = values;

      const body = {
        name: name,
        observations: observation,
        base_date: base_date,
        final_version: false,
      };

      await axios.post(`${process.env.REACT_APP_PUBLIC_API}po_versions`, body);

      setOpenModalNewVersion(false);
      setLoading(false);
      toast.success(t('Versão criada com sucesso'), { ns: 'maxion' });
      fetchVersions();
    } catch (error) {
      toast.error(t('Erro ao criar versão'), { ns: 'maxion' });
      console.log(`Error submiting data: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setOpenModalNewVersion(false);
  }

  return (
    <Formik
      initialValues={initialValuesFormik}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
      }) => (
        <Dialog
          open={openModalNewVersion}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{t('Nova versão', { ns: 'maxion' })}</DialogTitle>
          <DialogContent>
            <Form onSubmit={handleCreate}>
              <TextField
                fullWidth
                margin="dense"
                id="name"
                label={t('Nome da versão', { ns: 'maxion' })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                variant="outlined"
                error={Boolean(touched.name && errors.name)}
              />

              {errors.name && touched.name && errors.name && (
                <TextError>{errors.name}</TextError>
              )}

              <TextField
                fullWidth
                sx={{ marginTop: '20px' }}
                margin="dense"
                id="observation"
                label={t('Observações', { ns: 'maxion' })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.observation}
                variant="outlined"
                error={Boolean(touched.observation && errors.observation)}
              />

              {errors.observation &&
                touched.observation &&
                errors.observation && (
                  <TextError>{errors.observation}</TextError>
                )}

              <FormControl fullWidth sx={{ marginTop: '20px' }}>
                <InputLabel>{t('Base', { ns: 'maxion' })}</InputLabel>
                <Select
                  margin="dense"
                  id="base_date"
                  label={t('Base', { ns: 'maxion' })}
                  type="text"
                  onChange={(e) => {
                    setFieldValue('base_date', e.target.value);
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  value={values.base_date}
                  variant="outlined"
                  error={Boolean(touched.base_date && errors.base_date)}
                >
                  {baseDateArray.map((item, index) => {
                    if (item && item.base_date && item.label) {
                      return (
                        <MenuItem key={index} value={item.base_date}>
                          {item.label}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>

              {errors.base_date && touched.base_date && errors.base_date && (
                <TextError>{errors.base_date}</TextError>
              )}
            </Form>
          </DialogContent>

          {loading && <Loading />}
          <DialogActions>
            <Button
              disabled={loading}
              onClick={() => {
                resetForm();
                handleClose();
              }}
            >
              {t('Cancelar', { ns: 'maxion' })}
            </Button>

            <Button type="submit" disabled={loading} onClick={handleSubmit}>
              {loading
                ? t('Enviando', { ns: 'maxion' })
                : t('Enviar', { ns: 'maxion' })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
