//Importações
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';
import useAuth from '../../hooks/useAuth';
//Components
import MenuNavSection from './components/MenuNavSection';
//Material UI
import { CircularProgress } from '@mui/material';

const MenuNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 110px;
  min-width: 700px;
`;

export default function Menu({ items }) {
  const { user } = useAuth();
  const [itemsFiltered, setItemsFiltered] = useState([]);

  useEffect(() => {
    if (user !== null) {
      const itemsOriginais = _.cloneDeep(items);

      if (_.intersection([1], user.rolesList).length > 0) {
        setItemsFiltered(itemsOriginais);
        return;
      }

      let itemsFilter = _.filter(itemsOriginais, (item) => {
        item.pages = _.filter(item.pages, (page) => {
          page.children = _.filter(page.children, (child) => {
            return (
              _.intersection(child.permissions, user.permissionsList).length >
                0 || _.intersection(child.roles, user.rolesList).length > 0
            );
          });
          return page.children.length > 0;
        });

        return item.pages.length > 0;
      });

      setItemsFiltered(itemsFilter);
    }
  }, [items, user]);

  if (!itemsFiltered) {
    return (
      <Loading>
        <CircularProgress size={35} />
      </Loading>
    );
  }

  return (
    <MenuNav>
      {itemsFiltered &&
        user &&
        itemsFiltered.map((item, index) => (
          <MenuNavSection key={index} title={item.title} pages={item.pages} />
        ))}
    </MenuNav>
  );
}
