import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { formatNumbersGraph } from '../../../../../../../utils/formats';
import axios from '../../../../../../../utils/axios';
import { POContext } from '../../../../../../../contexts/POContext';
import { CircularProgress } from '@mui/material';

export default function GraficoPO(props) {
  const { infoDataType, dataInfo, loading, t } = props;

  const graphData = dataInfo
    ? dataInfo['graph'][infoDataType].map((item) => ({
        name: t(item.name, { ns: 'maxion' }),
        data: item.data,
      }))
    : [];

  const options = {
    chart: {
      type: 'line',
    },
    yaxis: {
      forceNiceScale: true,
      tickAmount: 8,
      labels: {
        show: true,
        style: {
          colors: ['#4b4d4f'],
          fontSize: '13px',
          fontFamily: 'Inter, Arial, sans-serif',
          fontWeight: 500,
          cssClass: 'apexcharts-yaxis-label',
        },
        formatter: (value) => {
          return formatNumbersGraph(value);
        },
      },
    },
    xaxis: {
      categories: [
        t('JAN', { ns: 'maxion' }),
        t('FEV', { ns: 'maxion' }),
        t('MAR', { ns: 'maxion' }),
        t('ABR', { ns: 'maxion' }),
        t('MAI', { ns: 'maxion' }),
        t('JUN', { ns: 'maxion' }),
        t('JUL', { ns: 'maxion' }),
        t('AGO', { ns: 'maxion' }),
        t('SET', { ns: 'maxion' }),
        t('OUT', { ns: 'maxion' }),
        t('NOV', { ns: 'maxion' }),
        t('DEZ', { ns: 'maxion' }),
      ],
    },

    stroke: {
      curve: 'smooth',
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor: '#f1f1f1',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#017AD5', '#2D93E8', '#5AACFF', '#A5C6FF'],
      },
      formatter: function (val) {
        if (val === null) return '';
        const formattedNumber = formatNumbersGraph(val);
        return formattedNumber;
      },
    },
    colors: ['#017AD5', '#2D93E8', '#5AACFF', '#A5C6FF'],
  };

  return (
    <div>
      {loading ? (
        <CircularProgress size="30" />
      ) : (
        <Chart options={options} series={graphData} type="line" height="400" />
      )}
    </div>
  );
}
