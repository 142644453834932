import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useAuth from '../../../hooks/useAuth';

import { spacing } from '@mui/system';
import {
  Alert as MuiAlert,
  Button as MuiButton,
  TextField as MuiTextField,
} from '@mui/material';

const Alert = styled(MuiAlert)(spacing);

const Input = styled.input`
  display: block;
  width: 100%;
  margin: 10px 0;
  min-height: 45px;
  border-radius: 4px;
  min-width: 350px;
  padding-left: 8px;
  border: none;
  outline: none;
`;

const Button = styled(MuiButton)`
  width: 35%;
  float: right;
`;
const TextError = styled.div`
  width: fit-content;
  margin-top: -7px;
  padding: 0 5px;
  border-radius: 4px;
  font-size: 12px;
  color: #f44336;
  background-color: rgb(240, 240, 240);
`;

export default function SignInComponent({ t }) {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  const initialValues = {
    email: '',
    password: '',
    submit: false,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .max(255)
      .required(t('Campo obrigatório'), { ns: 'maxion' }),
    password: Yup.string()
      .max(255)
      .required(t('A senha é obrigatória'), { ns: 'maxion' }),
  });

  async function handleSubmit(values, { setErrors, setStatus, setSubmitting }) {
    try {
      await signIn(values.email, values.password);

      navigate('/');
    } catch (error) {
      const message = error.message || t('Algo deu errado', { ns: 'maxion' });

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert my={2} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <div>
            <Input
              name="email"
              placeholder={t('Usuário', { ns: 'maxion' })}
              value={values.email}
              // error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            {touched.email && errors.email && (
              <TextError>{errors.email}</TextError>
            )}
          </div>

          <div>
            <Input
              type="password"
              name="password"
              placeholder={t('Senha', { ns: 'maxion' })}
              value={values.password}
              // error={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            {touched.password && errors.password && (
              <TextError>{errors.password}</TextError>
            )}
          </div>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('Entrar', { ns: 'maxion' })}
          </Button>
        </form>
      )}
    </Formik>
  );
}
