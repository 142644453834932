import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import Loading from '../../../../../../../components/Loading';
import axios from '../../../../../../../utils/axios';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextError = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

export default function ModalCloneVersion({
  fetchVersions,
  openModalCloneVersion,
  setOpenModalCloneVersion,
  row,
  t,
}) {
  const [loading, setLoading] = useState(false);

  const initialValuesFormik = {
    name:
      row && row.name.length > 0
        ? t('Cópia', { ns: 'maxion' }) + ' - ' + row.name
        : '',
    observation:
      row && row.observations && row.observations.length > 0
        ? row.observations
        : '',
    version_id_to_clone: '',
    final_version_id: false,
  };

  async function handleClone(values) {
    setLoading(true);

    try {
      const { name, observation, base_date } = values;

      const body = {
        name: name,
        version_id_to_clone: row.id,
        observations: observation,
        final_version: false,
      };

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}po_versions/clone`,
        body
      );

      setOpenModalCloneVersion(false);
      setLoading(false);
      toast.success(t('Versão duplicada com sucesso', { ns: 'maxion' }));
      fetchVersions();
    } catch (error) {
      toast.error(t('Erro ao duplicar versão', { ns: 'maxion' }));
      console.log(`Error submiting data: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setOpenModalCloneVersion(false);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValuesFormik}
      onSubmit={handleClone}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
      }) => (
        <Dialog
          open={openModalCloneVersion}
          onClose={() => {}}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{t('Duplicar versão', { ns: 'maxion' })}</DialogTitle>
          <DialogContent>
            <Form onSubmit={handleClone}>
              <TextField
                sx={{ marginTop: '20px' }}
                margin="dense"
                id="name"
                label={t('Nome', { ns: 'maxion' })}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={values.name}
                variant="outlined"
                error={Boolean(touched.name && errors.name)}
              />
              {errors.observation &&
                touched.observation &&
                errors.observation && (
                  <TextError>{errors.observation}</TextError>
                )}
              <TextField
                margin="dense"
                id="observation"
                label={t('Observações', { ns: 'maxion' })}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                value={values.observation}
                variant="outlined"
                error={Boolean(touched.observation && errors.observation)}
              />
              {errors.observation &&
                touched.observation &&
                errors.observation && (
                  <TextError>{errors.observation}</TextError>
                )}
              <TextField
                disabled
                margin="dense"
                id="year"
                label={t('Ano', { ns: 'maxion' })}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={row && row.year}
                fullWidth
                variant="outlined"
                error={Boolean(touched.year && errors.year)}
              />
              <TextField
                disabled
                margin="dense"
                id="base"
                label={t('Base', { ns: 'maxion' })}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={row && row.base}
                fullWidth
                variant="outlined"
                error={Boolean(touched.base && errors.base)}
              />
            </Form>
          </DialogContent>

          {loading && <Loading />}
          <DialogActions>
            <Button
              disabled={loading}
              onClick={() => {
                handleClose();
              }}
            >
              {t('Cancelar', { ns: 'maxion' })}
            </Button>

            <Button type="submit" disabled={loading} onClick={handleSubmit}>
              {loading
                ? t('Enviando', { ns: 'maxion' })
                : t('Enviar', { ns: 'maxion' })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
