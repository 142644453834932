const baseItems = {
  buttons: [
    {
      title: 'Segmentos',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-ApresentacaoOperacional/Painel1',
      height: '2500px',
    },
    {
      title: 'Caminhão',
      type: 'tableau',
      index: 1,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-ApresentacaoOperacional/Painel2',
      height: '2000px',
    },
    {
      title: 'Ônibus',
      type: 'tableau',
      index: 2,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-ApresentacaoOperacional/Painel3',
      height: '2000px',
    },
    {
      title: 'Share de vendas',
      type: 'tableau',
      index: 3,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-ApresentacaoOperacional/Painel4',
      height: '2300px',
    },
  ],
};

export default baseItems;
