import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { generateTokenTableau } from '../../../../utils/jwtTableau';
import axios from '../../../../utils/axios';
import { withTranslation } from 'react-i18next';

const Box = styled.div`
  display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

function MontarIframe(iframe, token) {
  return (
    <div style={{ width: '100%' }}>
      <tableau-viz
        id="tableau-viz"
        src={iframe.src}
        width="100%"
        height={iframe.height}
        token={token}
        toolbar="hidden"
      />
    </div>
  );
}

function Content({ t, baseInfos, invisibleNumber }) {
  const [content, setContent] = useState([]);
  const [old, setOld] = useState(null);

  useEffect(() => {
    if (invisibleNumber !== undefined) {
      const validation = content.some(
        ({ page }) => page.index === invisibleNumber
      );

      if (old === null || old !== baseInfos[invisibleNumber].title) {
        setUsersNavigationLog(baseInfos[invisibleNumber].title);
        setOld(baseInfos[invisibleNumber].title);
      }

      if (validation === false) {
        const index = content.findIndex(({ page }) => page.type === 'slide');
        if (index > 0) content.splice(index, 1);

        let token = null;
        if (baseInfos[invisibleNumber].type === 'tableau') {
          token = generateTokenTableau();
        }

        setContent([
          ...content,
          {
            page: {
              index: invisibleNumber,
              item:
                baseInfos[invisibleNumber].type !== 'tableau'
                  ? baseInfos[invisibleNumber].element
                  : MontarIframe(baseInfos[invisibleNumber], token, t),
              type: baseInfos[invisibleNumber].type,
            },
          },
        ]);
      }
    }
  }, [content, baseInfos, invisibleNumber, old]);

  async function setUsersNavigationLog(textRoute) {
    try {
      const route = `OpenMax / Mercado / ${textRoute}`;

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}users_navigation_log`,
        {
          route: route,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {content.map((item, index) => {
        return (
          <Box
            key={index}
            isDisplay={item.page.index === invisibleNumber ? true : false}
          >
            {item.page.item}
          </Box>
        );
      })}
    </div>
  );
}

export default withTranslation('maxion')(Content);
