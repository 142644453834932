//Importações
import React from 'react';
import * as HoverCard from '@radix-ui/react-hover-card';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';
//Components
import MenuNavList from './MenuNavList';

const Box = styled.div`
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  height: 160px;
  min-width: 180px;
  max-width: 190px;
  padding: 20px 30px 20px 20px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  font-size: 23px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.blue_300};
    border: 2px solid ${({ theme }) => theme.colors.blue_300};
    opacity: 0.8;
  }

  p {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Arrow = styled(HoverCard.Arrow)`
  height: 10px;
  width: 20px;
  fill: rgba(7, 131, 194, 1);
  opacity: 0.9;
`;

function MenuNavSection({ pages, title, t }) {
  return (
    <>
      {pages.map((item, index) => (
        <HoverCard.Root key={index} openDelay={10} closeDelay={200}>
          <HoverCard.Trigger>
            <Box>
              <p>{t(`${title}`, { ns: 'maxion' })}</p>
            </Box>
          </HoverCard.Trigger>
          <HoverCard.Portal>
            <HoverCard.Content sideOffset={7}>
              <MenuNavList items={item.children} t={t} />
              <Arrow />
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      ))}
    </>
  );
}

export default withTranslation('maxion')(MenuNavSection);
