import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';

import Infobar from '../../../../components/Infobar/Infobar';
import ButtonsActions from './ButtonsActions';
import baseItems from './baseItems.js';
import Content from './Content';
import axios from '../../../../utils/axios';
import { Button } from '@mui/material';
import UnitSelectAsync from '../../../../components/UnitSelectAsync';
import useAuth from '../../../../hooks/useAuth.js';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

const Box = styled.div`
  display: flex;
  flex-direction: column;

  .select-customer {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.blue_200};
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`;

const TextError = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

function Clientes({ t }) {
  const { user } = useAuth();

  const [invisibleNumber, setInvisibleNumber] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    customer: Yup.object()
      .nullable()
      .required(t('Selecione o cliente', { ns: 'maxion' })),
  });

  const initialValues = {
    customer: null,
  };

  useEffect(() => {
    async function fetchFiles() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API}files/internal_market_clients`
        );
        const find = data.name === '[INTERNO] - MERCADO | CLIENTES';

        if (find) {
          const newArray = data.childs.map((item) => {
            return {
              name: item.name,
              id: item.id,
              parent_id: item.parent_id,
            };
          });

          setButtons(newArray);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchFiles();
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.rolesList.includes(7) && !user.rolesList.includes(4)) {
        setInvisibleNumber(2);
      }
    }
  }, [user]);

  useEffect(() => {
    async function fetchCustomers() {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_PUBLIC_API}criticas/grupo_cliente`,
          {
            search: '',
          }
        );

        setCustomers(data);
        setLoading(false);
      } catch (error) {
        console.log(`Erro fetching customers:  ${error}`);
        setLoading(false);
      }
    }

    fetchCustomers();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
  });

  const { values, errors, setFieldValue } = formik;

  return (
    <Box>
      <Helmet title={t('Clientes', { ns: 'maxion' })} />

      {baseItems.buttons.length > 1 && (
        <ButtonsActions
          t={t}
          buttonInfos={baseItems.buttons}
          invisibleNumber={invisibleNumber}
          setInvisibleNumber={setInvisibleNumber}
        />
      )}

      <Infobar
        t={t}
        title={t('Clientes', { ns: 'maxion' })}
        links={[
          {
            name: t('Mercado', { ns: 'maxion' }),
            href: '/marketplace/customers',
          },
        ]}
        text={t('Clientes', { ns: 'maxion' })}
      />

      <div className="select-customer">
        <p>{t('Selecione um cliente', { ns: 'maxion' })}</p>

        <div>
          <UnitSelectAsync
            sx={{ width: 300 }}
            size="small"
            name="customer"
            value={values.customer}
            label={t('Cliente', { ns: 'maxion' })}
            loading={loading}
            loadingText={t('Buscando clientes', { ns: 'maxion' })}
            options={customers}
            getOptionLabel={(option) => option.descricao_grupo_cliente}
            onChange={(e, value) => {
              setFieldValue('customer', value);
            }}
          />

          {errors.customer && <TextError>{errors.customer}</TextError>}
        </div>
      </div>

      <div>
        {values.customer !== null && (
          <React.Fragment>
            <Content
              t={t}
              baseInfos={baseItems.buttons}
              invisibleNumber={invisibleNumber}
              skClient={values.customer.sk}
            />

            {buttons.length > 0 && (
              <Buttons>
                {buttons.map((item, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    onClick={() =>
                      navigate(
                        `/marketing-content/details/${item.parent_id}/${
                          item.id
                        }?search=${
                          values.customer !== null
                            ? values.customer.descricao_grupo_cliente.split(
                                ' '
                              )[0]
                            : ''
                        }`
                      )
                    }
                  >
                    {t(item.name, { ns: 'maxion' })}
                  </Button>
                ))}
              </Buttons>
            )}
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}

export default withTranslation('maxion')(Clientes);
