import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import StyledDropzone from '../../../../../../components/StyledDropzone';
import Loading from '../../../../../../components/Loading';
import UnitSelectAsync from '../../../../../../components/UnitSelectAsync';
import axios from '../../../../../../utils/axios';
import useAuth from '../../../../../../hooks/useAuth';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextError = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

function ModalUpload({ open, setOpen, t, getFileList }) {
  const initValues = {
    arquivo: null,
    url: '',
  };

  const [loading, setLoading] = useState(false);
  const [isMargin, setIsMargin] = useState(true);
  const [dataFile, setDataFile] = useState({});
  const [initialValues, setInitialValues] = useState(initValues);
  const { user } = useAuth();

  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    async function loadFilesType() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API}upload_file/files-type`
        );

        const listItems = data.map((item) => {
          return {
            key: item.id,
            label: item.file_name,
            noco_id: item.nocodb_id,
          };
        });

        console.log('listItems', listItems);

        setFiles(listItems);
      } catch (error) {
        console.error('Error loading files:', error);
      } finally {
        setIsLoadingFiles(false);
      }
    }

    loadFilesType();
  }, []);

  const validationSchema = Yup.object().shape({
    arquivo: Yup.object().shape({
      key: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
      label: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
    }),
    url: Yup.string().required(`${t('Campo obrigatório', { ns: 'maxion' })}!`),
  });

  async function handleSubmit(
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) {
    console.log('values', values);
    setLoading(true);

    dataFile.append('file_id', values.arquivo.key);
    dataFile.append('nocodb_id', values.arquivo.noco_id);
    // dataFile.append('user', JSON.stringify(user));

    await axios
      .post(
        `${process.env.REACT_APP_PUBLIC_API}upload_file/importer`,
        dataFile,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        console.log('response', response);
        resetForm();
        toast.info(response.data.message);
        setOpen(false);
        getFileList();
      })
      .catch((error) => console.log('Error when uploading', error))
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClose(resetForm) {
    setOpen(false);
    resetForm();
    setInitialValues(initValues);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        handleReset,
        isSubmitting,
      }) => (
        <Dialog
          open={open}
          onClose={() => handleClose(handleReset)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>{t('Pricing', { ns: 'maxion' })}</DialogTitle>

          <DialogContent>
            <Form>
              <div className="item" style={{ marginBottom: 20 }}>
                <p>{t('Arquivo', { ns: 'maxion' })}*</p>
                <div>
                  <UnitSelectAsync
                    limitTags={0}
                    size="small"
                    name="arquivo"
                    value={values.arquivo}
                    label={t('Arquivo', { ns: 'maxion' })}
                    loading={isLoadingFiles}
                    disabled={isLoadingFiles}
                    loadingText={t('Carregando', { ns: 'maxion' })}
                    options={files ? files : []}
                    getOptionLabel={(option) => option.label}
                    onBlur={handleBlur}
                    onChange={(event, value) => setFieldValue('arquivo', value)}
                    isOptionEqualToValue={(option, value) =>
                      option.key === value.key
                    }
                    disableCloseOnSelect
                  />
                </div>
              </div>
              <StyledDropzone
                isEditing={false}
                setFieldValue={setFieldValue}
                setFormData={setDataFile}
                setIsMargin={setIsMargin}
                accept={'excel'}
              />
              <TextField
                style={{ marginTop: 20 }}
                margin="dense"
                id="url"
                label={t('Arquivo', { ns: 'maxion' })}
                type="text"
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                fullWidth
                variant="outlined"
                error={values.url === '' && Boolean(errors.url)}
              />

              {errors.url && <TextError>{errors.url}</TextError>}

              {/*<pre>{JSON.stringify(dataFile, null, 4)}</pre>*/}
              <pre>{JSON.stringify(values, null, 4)}</pre>
              <pre>{JSON.stringify(errors, null, 4)}</pre>
            </Form>
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={() => handleClose(handleReset)}>
              {t('Cancelar', { ns: 'maxion' })}
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {loading
                ? t('Enviando', { ns: 'maxion' })
                : t('Enviar', { ns: 'maxion' })}
            </Button>
          </DialogActions>

          {loading && <Loading />}
        </Dialog>
      )}
    </Formik>
  );
}

export default withTranslation('maxion')(ModalUpload);
