import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import axios from '../../../../../../../utils/axios.js';
import { Tooltip } from '@mui/material';

import Loading from '../../../../../../../components/Loading.js';

const Overflow = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;

const BoxTable = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 1050px;

  .header-column {
    display: flex;
    flex-direction: column;
    width: 10%;
    min-width: 130px;

    .segment-type {
      width: 100%;
      background-color: #f4f7fc;
      color: ${({ theme }) => theme.colors.blue_300};
      font-weight: 600;
      font-size: 18px;
      height: 80px !important;
    }

    .item {
      justify-content: left !important;
      padding-left: 8px !important;
    }
  }

  .content-row {
    display: flex;
    flex-direction: row;
    width: 90%;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
`;

const BoxTableYear = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.blue_300};
  width: 35%;

  .header {
    height: 35px;
    line-height: 35px;
    background-color: #c2ddf0;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue_300};
    width: 100%;
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    .evolution {
      min-width: 70px;
    }

    th {
      height: 45px;
      background-color: #f9f8fd;
      color: ${({ theme }) => theme.colors.blue_200};
      font-size: 14px;
    }

    td {
      background-color: #fff;
      height: 50px;
      line-height: 35px;
      text-align: center;
      overflow: hidden;
      font-size: 14px;
    }
  }
`;

const segments = [
  { label: 'Caminhões', key: 'trucks' },
  { label: 'Ônibus', key: 'bus' },
  { label: 'Pickups', key: 'pickups' },
];

const rows = [
  { label: 'CLIENTE', key: 'CLIENTE' },
  { label: 'EMPLACAMENTO', key: 'FRAGA' },
  { label: 'IHS', key: 'IHS' },
  { label: 'LMC', key: 'LMC' },
  { label: 'SINDIPECAS', key: 'SINDIPECAS' },
];

const columns = [
  { label: 'Veículos', key: 'quantity' },
  { label: 'Dias úteis', key: 'work_days_number' },
  { label: 'JPD', key: 'jpd' },
  { label: 'Share', key: 'share' },
];

function formatNumber(number) {
  if (number === null || number === undefined || isNaN(number)) {
    return '*';
  }

  const roundedNumber = Math.round(number);
  const formattedNumber = new Intl.NumberFormat('pt-BR').format(roundedNumber);
  const isGiantNumber = formattedNumber.split('.');

  if (isGiantNumber.length >= 3) {
    if (number >= 1000000000 || number <= -1000000000) {
      return (
        <Tooltip title={formattedNumber}>
          <span style={{ cursor: 'default' }}>
            {(number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'}
          </span>
        </Tooltip>
      );
    }

    if (number >= 1000000 || number <= -1000000) {
      return (
        <Tooltip title={formattedNumber}>
          <span style={{ cursor: 'default' }}>
            {(number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'}
          </span>
        </Tooltip>
      );
    }
  }

  return formattedNumber;
}

function TableYear({ t, data, segment, year, columns, rows, arrayYears }) {
  const isFinalYear = arrayYears[arrayYears.length - 1] === year;

  function calculateVehicleQuantityEvolution(row) {
    const penultimateYearKey = arrayYears[arrayYears.length - 2].key;
    const lastYearKey = arrayYears[arrayYears.length - 1].key;

    const penultimateValue =
      data[segment.key]?.[row.key]?.[penultimateYearKey]?.['quantity'] || 0;
    const lastValue =
      data[segment.key]?.[row.key]?.[lastYearKey]?.['quantity'] || 0;

    if (penultimateValue === 0) {
      return '*';
    }

    const evolution = ((lastValue - penultimateValue) / penultimateValue) * 100;
    return `${Math.round(evolution)}%`;
  }

  return (
    <BoxTableYear>
      <div className="header">{year?.label || ''}</div>

      <table>
        <thead>
          <tr>
            {columns.map((column, indexColumn) => (
              <th key={`column-${indexColumn}`}>
                {t(column.label, { ns: 'maxion' })}
              </th>
            ))}

            {isFinalYear && (
              <th className="evolution">{t('Evolução', { ns: 'maxion' })}</th>
            )}
          </tr>
        </thead>

        <tbody>
          {rows.map((row, indexRow) => (
            <tr key={`row-${indexRow}`}>
              {columns.map((column, indexColumn) => {
                const value =
                  data[segment.key]?.[row.key]?.[year.key]?.[column.key];

                return (
                  <td key={`row-${indexRow}-column-${indexColumn}`}>
                    {formatNumber(value)}
                    {Boolean(value) && column.key === 'share' && '%'}
                  </td>
                );
              })}

              {isFinalYear && <td>{calculateVehicleQuantityEvolution(row)}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </BoxTableYear>
  );
}

export default function TablesBySegment({
  t,
  versionId,
  customerId,
  versionYear,
  refreshTables,
  onInnitialize,
  refreshTablesBySegment,
}) {
  const [marketInfo, setMarketInfo] = useState({});
  const [loadingFetchInfo, setLoadingFetchInfo] = useState(true);

  const fetchMarketInfo = useCallback(async () => {
    try {
      setLoadingFetchInfo(true);

      const { data: info } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_aop/get_market_info/version_id/${versionId}/sk_grupo_cliente/${customerId}`
      );

      setMarketInfo(info);
      setLoadingFetchInfo(false);
    } catch (error) {
      console.log(error);
      setLoadingFetchInfo(false);
    }
  }, [versionId, customerId]);

  useEffect(() => {
    if (versionId && customerId) {
      fetchMarketInfo();
    }
  }, [versionId, customerId, fetchMarketInfo]);

  useEffect(() => {
    if (refreshTables) {
      fetchMarketInfo();
      onInnitialize();
    }
  }, [refreshTables, onInnitialize, fetchMarketInfo, refreshTablesBySegment]);

  useEffect(() => {
    if (refreshTablesBySegment) {
      fetchMarketInfo();
      onInnitialize();
    }
  }, [refreshTables, onInnitialize, fetchMarketInfo, refreshTablesBySegment]);

  const years = useMemo(() => {
    const yearNumber = Number(versionYear) || '*';

    return [
      {
        label: yearNumber !== '*' ? (yearNumber - 2).toString() : '*',
        key: yearNumber !== '*' ? `${yearNumber - 2}.0` : undefined,
      },
      {
        label: yearNumber !== '*' ? (yearNumber - 1).toString() : '*',
        key: yearNumber !== '*' ? `${yearNumber - 1}.0` : undefined,
      },
      {
        label: yearNumber !== '*' ? yearNumber.toString() : '*',
        key: yearNumber !== '*' ? `${yearNumber}.0` : undefined,
      },
    ];
  }, [versionYear]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
      {segments.map((segment, indexSegment) => {
        return (
          <Overflow key={`segment-${indexSegment}`}>
            <BoxTable>
              <div className="header-column segment-type">
                <div className="segment-type item">
                  {t(segment.label, { ns: 'maxion' })}
                </div>

                <div>
                  {rows.map((row, indexRow) => (
                    <div className="item" key={`row-${indexRow}`}>
                      {t(row.label, { ns: 'maxion' })}
                    </div>
                  ))}
                </div>
              </div>

              <div className="content-row">
                {years.map((year, indexValues, arrayYears) => (
                  <React.Fragment key={indexValues}>
                    <TableYear
                      data={marketInfo}
                      segment={segment}
                      year={year}
                      arrayYears={arrayYears}
                      rows={rows}
                      columns={columns}
                      t={t}
                    />
                  </React.Fragment>
                ))}
              </div>

              {loadingFetchInfo && <Loading />}
            </BoxTable>
          </Overflow>
        );
      })}
    </div>
  );
}
