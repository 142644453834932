import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { POContext } from '../../../../../../../contexts/POContext';

import { Checkbox, Tooltip, Switch } from '@mui/material';
import MakeForecastVolume from './MakeForecastVolume';
import MakeForecastPreco from './MakeForecastPreco';
import Loading from '../../../../../../../components/Loading';
import HoverCardProduct from './HoverCard';

const Box = styled.div`
  margin-top: 50px;

  .no-items {
    padding: 15px;
  }
`;

const BoxSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
`;

const Overflow = styled.div`
  position: relative;
  overflow-x: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 1550px;

  thead {
    background-color: #c8dcee;
  }

  th {
    color: #0783c2;
    text-align: center;
    padding: 5px;
    font-size: 14px;
  }

  td {
    text-align: center;
    color: ${({ theme }) => theme.colors.gray_500};
    padding: 5px;
    font-size: 14px;
  }

  .row-light-blue {
    background-color: #f4f8fc;
  }

  .is-product-checkbox {
    display: flex;
    gap: 3px;
    align-items: center;
    text-align: left;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  button {
    background-color: transparent;
    border: 1px solid #3182ce;
    border-radius: 6px;
    color: #3182ce;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover:not(:disabled) {
      background-color: #3182ce;
      color: #fff;
    }

    &:disabled {
      pointer-events: none;
    }

    &.active {
      background-color: #3182ce;
      color: #fff;
      pointer-events: none;
    }
  }
`;

const ITEMS_PER_PAGE = 10;
const MAX_PAGE_BUTTONS = 8;

const colsVolume = [
  { label: 'Produto', width: '100%' },
  { label: 'Base', width: '5%' },
  { label: 'Percentual Mercado', width: '5%' },
  { label: 'Total Mercado', width: '5%' },
  { label: 'Total Criticado', width: '5%' },
  { label: 'JAN', width: '5%' },
  { label: 'FEV', width: '5%' },
  { label: 'MAR', width: '5%' },
  { label: 'ABR', width: '5%' },
  { label: 'MAI', width: '5%' },
  { label: 'JUN', width: '5%' },
  { label: 'JUL', width: '5%' },
  { label: 'AGO', width: '5%' },
  { label: 'SET', width: '5%' },
  { label: 'OUT', width: '5%' },
  { label: 'NOV', width: '5%' },
  { label: 'DEZ', width: '5%' },
];

const colsPreco = [
  { label: 'Produto', width: '20%' },
  { label: 'JAN', width: '6.5%' },
  { label: 'FEV', width: '6.5%' },
  { label: 'MAR', width: '6.5%' },
  { label: 'ABR', width: '6.5%' },
  { label: 'MAI', width: '6.5%' },
  { label: 'JUN', width: '6.5%' },
  { label: 'JUL', width: '6.5%' },
  { label: 'AGO', width: '6.5%' },
  { label: 'SET', width: '6.5%' },
  { label: 'OUT', width: '6.5%' },
  { label: 'NOV', width: '6.5%' },
  { label: 'DEZ', width: '6.5%' },
];

const numberOptions = {
  maximumFractionDigits: 2,
  style: 'decimal',
};

function formatNumber(number) {
  if (number === null || number === undefined || isNaN(number)) {
    return '*';
  }

  const numberValue = new Intl.NumberFormat('pt-BR', numberOptions).format(
    number
  );

  if (Math.abs(number) >= 1000000) {
    let formattedNumber;
    let tooltipTitle;

    if (number >= 1000000000 || number <= -1000000000) {
      formattedNumber =
        (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
      tooltipTitle = numberValue;
    } else {
      formattedNumber = (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      tooltipTitle = numberValue;
    }

    return (
      <Tooltip title={tooltipTitle}>
        <span style={{ cursor: 'default' }}>{formattedNumber}</span>
      </Tooltip>
    );
  }

  return numberValue;
}

function formatPercent(number) {
  if (number === null || number === undefined || isNaN(number)) {
    return '*';
  }

  const numberValue = new Intl.NumberFormat('pt-BR', numberOptions).format(
    number
  );

  return `${numberValue}%`;
}

function formatString(str, comprimentoMaximo) {
  if (str.length <= comprimentoMaximo) {
    return str;
  } else {
    const subString = str.substring(0, comprimentoMaximo) + '...';

    return (
      <Tooltip title={str}>
        <span style={{ cursor: 'default' }}>{subString}</span>
      </Tooltip>
    );
  }
}

export default function ResultsFiltered({ data, setData, loadingData, t }) {
  const [checked, setChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
  const selectedProducts = data.filter((product) => product.checked === true);

  const { metricaPO } = useContext(POContext);

  const cols = metricaPO === 'volume' ? colsVolume : colsPreco;
  const isBaseOrCritic = checked ? 'criticaValues' : 'baseValues';

  function handlePageClick(event) {
    const nextPage = parseInt(event.target.textContent);
    setCurrentPage(nextPage);
  }

  const pageData = data.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const renderPageButtons = () => {
    let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_BUTTONS / 2));
    let endPage = Math.min(totalPages, startPage + MAX_PAGE_BUTTONS - 1);

    const pageButtons = [];

    if (startPage > 1) {
      pageButtons.push(
        <button onClick={() => setCurrentPage(currentPage - MAX_PAGE_BUTTONS)}>
          ...
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={handlePageClick}
          disabled={i === currentPage}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      pageButtons.push(
        <button
          key={pageButtons.length + 1}
          onClick={() => setCurrentPage(currentPage + MAX_PAGE_BUTTONS)}
        >
          ...
        </button>
      );
    }

    return pageButtons.length > 1 ? pageButtons : null;
  };

  function handleChange(item, checked, selectAll) {
    if (selectAll) {
      const newArray = data.map((product) => ({
        ...product,
        checked: checked,
      }));

      setData(newArray);
      return;
    }

    const newArray = data.map((product) =>
      product.operational_plan_agg_id === item.operational_plan_agg_id
        ? { ...product, checked: checked }
        : product
    );

    setData(newArray);
  }

  return (
    <Box>
      <div>
        <h2>{t('Resultados filtrados', { ns: 'maxion' })}</h2>

        <BoxSwitch>
          <p>{t('Base', { ns: 'maxion' })}</p>
          <Switch
            checked={checked}
            onChange={() => setChecked(!checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <p>{t('Crítica', { ns: 'maxion' })}</p>
        </BoxSwitch>
      </div>

      <Overflow>
        <Table>
          <thead>
            <tr>
              {cols.map((column, index) => (
                <th
                  key={index}
                  className={index === 0 ? 'is-product-checkbox' : ''}
                  style={{ width: column.width }}
                >
                  {index === 0 && (
                    <Checkbox
                      size="small"
                      checked={
                        data.length > 0 &&
                        data.every((product) => product.checked)
                      }
                      onChange={(e) =>
                        handleChange(null, e.target.checked, true)
                      }
                    />
                  )}
                  {t(`${column.label}`, { ns: 'maxion' })}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.length === 0 ? (
              <p className="no-items">{t('Sem itens', { ns: 'maxion' })}</p>
            ) : (
              pageData.map((product, indexProduct) => (
                <tr
                  key={indexProduct}
                  className={indexProduct % 2 !== 0 ? 'row-light-blue' : ''}
                >
                  <td className="is-product-checkbox">
                    <Checkbox
                      size="small"
                      checked={product.checked}
                      onChange={(e) =>
                        handleChange(product, e.target.checked, false)
                      }
                    />

                    <HoverCardProduct
                      client={`${product.nome_cliente} - ${product.codigo_cliente}`}
                      product={product.descricao_produto}
                      t={t}
                    />
                  </td>

                  {metricaPO === 'volume' && (
                    <React.Fragment>
                      <td>{formatNumber(product?.volume_total_base)}</td>
                      <td>
                        {formatPercent(product?.original_market_pct_evolution)}
                      </td>
                      <td>{formatNumber(product?.volume_total_market)}</td>
                      <td>{formatNumber(product?.volume_total_critic)}</td>
                    </React.Fragment>
                  )}

                  {Array.from({ length: 12 }).map((_, indexValue) => (
                    <td key={indexValue}>
                      {formatNumber(product?.[isBaseOrCritic]?.[indexValue])}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </Table>

        {loadingData && <Loading />}
      </Overflow>

      <Pagination>{renderPageButtons()}</Pagination>

      {metricaPO === 'volume' ? (
        <MakeForecastVolume t={t} selectedProducts={selectedProducts} />
      ) : (
        <MakeForecastPreco t={t} selectedProducts={selectedProducts} />
      )}
    </Box>
  );
}
