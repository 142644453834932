import React, { useEffect, useState } from 'react';
import { generateTokenTableau } from '../../../../utils/jwtTableau';
import axios from '../../../../utils/axios';
import { withTranslation } from 'react-i18next';

function MontarIframe(item, token, skClient, t) {
  return (
    <div style={{ width: '100%' }}>
      <tableau-viz
        id={`tableau-viz`}
        src={item.src}
        width="100%"
        height={item.height}
        token={token}
        toolbar="hidden"
      >
        <viz-parameter name="sk_cliente" value={skClient} />
      </tableau-viz>
    </div>
  );
}

function Content({ t, baseInfos, invisibleNumber, skClient }) {
  const [newArray, setNewArray] = useState([]);
  const [oldInvisibleNumber, setOldInvisibleNumber] = useState(null);

  useEffect(() => {
    if (invisibleNumber !== undefined) {
      //Logs
      if (
        oldInvisibleNumber === null ||
        invisibleNumber !== oldInvisibleNumber
      ) {
        setOldInvisibleNumber(invisibleNumber);
        usersNavigationLog(baseInfos[invisibleNumber].title);
      }

      if (newArray.length === 0) {
        const array = baseInfos.map((item) => {
          const isActive = item.index === invisibleNumber;
          const sk = isActive ? skClient : null;
          const token = isActive ? generateTokenTableau() : null;
          const iframe = MontarIframe(item, token, sk, t);

          return {
            title: item.title,
            index: item.index,
            iframe: iframe,
            token: null,
            sk: null,
          };
        });

        setNewArray(array);
      } else {
        const findIndex = newArray.findIndex(
          (item) => item.index === baseInfos[invisibleNumber].index
        );
        const infos = baseInfos[invisibleNumber];

        if (findIndex !== -1) {
          const isChangedSk = newArray[findIndex].sk !== skClient;

          if (isChangedSk) {
            const currentContainer = document.getElementById(
              `tableau-viz-${findIndex}`
            );
            currentContainer.innerHTML = '';

            const token = generateTokenTableau();

            const newTableauViz = document.createElement('tableau-viz');
            newTableauViz.id = `tableau-viz-${findIndex}`;
            newTableauViz.setAttribute('src', infos.src);
            newTableauViz.setAttribute('width', '100%');
            newTableauViz.setAttribute('height', infos.height);
            newTableauViz.setAttribute('token', token);
            newTableauViz.setAttribute('toolbar', 'hidden');

            const vizParameter = document.createElement('viz-parameter');
            vizParameter.setAttribute('name', 'sk_cliente');
            vizParameter.setAttribute('value', skClient);

            currentContainer.appendChild(newTableauViz);
            newTableauViz.appendChild(vizParameter);

            const array = [...newArray];
            array[findIndex].sk = skClient;
            setNewArray(array);
          }
        }
      }
    }
  }, [baseInfos, invisibleNumber, skClient, newArray, oldInvisibleNumber]);

  async function usersNavigationLog(textRoute) {
    try {
      const route = `OpenMax / Mercado / Clientes / ${textRoute}`;

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}users_navigation_log`,
        {
          route: route,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {newArray.map((item, index) => (
        <div
          id={`tableau-viz-${item.index}`}
          key={index}
          style={{
            display: invisibleNumber === index ? 'block' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {item.iframe}
        </div>
      ))}
    </div>
  );
}

export default withTranslation('maxion')(Content);
