import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TableReportMonth from './TableReportMonth';

export default function ModalReportMonth({ t, openModal, setOpenModal }) {
  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)} fullScreen>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {t('Simulador Mensal do Plano Operacional', { ns: 'maxion' })}
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            <CloseRoundedIcon
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TableReportMonth t={t} />
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
            marginBottom: 10,
            width: '100%',
          }}
        >
          <Button
            color="primary"
            autoFocus
            onClick={async () => {
              setOpenModal(false);
            }}
            type="button"
            variant="contained"
            style={{ width: 120 }}
          >
            {t('Voltar', { ns: 'maxion' })}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
