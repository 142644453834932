import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';
import { CircularProgress, FormControl } from '@mui/material';
import { NavLink } from 'react-router-dom';
import axios from '../../../../../../utils/axios';
import { withTranslation } from 'react-i18next';
import useAuth from '../../../../../../hooks/useAuth';
import { checkPermission } from '../../../../../../utils/permissions';

import { Divider, Breadcrumbs, Button as MuiButton } from '@mui/material';
import { spacing } from '@mui/system';
import OPVersionTable from './components/OPVersionTable';
import { ClosedDate } from '../../../../Critica/CenarioDeClientes/styles';
import { useFormik } from 'formik';
import UnitSelectAsync from '../../../../../../components/UnitSelectAsync';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.gray_500};
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

const Button = styled(MuiButton)(spacing);

function OPVersion0({ t }) {
  const [openModalNewVersion, setOpenModalNewVersion] = useState(false);
  const [dataFechamento, setDataFechamento] = useState(null);
  const [years, setYears] = useState([]);
  const [loadingYears, setLoadingYears] = useState(true);
  const [filteredYears, setFilteredYears] = useState([]);

  const { user } = useAuth();

  const userHasPermissionToManage = user && checkPermission(user, [], [9]);

  async function fetchClosingDate() {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}parameters/name/MES_ULTIMO_FECHAMENTO`
      );

      if (data.value === null) return;

      const split = data.value.split('-');

      const year = split[0];
      const month = split[1];

      setDataFechamento({ month, year });
    } catch (error) {
      console.log(`Error fetching closing date: ${error}`);
    }
  }

  const fetchYears = async () => {
    setLoadingYears(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_versions/years`
      );
      setYears(data || []);
    } catch (error) {
      console.log(`Error fetching years: ${error}`);
      setYears([]);
    } finally {
      setLoadingYears(false);
    }
  };

  useEffect(() => {
    fetchClosingDate();
    fetchYears();
  }, []);

  const formikInitialValues = {
    year: [],
  };

  const handleFilter = (values) => {
    setFilteredYears(values.year);
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: handleFilter,
  });

  useEffect(() => {}, [filteredYears]);

  const { values, handleBlur, setFieldValue, handleChange, handleSubmit } =
    formik;

  return (
    <div style={{ width: '100%' }}>
      <Helmet title={t('Listagem de versões', { ns: 'maxion' })} />

      <Infobar t={t}>
        <div>
          <h2> {t('Listagem de versões', { ns: 'maxion' })}</h2>

          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
            <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

            <p>{t('Números Maxion', { ns: 'maxion' })} </p>

            <p>{t('Plano operacional', { ns: 'maxion' })}</p>

            <span>{t('Listagem de versões', { ns: 'maxion' })}</span>
          </Breadcrumbs>
        </div>

        <Divider className="divider" />

        <div
          style={{ width: '100%', marginLeft: '20px', backgroundColor: '#fff' }}
        >
          <div
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '40px',
            }}
          >
            {userHasPermissionToManage ? (
              <Button
                variant="contained"
                color="secondary"
                mb={5}
                onClick={() => setOpenModalNewVersion(true)}
              >
                {t('Nova versão', { ns: 'maxion' })}
              </Button>
            ) : null}
            <ClosedDate style={{ marginLeft: 'auto', gap: '5px' }}>
              {`${t('Data de fechamento', { ns: 'maxion' })}: `}
              {dataFechamento ? (
                `${dataFechamento.month}/${dataFechamento.year}`
              ) : (
                <CircularProgress size={15} />
              )}
            </ClosedDate>
            <FormControl
              size="md"
              variant="outlined"
              style={{
                display: 'flex',
                gap: '20px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <UnitSelectAsync
                sx={{ width: 200 }}
                name="year"
                value={values.year === '' ? null : values.year}
                label={t('Ano', { ns: 'maxion' })}
                loading={loadingYears}
                disabled={loadingYears}
                loadingText="Buscando anos..."
                options={years}
                getOptionLabel={(option) => option.toString()}
                onBlur={handleBlur}
                onChange={(event, value) => setFieldValue('year', value)}
                isOptionEqualToValue={(option, value) => option === value}
              />
              <Button
                sx={{ height: '40px', width: '140px' }}
                variant="outlined"
                type="submit"
                onClick={formik.handleSubmit}
              >
                {t('Filtrar', { ns: 'maxion' })}
              </Button>
            </FormControl>
          </div>

          <OPVersionTable
            t={t}
            style={{ margin: '10px' }}
            openModalNewVersion={openModalNewVersion}
            setOpenModalNewVersion={setOpenModalNewVersion}
            filteredYears={filteredYears}
          />
        </div>
      </Infobar>
    </div>
  );
}

export default withTranslation(['maxion'])(OPVersion0);
