import styled from '@emotion/styled';
import { formatNumber } from '../../../../../../../utils/formats';

const TableDiv = styled.div`
  .table-container {
    margin: 20px;
    height: 100%;
    overflow-y: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  table tr:last-child .border-bottom {
    border-bottom: 2px solid #0783c2;
  }

  th {
    color: #0783c2;
    background-color: #c2ddf0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    font-size: 14px;
  }

  thead th {
    background-color: #c2ddf0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .row-title th {
    background-color: #f4f7fc;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody tr:nth-child(even) {
    background-color: #f4f8fc;
  }

  .border-left {
    border-left: 2px solid #0783c2;
  }

  .border-right {
    border-right: 2px solid #0783c2;
  }

  .border-top {
    border-top: 2px solid #0783c2;
  }
`;

export default function POTabela(props) {
  const { infoDataType, dataInfo, t } = props;

  function formatString(cell) {
    const regex = /\d+ \+ \d+/;
    const dynamicPart = cell.match(regex);

    const matchesToRegex = regex.test(cell);

    if (matchesToRegex) {
      const fixedPart = cell.split(dynamicPart);

      const stringBuilded = `${t(fixedPart[0], {
        ns: 'maxion',
      })}${dynamicPart}${t(fixedPart[1], { ns: 'maxion' })} `;

      return stringBuilded;
    }

    return t(cell, { ns: 'maxion' });
  }

  return (
    <TableDiv>
      <div className="table-container">
        <table className="main">
          <thead>
            <tr className="header">
              {dataInfo &&
                dataInfo['table'][infoDataType]['columns'].map(
                  (item, index) => {
                    return (
                      <th key={index}>
                        {t(item, { ns: 'maxion' }).toUpperCase()}
                      </th>
                    );
                  }
                )}
            </tr>
          </thead>
          <tbody>
            {dataInfo &&
              dataInfo['table'][infoDataType]['rows'].map((row, rowIndex) => {
                return (
                  <tr className="row-title" key={rowIndex}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <td key={cellIndex}>
                          {typeof cell === 'number'
                            ? formatNumber(cell)
                            : formatString(cell)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </TableDiv>
  );
}
