const baseItems = {
  buttons: [
    {
      title: 'Emplacamentos',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-Clientes/Painel1',
      height: '1400px',
      roles: [4],
    },
    {
      title: 'Projeções',
      type: 'tableau',
      index: 1,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-Clientes/Painel2',
      height: '1200px',
      roles: [4],
    },
    {
      title: 'Programação BR',
      type: 'tableau',
      index: 2,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-Clientes/Painel3',
      height: '1600px',
      roles: [7],
    },
    {
      title: 'Plantas',
      type: 'tableau',
      index: 3,
      src: 'https://us-east-1.online.tableau.com/t/iochpemaxion/views/Mercado-Clientes/Painel4',
      height: '1000px',
      roles: [4],
    },
  ],
};

export default baseItems;
