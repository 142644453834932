import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

const NumerosMaxion = [
  {
    href: '/',
    icon: AllInclusiveIcon,
    title: 'Números Maxion',
    children: [
      {
        href: '/numbers-maxion/sales-forecast',
        title: 'Previsão de faturamento',
        permissions: [],
        roles: [5, 6],
      },
      {
        href: '/numbers-maxion/operational-plan',
        title: 'Plano operacional',
        permissions: [],
        roles: [9, 10, 11],
      },
      // {
      //   href: "/numbers-maxion/new-business",
      //   title: "Novos negócios",
      //   permissions: [],
      //   roles: [5, 6],
      // },
    ],
  },
];

const Mercado = [
  {
    href: '/',
    icon: WarehouseOutlinedIcon,
    title: 'Mercado',
    children: [
      {
        href: '/marketplace/registration',
        title: 'Emplacamento diário',
        permissions: [],
        roles: [2],
      },
      {
        href: '/marketplace/operational-presentation',
        title: 'Apresentação operacional',
        permissions: [],
        roles: [2],
      },
      {
        href: '/marketplace/automotive-data-br',
        title: 'Dados Automotivos BR',
        permissions: [],
        roles: [3],
      },
      {
        href: '/marketplace/historic-data-br',
        title: 'Dados Históricos BR',
        permissions: [],
        roles: [2],
      },
      {
        href: '/marketplace/forecasts',
        title: 'Forecasts ICE',
        permissions: [],
        roles: [3],
      },
      {
        href: '/marketplace/market-evs',
        title: 'Forecasts EV',
        permissions: [],
        roles: [3],
      },
      {
        href: '/marketplace/cycle-plan-vehicles',
        title: 'Cycle Plan',
        permissions: [],
        roles: [3],
      },
      {
        href: '/marketplace/vehicle-price-br-fipe',
        title: 'Preços Veículos BR - FIPE',
        permissions: [],
        roles: [3],
      },
      {
        href: '/marketplace/macroeconomic-data',
        title: 'Dados Macroeconômicos',
        permissions: [],
        roles: [2],
      },
      {
        href: '/marketplace/competitors',
        title: 'Concorrentes',
        permissions: [],
        roles: [4],
      },
      {
        href: '/marketplace/customers',
        title: 'Clientes',
        permissions: [],
        roles: [4, 7],
      },
      {
        href: '/marketplace/infos',
        title: 'Informações Financeiras',
        permissions: [],
        roles: [7],
      },
    ],
  },
];

const MaxionMercado = [
  {
    href: '/',
    icon: AnalyticsOutlinedIcon,
    title: 'Maxion X Mercado',
    children: [
      {
        href: '/maxion-marketplace/market-mapping',
        title: 'Mapeamento Mercado',
        permissions: [],
        roles: [8],
      },
      {
        href: '/maxion-marketplace/maxion-recipe-alignment',
        title: 'Alinhamento Receita Maxion',
        permissions: [],
        roles: [8],
      },
      {
        href: '/maxion-marketplace/customer-product-matrix',
        title: 'Matriz Cliente Produto',
        permissions: [],
        roles: [8],
      },
    ],
  },
];

const EstrategiasMateriais = [
  {
    href: '/',
    icon: DataThresholdingOutlinedIcon,
    title: 'Conteúdos Marketing',
    children: [
      {
        href: '/marketing-content/explore',
        title: 'Explorar',
        permissions: [],
        roles: [4],
      },
    ],
  },
];

const Administrativo = [
  {
    href: '/admin',
    icon: AdminPanelSettingsOutlinedIcon,
    title: 'Administrativo',
    children: [
      {
        href: '/admin/department',
        title: 'Departamentos',
        permissions: [],
        roles: [1],
      },
      {
        href: '/admin/user',
        title: 'Usuários',
        permissions: [],
        roles: [1],
      },
      // {
      //   href: "/admin/role",
      //   title: "Roles",
      //   permissions: [],
      //   roles: [1],
      // },
      {
        href: '/admin/closing-date',
        title: 'Data fechamento',
        permissions: [],
        roles: [1],
      },
      {
        href: '/admin/monitoring',
        title: 'Monitoramento de uso',
        permissions: [],
        roles: [1],
      },
    ],
  },
];

const navItems = [
  {
    title: 'Números Maxion',
    pages: NumerosMaxion,
    permissions: [],
    roles: [],
    active: true,
  },
  {
    title: 'Mercado',
    pages: Mercado,
    permissions: [],
    roles: [],
  },
  // {
  //   title: "Maxion X Mercado",
  //   pages: MaxionMercado,
  //   permissions: [],
  //   roles: [],
  // },
  {
    title: 'Conteúdos Marketing',
    pages: EstrategiasMateriais,
    permissions: [],
    roles: [],
  },
  {
    title: 'Administrativo',
    pages: Administrativo,
    permissions: [],
    roles: [],
  },
];

export default navItems;
