const baseItems = {
  page: 'Mercado',
  buttons: [
    // {
    //     title: "Overview",
    //     type: "tableau",
    //     index: 0,
    //     src: 'https://public.tableau.com/views/MAXION2-JustDashes-2/Painel3?:language=pt-BR&:display_count=n&:origin=viz_share_link?:embed=y&:showVizHome=no'
    // },
    {
      title: 'Categoria',
      type: 'tableau',
      index: 0,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-FRAGA/Painel3',
      height: '2000px',
    },
    {
      title: 'Combustível',
      type: 'tableau',
      index: 1,
      src: 'https://us-east-1.online.tableau.com/#/site/iochpemaxion/views/Mercado-FRAGA/Painel4',
      height: '2000px',
    },
    // {
    //     title: "Impressão",
    //     type: "tableau",
    //     index: 1,
    //     src: 'https://public.tableau.com/views/MAXION2-JustDashes-3/Painel4?:language=pt-BR&:display_count=n&:origin=viz_share_link?:embed=y&:showVizHome=no'
    // }
  ],
};

export default baseItems;
