//Importações
import React from 'react';
import styled from '@emotion/styled';
//Imagens
import LogoMaxion from '../../assets/logoopenmax_totalwhite.png';
import BackgroundHeader from '../../assets/top-bar.webp';
//Components
import LanguagesDropdown from '../../components/MenuDropdown/LanguagesDropdown';
import UserDropdown from '../../components/MenuDropdown/UserDropdown';
import Grid from './Grid';
//Material UI
import { Box as BoxMUI } from '@mui/material';

const Box = styled(BoxMUI)`
  background-image: url(${BackgroundHeader});
  background-size: cover;
  background-repeat: no-repeat;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  text-transform: uppercase;
  color: #ffffff;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export default function Header() {
  return (
    <Box>
      <Grid>
        <Content>
          <div />
          <img width={470} className="logo" src={LogoMaxion} alt="Maxion" />
          <Actions>
            <LanguagesDropdown />
            <UserDropdown />
          </Actions>
        </Content>
      </Grid>
    </Box>
  );
}
