import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { withTranslation } from 'react-i18next';
import { checkPermission } from '../utils/permissions';
import useAuth from '../hooks/useAuth';

import { FileContext } from '../contexts/FileContext';

import ModalNewFold from '../pages/pages/EstrategiaisMateriais/Explorar/components/ModalNewFold';

import { Add as AddIcon } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';

const AddButton = styled.div`
  background-color: ${({ theme }) => theme.colors.blue_300};
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  path {
    height: 5px;
    width: 5px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

function ButtonNewFold({ t, getFolders }) {
  const { user } = useAuth();

  const [openModalAddFold, setOpenModalAddFold] = useState(false);
  const { file, setFile } = useContext(FileContext);

  useEffect(() => {});

  function handleOpen() {
    setFile({ id: '1' });
    setOpenModalAddFold(true);
  }

  return (
    <>
      <React.Fragment>
        {user && checkPermission(user, [17]) && (
          <Tooltip title={t('Adicionar categoria', { ns: 'maxion' })}>
            <IconButton
              aria-haspopup="true"
              onClick={handleOpen}
              color="inherit"
              size="large"
            >
              <AddButton>
                <AddIcon />
              </AddButton>
            </IconButton>
          </Tooltip>
        )}

        {file !== null && openModalAddFold !== false && (
          <ModalNewFold
            open={openModalAddFold}
            setOpen={setOpenModalAddFold}
            file={file}
            setFile={setFile}
            getFolders={getFolders}
          />
        )}
      </React.Fragment>
    </>
  );
}

export default withTranslation('maxion')(ButtonNewFold);
