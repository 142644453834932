import Content from './Versions';
import ReportVersions from './ReportGeneral';

const baseItems = {
  buttons: [
    {
      title: 'Listagem de versões',
      type: 'component',
      index: 0,
      element: <Content />,
    },
    {
      title: 'Relatórios',
      type: 'component',
      index: 1,
      element: <ReportVersions />,
    },
  ],
};

export default baseItems;
