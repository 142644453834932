import React, { useEffect, useCallback, useState, useContext } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from '../../../utils/axios';
import { toast } from 'react-toastify';
import { GetUsersContext } from '../../../contexts/GetUsersContext';

import { DataGrid } from '@mui/x-data-grid';
import {
  Divider,
  Breadcrumbs,
  TextField,
  Button as MuiButton,
} from '@mui/material';
import { spacing } from '@mui/system';
import { withTranslation } from 'react-i18next';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.gray_500};
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;
const Button = styled(MuiButton)(spacing);

function formateLogsDate(date) {
  const split = date.split('-');
  return `${split[1]}/${split[0]}`;
}

function getDataTime(date) {
  const formatedHour = new Date(date).toLocaleTimeString('pt-br', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return formatedHour;
}

function formateDate(date) {
  const formatedDate = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return formatedDate;
}

function Content({ t }) {
  const [closingDate, setClosingDate] = useState('');
  const [itemsDataGrid, setItemsDataGrid] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const dataGridColumns = [
    {
      field: 'id',
      headerName: t('ID', { ns: 'maxion' }),
      width: 90,
    },
    {
      field: 'change',
      headerName: t('Mudança', { ns: 'maxion' }),
      width: 180,
    },
    {
      field: 'user',
      headerName: t('Usuário', { ns: 'maxion' }),
      width: 110,
    },
    {
      field: 'updated',
      headerName: t('Atualizado em', { ns: 'maxion' }),
      width: 150,
    },
  ];

  const { user, users } = useContext(GetUsersContext);

  const getUserWhoUpdated = useCallback(
    (id) => {
      if (users && user) {
        const findUser = _.find(users, (user) => user.id === id);
        return findUser !== undefined ? findUser.name : user.name;
      }
    },
    [user, users]
  );

  const fetchClosingDate = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}parameters/name/MES_ULTIMO_FECHAMENTO`
      );
      if (data.value === null) return;

      const split = data.value.split('-');
      const formatedDate = `${split[1]}/${split[0]}`;
      setClosingDate(formatedDate);
    } catch (error) {
      console.log(error);
      toast.error(t('Erro ao buscar data de fechamento', { ns: 'maxion' }));
    }
  }, []);

  const fetchLogsDate = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}parameters/log/1`
      );
      if (data.value === null) return;

      const newArray = data.map((item, index) => {
        return {
          id: index + 1,
          user: getUserWhoUpdated(item.user_id_who_updated),
          change: `${
            item.value_from ? formateLogsDate(item.value_from) : '*'
          } -> ${formateLogsDate(item.value_to)}`,
          updated: `${formateDate(item.created_at)} - ${getDataTime(
            item.created_at
          )}`,
        };
      });

      setItemsDataGrid(newArray);
      setLoadingLogs(false);
    } catch (error) {
      console.log(error);
      setLoadingLogs(false);
      toast.error(
        t('Erro ao buscar logs histórico da data de fechamento', {
          ns: 'maxion',
        })
      );
    }
  }, [getUserWhoUpdated]);

  useEffect(() => {
    fetchClosingDate();
    fetchLogsDate();
  }, [fetchClosingDate, fetchLogsDate]);

  async function handleSubmit(values) {
    if (formik.values.date === closingDate) {
      toast.info(t('Insira uma data diferente da atual', { ns: 'maxion' }));
      return;
    }

    try {
      setLoadingSubmit(true);

      const split = values.date.split('/');
      await axios.patch(`${process.env.REACT_APP_PUBLIC_API}parameters/1`, {
        value: `${split[1]}-${split[0]}`,
        type: 'DATE',
      });

      toast.success(
        t('Data de fechamento atualizada com sucesso', { ns: 'maxion' })
      );
      fetchLogsDate();
      setLoadingSubmit(false);
    } catch (error) {
      toast.error(
        t('Erro ao enviar nova data de fechamento', { ns: 'maxion' })
      );

      console.log(error);
    }
  }

  const initialValues = {
    date: '',
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])\/[0-9]{4}$/,
        t('Insira uma data no formato MM/YYYY', { ns: 'maxion' })
      )
      .required(t('Campo obrigatório'), { ns: 'maxion' }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          sx={{ minWidth: '400px' }}
          focused
          placeholder="MM/YYYY"
          name="date"
          label={t('Data de fechamento', { ns: 'maxion' })}
          value={formik.values.date !== '' ? formik.values.date : closingDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.date) && Boolean(formik.touched.date)}
          helperText={
            Boolean(formik.errors.date) &&
            Boolean(formik.touched.date) &&
            formik.errors.date
          }
          disabled={loadingLogs || loadingSubmit}
        />

        <Button
          ml={5}
          mt={4}
          type="submit"
          variant="contained"
          disabled={loadingLogs || loadingSubmit}
        >
          {t('Enviar', { ns: 'maxion' })}
        </Button>
      </form>

      <DataGrid
        style={{ minHeight: '50vh', marginTop: '20px' }}
        loading={loadingLogs}
        rows={itemsDataGrid}
        columns={dataGridColumns}
        pageSize={6}
        rowsPerPageOptions={[6]}
        editMode={null}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
      />
    </div>
  );
}

function DataFechamento({ t }) {
  const [old, setOld] = useState(null);

  useEffect(() => {
    if (old === null && old !== 'Data de Fechamento') {
      async function setUsersNavigationLog(textRoute) {
        try {
          const route = `OpenMax / Administrativo / ${textRoute}`;

          await axios.post(
            `${process.env.REACT_APP_PUBLIC_API}users_navigation_log`,
            {
              route: route,
            }
          );
        } catch (error) {
          console.log(error);
        }
      }

      setUsersNavigationLog('Data de Fechamento');
      setOld('Data de Fechamento');
    }
  }, [old]);

  return (
    <div>
      <Helmet title={t('Data de Fechamento', { ns: 'maxion' })} />

      <Infobar t={t}>
        <div>
          <div>
            <h2>{t('Data de fechamento', { ns: 'maxion' })}</h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

              <p>{t('Administrativo', { ns: 'maxion' })}</p>

              <span>{t('Data de fechamento', { ns: 'maxion' })}</span>
            </Breadcrumbs>
          </div>
        </div>

        <Divider className="divider" />
      </Infobar>

      <Content t={t} />
    </div>
  );
}

export default withTranslation('maxion')(DataFechamento);
