import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

const List = styled.ul`
  background: ${({ theme }) => theme.colors.blue_gradient};
  opacity: 0.9;
  min-width: 240px;
  padding: 10px 0;
  list-style: none;
  border-radius: 10px;

  a {
    display: block;
    width: 100%;
    padding: 10px 0;
    cursor: pointer;
    text-decoration: none;

    p {
      color: ${({ theme }) => theme.colors.white};
      font-weight: 400;
      font-size: 17px;
      padding: 0 20px;
    }

    :hover {
      background: #fff;
      /* background: #005D8F; */

      p {
        color: ${({ theme }) => theme.colors.blue_300};
      }
    }
  }
`;

export default function MenuNavList({ items, t }) {
  return (
    <>
      <List>
        {items.map((item, index) => (
          <NavLink key={index} to={item.href}>
            <p>{t(`${item.title}`, { ns: 'maxion' })}</p>
          </NavLink>
        ))}
      </List>
    </>
  );
}
