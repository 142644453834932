import { useMaskito } from '@maskito/react';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { TextField } from '@mui/material';

export default function TextFieldMaskNumber(props) {
  const numberOptions = {
    precision: props.mask.precision,
    decimalSeparator: props.mask.decimalSeparator,
    thousandSeparator: props.mask.thousandSeparator,
  };

  const maskitoNumberOptions = maskitoNumberOptionsGenerator(numberOptions);

  const inputRef = useMaskito({ options: maskitoNumberOptions });

  return (
    <TextField
      fullWidth
      inputRef={inputRef}
      onInput={props.onInput}
      size={props.size}
      name={props.name}
      value={props.value}
      label={props.label}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      style={props.style}
      disabled={props.disabled}
    />
  );
}
