import ExcelJS from 'exceljs';

const exportToExcel = (
  dataRows,
  columnsBase,
  columnsVersion,
  columnsSimulate,
  rowsCustomer,
  t
) => {
  // Função para formatar números
  const formatNumberLocale = (value, decimalPlaces) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(value);
  };

  // Função para calcular a porcentagem de diferença
  const calculatePercentageDifference = (newValue, oldValue) => {
    if (oldValue === 0) {
      if (newValue === 0) {
        return '0%';
      } else {
        return 'NA';
      }
    } else {
      const percentage = ((newValue - oldValue) / oldValue) * 100;
      return formatNumberLocale(percentage, 0) + '%';
    }
  };

  // Criar um novo workbook e worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Report');

  // Definir cabeçalhos
  const headers = [
    t('CLIENTES', { ns: 'maxion' }),
    ...columnsBase,
    ...columnsVersion,
    ...columnsSimulate,
  ];
  worksheet.addRow(headers);

  // Função para adicionar uma linha à planilha
  const addRowToWorksheet = (rowData) => {
    const row = worksheet.addRow(rowData);
    row.eachCell((cell) => {
      cell.alignment = { horizontal: 'center' };
    });
    return row;
  };

  // Adicionar dados
  Object.entries(dataRows).forEach(([customerName, customerData]) => {
    const lastVersionIndex = columnsVersion.length - 1;
    const previousVersionIndex = columnsVersion.length - 2;

    const mainRow = [
      customerName,
      ...columnsBase.map((col) =>
        formatNumberLocale(customerData[col]?.value_total || 0, 2)
      ),
      ...columnsVersion.map((col) =>
        formatNumberLocale(customerData[col]?.value_total || 0, 2)
      ),
      calculatePercentageDifference(
        customerData[columnsVersion[lastVersionIndex]]?.value_total || 0,
        customerData[columnsVersion[previousVersionIndex]]?.value_total || 0
      ),
      formatNumberLocale(customerData.simulate?.value_total || 0, 2),
      calculatePercentageDifference(
        customerData.simulate?.value_total || 0,
        customerData[columnsVersion[lastVersionIndex]]?.value_total || 0
      ),
    ];
    const mainExcelRow = addRowToWorksheet(mainRow);

    // Estilizar linhas principais
    mainExcelRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC2DDF0' },
      };
      cell.font = { bold: true };
    });

    // Adicionar linhas de detalhes se não for 'TOTAL' ou 'OUTROS'
    if (!['TOTAL', 'OUTROS'].includes(customerName)) {
      rowsCustomer.forEach((detailRow) => {
        const detailData = [
          `${customerName} - ${detailRow.label}`,
          ...columnsBase.map((col) =>
            formatNumberLocale(customerData[col]?.[detailRow.key] || 0, 2)
          ),
          ...columnsVersion.map((col) =>
            formatNumberLocale(customerData[col]?.[detailRow.key] || 0, 2)
          ),
          calculatePercentageDifference(
            customerData[columnsVersion[lastVersionIndex]]?.[detailRow.key] ||
              0,
            customerData[columnsVersion[previousVersionIndex]]?.[
              detailRow.key
            ] || 0
          ),
          formatNumberLocale(customerData.simulate?.[detailRow.key] || 0, 2),
          calculatePercentageDifference(
            customerData.simulate?.[detailRow.key] || 0,
            customerData[columnsVersion[lastVersionIndex]]?.[detailRow.key] || 0
          ),
        ];
        addRowToWorksheet(detailData);
      });
    }
  });

  // Estilizar cabeçalhos
  worksheet.getRow(1).font = { bold: true };
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFC2DDF0' },
  };

  // Ajustar largura das colunas
  worksheet.columns.forEach((column) => {
    column.width = 20;
  });

  const date = new Date().toISOString().slice(0, 10);

  // Exportar para arquivo
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `AOP-Simulador-Geral-${date}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

export default exportToExcel;
