import _ from 'lodash';

export const checkPermission = (user, permissions = [], roles = []) => {
  let hasPermission = false;

  if (user.rolesList.includes(1)) {
    hasPermission = true;
  } else {
    const validation =
      _.intersection(permissions, user.permissionsList).length > 0 ||
      _.intersection(roles, user.rolesList).length > 0;
    hasPermission = validation;
  }

  return hasPermission;
};
