import React from 'react';
import styled from '@emotion/styled';

const Box = styled.div`
  display: flex;
  margin-bottom: 30px;
  gap: 30px;
`;

const Button = styled.button`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.blue_200 : '#FFF'};
  padding: 9px 35px;
  color: ${({ isSelected, theme }) =>
    isSelected ? '#FFF' : theme.colors.gray_500};
  /* border: 1px solid ${({ theme }) => theme.colors.blue_200}; */
  border: none;
  border-radius: 4px;
  min-width: 160px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;
  cursor: ${({ isSelected }) => (isSelected ? 'not-allowed' : 'pointer')};
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 15px;

  :hover {
    background-color: ${({ theme }) => theme.colors.blue_200};
    color: ${({ isSelected }) => (isSelected ? '#FFF' : '#FFF')};
  }
`;

export default function ButtonsActions({
  t,
  buttonInfos,
  invisibleNumber,
  setInvisibleNumber,
}) {
  return (
    <Box>
      {buttonInfos.map((button, index) => (
        <Button
          key={index}
          onClick={() => setInvisibleNumber(button.index)}
          isSelected={button.index === invisibleNumber}
        >
          {t(button.title, { ns: 'maxion' })}
        </Button>
      ))}
    </Box>
  );
}
