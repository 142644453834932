import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import Infobar from '../../../components/Infobar/Infobar';

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  InputLabel,
  Paper,
  Stack,
  TextField as MuiTextField,
} from '@mui/material';
import { spacing } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

import axios from '../../../utils/axios';

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function DepartmentForm({ t }) {
  /*
  DATAGRID
  */
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'role',
      headerName: t('Role', { ns: 'maxion' }),
      width: 150,
      editable: true,
    },
  ];

  /*
  DATAGRID
  */

  const validationSchema = Yup.object().shape({
    role: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
  });

  const urlForm = 'roles';

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setSubmitting(true);

    // Update
    if (typeof values.id != 'undefined') {
      await axios
        .patch(
          `${process.env.REACT_APP_PUBLIC_API}${urlForm}/${values.id}`,
          values
        )
        .then((response) => {
          resetForm();
          setStatus({ sent: true });
          setSubmitting(false);
        })
        .catch((error) => {
          console.log(`Error updating ${urlForm}`, error);
          setStatus({ sent: false });
          setErrors(error.response.data.detail);
          setSubmitting(false);
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_PUBLIC_API}${urlForm}`, values)
        .then((response) => {
          resetForm();
          setStatus({ sent: true });
          setSubmitting(false);
        })
        .catch((error) => {
          console.log('Error creating user', error);
          setStatus({ sent: false });
          setErrors(error.response.data.detail);
          setSubmitting(false);
        });
    }
  };

  const initialValuesBlank = {
    role: '',
    permissions: [],
  };

  const [create, setCreate] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [initialValues, setInitialValues] = useState(initialValuesBlank);
  const [rowCount, setRowCount] = useState();
  const [page, setPage] = useState(0);
  const [removeItem, setRemoveItem] = useState(false);
  const [isRemovingItem, setIsRemovingItem] = useState(false);
  const [itemsDataGrid, setItemsDataGrid] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const pageSize = 20;

  const getItemsDatagrid = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API}${urlForm}?skip=${
          page * pageSize
        }&limit=${pageSize}`
      )
      .then((response) => {
        // let items = []
        // _.forEach(response.data, (val) => {
        //   items.push({
        //     'id': val.id,
        //     'department': val.department,
        //   })
        // })
        setItemsDataGrid(response.data);
        setLoadingData(false);
      })
      .catch((error) => console.log('Error grid', error));
  }, [page, pageSize]);

  const getItemById = useCallback((item_id) => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}${urlForm}/${item_id}`)
      .then((response) => {
        let item = response.data;

        setInitialValues({
          id: item.id,
          role: item.role,
          permissions: item.permissions.map((item) => {
            return {
              value: item.id,
              label: item.permission,
            };
          }),
        });

        setCreate(true);
        setLoadingData(false);
      })
      .catch((error) => console.log('Error item by ID', error));
  }, []);

  const getPermissions = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}permissions`)
      .then((response) => {
        let options = [];
        _.forEach(response.data, (val) => {
          options.push({
            value: val.id,
            label: val.permission,
          });
        });

        setPermissions(options);
      })
      .catch((error) => console.log('Error permission', error));
  }, []);

  useEffect(() => {
    getItemsDatagrid();
  }, [getItemsDatagrid, page, pageSize]);

  useEffect(() => {
    // Get items count
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}${urlForm}/count`)
      .then((response) => {
        setRowCount(response.data);
      })
      .catch((error) => console.log('Error counting', error));

    // Permissions
    getPermissions();
  }, [getPermissions]);

  const [rowCountState, setRowCountState] = React.useState(0);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  if (!create) {
    return (
      <Box sx={{ height: 500, width: '100%' }}>
        <Stack direction="row">
          <Button
            type="button"
            variant="contained"
            color="primary"
            mb={3}
            onClick={() => {
              setInitialValues(initialValuesBlank);
              setCreate(true);
            }}
          >
            {t('Nova role', { ns: 'maxion' })}
          </Button>
        </Stack>
        <DataGrid
          rows={itemsDataGrid}
          columns={columns}
          onRowClick={(params, event) => {
            getItemById(params.row.id);
            setLoadingData(true);
          }}
          pagination
          loading={loadingData}
          paginationMode="server"
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          onPageChange={(newPage) => {
            setLoadingData(true);
            setPage(newPage);
          }}
          page={page}
          rowCount={rowCountState}
          keepNonExistentRowsSelected
        />
      </Box>
    );
  } else {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
          setFieldValue,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Paper>
                {values.id && !isRemovingItem && (
                  <Button
                    color="error"
                    variant="contained"
                    style={{ marginBottom: 20 }}
                    onClick={() => setRemoveItem(true)}
                  >
                    {t('Remover', { ns: 'maxion' })}
                  </Button>
                )}
                <Dialog
                  open={removeItem}
                  onClose={() => {
                    setRemoveItem(false);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t('Deseja remover o item?', { ns: 'maxion' })}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t(
                        'Clique em Confirmar para remover o item ou em Cancelar para voltar',
                        { ns: 'maxion' }
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setRemoveItem(false)}
                      color="primary"
                    >
                      {t('Cancelar', { ns: 'maxion' })}
                    </Button>
                    <Button
                      onClick={() => {
                        setIsRemovingItem(true);
                        setRemoveItem(false);

                        axios
                          .delete(
                            `${process.env.REACT_APP_PUBLIC_API}${urlForm}/${values.id}`
                          )
                          .then((response) => {
                            setInitialValues(initialValuesBlank);
                            setCreate(false);
                          })
                          .catch((error) =>
                            console.log('Error deleting item', error)
                          );
                      }}
                      color="primary"
                      autoFocus
                    >
                      {t('Confirmar', { ns: 'maxion' })}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  {t('Dados enviados com sucesso', { ns: 'maxion' })}
                  <Button
                    onClick={() => {
                      setInitialValues(initialValuesBlank);
                      setCreate(false);
                      getItemsDatagrid();
                    }}
                  >
                    {t('Voltar', { ns: 'maxion' })}
                  </Button>
                </Alert>
              )}

              {isSubmitting || isRemovingItem ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <TextField
                    name="role"
                    label={t('role.role', { ns: 'maxion' })}
                    value={values.role}
                    error={Boolean(touched.role && errors.role)}
                    fullWidth
                    helperText={touched.role && errors.role}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />

                  <FormGroup fullWidth style={{ marginTop: 10 }}>
                    <InputLabel shrink id="select-permissoes-label">
                      {t('Permissões', { ns: 'maxion' })}
                    </InputLabel>
                    <Select
                      value={values.permissions}
                      onChange={(option) => {
                        setFieldValue('permissions', option);
                      }}
                      isMulti
                      options={permissions}
                      placeholder={t('role.permissoes', { ns: 'maxion' })}
                      loadingMessage={() => {
                        t('carregando', { ns: 'maxion' });
                      }}
                      // isLoading={field.isLoading}
                      // className={errors[field.name] && status == 'submitted' ? 'is-invalid' : ''}
                      name={'permissions'}
                      menuPortalTarget={document.body}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </FormGroup>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        setInitialValues(initialValuesBlank);
                        setCreate(false);
                      }}
                      disabled={status && status.sent}
                    >
                      {t('Cancelar', { ns: 'maxion' })}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={(status && status.sent) || isSubmitting}
                    >
                      {t('Salvar', { ns: 'maxion' })}
                    </Button>
                  </Stack>
                  {/* <Paper style={{ marginTop: 30 }}>
                    <pre>
                      {JSON.stringify(values, null, 4)}
                    </pre>
                    <pre>
                      {JSON.stringify(errors, null, '\t')}
                    </pre>
                  </Paper> */}
                </form>
              )}
            </CardContent>
          </Card>
        )}
      </Formik>
    );
  }
}

function RolePage({ t, tReady }) {
  return (
    <div>
      {tReady && (
        <React.Fragment>
          {/* <Typography variant="h3" gutterBottom display="inline">
            {t('role.role', { ns: 'maxion' })}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t('admin', { ns: 'maxion' })}</Typography>
            <Typography>{t('role.role', { ns: 'maxion' })}</Typography>
          </Breadcrumbs>

          <Divider my={6} /> */}
          <Helmet title={t('Roles', { ns: 'maxion' })} />

          <Infobar
            t={t}
            title={t('Papéis', { ns: 'maxion' })}
            links={[
              {
                name: t('Administrativo', { ns: 'maxion' }),
                href: '/admin/role',
              },
            ]}
            text={t('Papéis', { ns: 'maxion' })}
          />

          <DepartmentForm t={t} />
        </React.Fragment>
      )}
    </div>
  );
}

export default withTranslation(['admin', 'common', 'titles'])(RolePage);
