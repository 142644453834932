import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import axios from '../../../../../../../utils/axios';
import { toast } from 'react-toastify';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
  Dialog,
  DialogTitle as DialogTitleMUI,
  DialogContent as DialogContentMUI,
  DialogActions as DialogActionsMUI,
  DialogContentText,
  Button,
  Divider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { POContext } from '../../../../../../../contexts/POContext';

const DialogTitle = styled(DialogTitleMUI)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 12px 20px;
`;

const DialogContent = styled(DialogContentMUI)`
  padding: 25px 20px;
`;

const DialogActions = styled(DialogActionsMUI)`
  background-color: #f6f9fc;
`;

const Input = styled.input`
  background-color: #fff !important;
  margin-right: 10px;
  padding: 6px 8px !important;
  min-width: 200px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  color: ${({ theme }) => theme.colors.gray_500};

  ::placeholder {
    color: #9a9a9a;
    font-weight: 400;
  }
`;

export default function ModalDeleteProduct({
  fetchSemaphore,
  fetchTableItems,
  t,
  row,
  open,
  setOpen,
}) {
  const { i18n } = useTranslation();

  const [valueInput, setValueInput] = useState('');
  const [loading, setLoading] = useState(false);

  const { versionId } = useContext(POContext);

  async function handleDelete() {
    try {
      setLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_PUBLIC_API}po_new_products/${row.id}?version_id=${versionId}`
      );

      toast.success(t('Produto excluído com sucesso'), { ns: 'maxion' });
      setOpen(false);

      fetchTableItems();
    } catch (error) {
      toast.error(
        t('Erro ao excluir produto', {
          ns: 'maxion',
        })
      );
      console.log(`Error fetching data:  ${error}`);
    } finally {
      setLoading(false);
    }
  }

  const key = i18n.language === 'pt-BR' ? 'EXCLUIR' : 'DELETE';

  function handleClose(setOpen) {
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('Excluir produto', { ns: 'maxion' })} {row.name}
        <IconButton
          onClick={() => setOpen(false)}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('Esta ação irá remover permanentemente este produto', {
            ns: 'maxion',
          })}
          .
          <br />
          <b style={{ color: '#FF0000' }}>
            {t('Isto não poderá ser desfeito', { ns: 'maxion' })}.
          </b>
        </DialogContentText>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Input
          placeholder={`${t('Digite a palavra', { ns: 'maxion' })} ${key}`}
          onChange={(e) => setValueInput(e.target.value)}
          value={valueInput}
        />

        <Button
          color="primary"
          autoFocus
          disabled={loading || valueInput !== key}
          onClick={() => {
            handleDelete();
          }}
        >
          {loading ? (
            <CircularProgress size="16px" />
          ) : (
            t('Excluir', { ns: 'maxion' })
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
