//Importações
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import _ from 'lodash';
import useAuth from '../../hooks/useAuth';
//Components
import NavbarNavSection from './NavbarNavSection';
//Material UI
import { CircularProgress } from '@mui/material';

const Nav = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 50px;
  margin-left: 30px;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
`;

function NavbarNav({ t, items }) {
  const { user } = useAuth();
  const [itemsFiltered, setItemsFiltered] = useState([]);

  useEffect(() => {
    if (user !== null) {
      const itemsOriginais = _.cloneDeep(items);

      if (_.intersection([1], user.rolesList).length > 0) {
        setItemsFiltered(itemsOriginais);
        return;
      }

      let itemsFilter = _.filter(itemsOriginais, (item) => {
        item.pages = _.filter(item.pages, (page) => {
          page.children = _.filter(page.children, (child) => {
            return (
              _.intersection(child.permissions, user.permissionsList).length >
                0 || _.intersection(child.roles, user.rolesList).length > 0
            );
          });
          return page.children.length > 0;
        });

        return item.pages.length > 0;
      });

      setItemsFiltered(itemsFilter);
    }
  }, [items, user]);

  if (!itemsFiltered || !user) {
    return (
      <Loading>
        <CircularProgress size={35} />
      </Loading>
    );
  }

  return (
    <Nav>
      {itemsFiltered &&
        user &&
        itemsFiltered.map((item, index) => (
          <NavbarNavSection key={index} title={item.title} pages={item.pages} />
        ))}
    </Nav>
  );
}

export default withTranslation('maxion')(NavbarNav);
