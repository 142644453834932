import ExcelJS from 'exceljs';

const exportToExcelMonthly = (
  dataRowsMonth,
  colsReportMonth,
  rowsCustomer,
  t
) => {
  // Função para formatar números
  const formatNumberLocale = (value, decimalPlaces) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(value);
  };

  // Criar um novo workbook e worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Monthly Report');

  // Definir cabeçalhos
  const headers = [t('CLIENTES', { ns: 'maxion' }), ...colsReportMonth];
  worksheet.addRow(headers);

  // Função para adicionar uma linha à planilha
  const addRowToWorksheet = (rowData) => {
    const row = worksheet.addRow(rowData);
    row.eachCell((cell) => {
      cell.alignment = { horizontal: 'center' };
    });
    return row;
  };

  // Adicionar dados
  Object.entries(dataRowsMonth).forEach(([customerName, customerData]) => {
    const mainRow = [
      customerName,
      ...customerData.value_total.map((value) => formatNumberLocale(value, 2)),
    ];
    const mainExcelRow = addRowToWorksheet(mainRow);

    // Estilizar linhas principais
    mainExcelRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC2DDF0' },
      };
      cell.font = { bold: true };
    });

    // Adicionar linhas de detalhes se não for 'TOTAL' ou 'OUTROS'
    if (!['TOTAL', 'OUTROS'].includes(customerName)) {
      rowsCustomer.forEach((detailRow) => {
        const detailData = [
          `${customerName} - ${detailRow.label}`,
          ...customerData[detailRow.key].map((value) => {
            let decimalPlaces = 2;
            switch (detailRow.key) {
              case 'value_m_per_day':
                decimalPlaces = 4;
                break;
              case 'value_total_per_volume':
                decimalPlaces = 2;
                break;
              default:
                decimalPlaces = 0;
            }
            return formatNumberLocale(value, decimalPlaces);
          }),
        ];
        addRowToWorksheet(detailData);
      });
    }
  });

  // Estilizar cabeçalhos
  worksheet.getRow(1).font = { bold: true };
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFC2DDF0' },
  };

  // Ajustar largura das colunas
  worksheet.columns.forEach((column) => {
    column.width = 20;
  });

  const date = new Date().toISOString().slice(0, 10);

  // Exportar para arquivo
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `AOP-Simulador-Mensal-${date}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  });
};

export default exportToExcelMonthly;
