import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from '../../../utils/axios';
import { generateTokenTableau } from '../../../utils/jwtTableau';
import Infobar from '../../../components/Infobar/Infobar';
import { withTranslation } from 'react-i18next';

const Box = styled.div`
  display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

function MonitoramentoDeUso({ t }) {
  const [content, setContent] = useState([]);
  const [old, setOld] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    let token = null;
    token = generateTokenTableau();
    setToken(token);
  }, []);

  useEffect(() => {
    if (old === null && old !== 'Monitoramento de uso') {
      async function setUsersNavigationLog(textRoute) {
        try {
          const route = `OpenMax / Administrativo / ${textRoute}`;

          await axios.post(
            `${process.env.REACT_APP_PUBLIC_API}users_navigation_log`,
            {
              route: route,
            }
          );
        } catch (error) {
          console.log(error);
        }
      }

      setUsersNavigationLog('Monitoramento de uso');
      setOld('Monitoramento de uso');
    }
  }, [old]);

  return (
    <div>
      <Infobar
        t={t}
        title={t('Monitoramento de uso', { ns: 'maxion' })}
        links={[
          {
            name: t('Administrativo', { ns: 'maxion' }),
            href: '/admin/monitoring',
          },
        ]}
        text={t('Monitoramento de uso', { ns: 'maxion' })}
      />

      {token && (
        <div style={{ width: '100%' }}>
          <tableau-viz
            id="tableau-viz"
            src="https://us-east-1.online.tableau.com/t/iochpemaxion/views/Admin-NavegacaoLog/Dashboard1"
            width="100%"
            height="1200px"
            token={token}
            toolbar="hidden"
          />
        </div>
      )}
    </div>
  );
}

export default withTranslation('maxion')(MonitoramentoDeUso);
